@use "src/sedestral/sass/mixins/texts";
@use "src/sedestral/sass/mixins/outline";
@use "src/sedestral/sass/extends/inputs";

:global {
  body {
    @include texts.font("medium");
    margin: 0;
    padding: 0;
    overflow: auto;
    overflow-x: hidden;
  }

  main {
    position: absolute;
    width: 100%;
    min-height: 100%;
    background: var(--black);
    color: var(--white);
  }

  input {
    @include inputs.border;
  }

  a {
    text-decoration: none;
    color: var(--white);
  }

  button {
    background: transparent;
    border: none;
  }

  h1, h2, h3, h4, h5, h6, p, ul, li {
    margin: 0;
    list-style-type: none;
  }

  @media (max-width: 480px) {
    main {
      max-width: 480px;
      overflow-x: hidden;
    }
  }
}
