.LrIP1 {
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-y: auto;
}

.LrIP1::-webkit-scrollbar {
  display: none;
}

.LrIP1 .yYNu3 {
  z-index: 10;
  cursor: pointer;
  background: var(--grey-sur);
  border-radius: 20px;
  width: 4px;
  height: calc(100% - 8px);
  transition: all .1s;
  position: absolute;
  top: 4px;
  bottom: 0;
  right: 0;
}

.LrIP1 .yYNu3:hover {
  background: #0003;
  width: 8px !important;
}

.LrIP1 .yYNu3:hover .vC-Op {
  background: #00000080;
}

.LrIP1 .FN46B {
  -webkit-user-select: none;
  user-select: none;
}

.LrIP1 .vC-Op {
  z-index: 0;
  cursor: grab;
  background: #0000006b;
  border-radius: 20px;
  width: 100%;
  position: relative;
  top: 0;
  right: 0;
}

.LrIP1 .vC-Op:hover {
  background: #0009;
}

.LrIP1 .vC-Op.zHL2l {
  cursor: grab;
  background: #00000080;
}

.LrIP1 .zHL2l {
  cursor: grab;
  -webkit-user-select: none;
  user-select: none;
  background: #0003;
  width: 8px !important;
}

.k4BLk {
  z-index: 2147483647;
  pointer-events: none;
  background: var(--white);
  opacity: 0;
  border-radius: 10px;
  font-family: semi-bold, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 11px;
  position: fixed;
  box-shadow: 0 0 0 1px #00000008, 0 0 20px #0003;
}

.k4BLk .R0ByI {
  width: 10px;
  height: 10px;
  position: absolute;
}

.k4BLk .R0ByI:after {
  content: "";
  background-color: var(--white);
  width: 10px;
  height: 10px;
  position: absolute;
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  box-shadow: 0 4px 14px #0003, 0 0 0 1px #0000000d;
}

.k4BLk .B1zGi {
  box-sizing: border-box;
  background-color: var(--white);
  color: #222;
  z-index: 2;
  text-align: center;
  border-radius: 10px;
  padding: 8px 12px;
  font-size: 14px;
  line-height: 20px;
  position: relative;
}

.MHPN0 {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 30px;
  margin-top: -6px;
  display: flex;
}

.MHPN0 .SOfAh {
  background: var(--grey-sur);
  border-radius: 10px;
  width: 40%;
  height: 5px;
  left: 30%;
}

.JlCaV {
  max-height: calc(100% - 6px - env(safe-area-inset-bottom)  - env(safe-area-inset-top)) !important;
  padding-bottom: env(safe-area-inset-bottom) !important;
}

.pcr-app {
  z-index: 99999999 !important;
  border-radius: 12px !important;
  transition: all !important;
  box-shadow: 0 0 0 1px #00000008, 0 0 20px #0003 !important;
}

.pcr-app .pcr-palette, .pcr-app .pcr-palette:before {
  border-radius: 8px !important;
}

.pcr-app .pcr-result {
  font-family: semi-bold, Segoe UI Emoji, Montserrat, sans-serif;
  border-radius: 8px !important;
}

.pcr-app .pcr-result:focus {
  cursor: pointer;
  box-shadow: var(--grey-sur) 0 0 0 2px !important;
}

.pcr-app .pcr-swatches button, .pcr-app .pcr-swatches button:after {
  border-radius: 100% !important;
}

.pcr-app .pcr-swatches button:before {
  background: unset !important;
}

.apexcharts-legend {
  left: -9px !important;
}

.apexcharts-tooltip {
  background: var(--white);
  border-radius: 14px;
  box-shadow: 0 0 0 1px #00000008, 0 0 20px #0003;
  border: unset !important;
  background: var(--white) !important;
  border-radius: 12px !important;
  padding: 8px !important;
  box-shadow: 0 0 0 1px #00000008, 0 0 20px #0003 !important;
}

.apexcharts-tooltip .tooltip-content .side .value {
  border-bottom: 1px solid var(--grey-sur);
  gap: 6px;
  width: 100%;
  padding: 6px 0;
  font-size: 14px;
  display: flex;
}

.apexcharts-tooltip .tooltip-content .side .value .color {
  border-radius: 5px;
  width: 12px;
  height: 12px;
  margin-top: 3px;
}

.apexcharts-tooltip .tooltip-content .side .data {
  margin-left: auto;
  font-family: semi-bold, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 15px;
}

.apexcharts-tooltip .tooltip-content .date {
  color: var(--black);
  font-family: semi-bold, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 17px;
}

.apexcharts-xaxistooltip {
  background: var(--white) !important;
  border: unset !important;
  border-radius: 10px !important;
  padding: 10px 16px !important;
  box-shadow: 0 0 0 1px #00000008, 0 0 20px #0003 !important;
}

.apexcharts-xaxistooltip:before {
  border-bottom-color: var(--grey-sur) !important;
}

.apexcharts-xaxistooltip:after {
  border-bottom-color: var(--white) !important;
}

.apexcharts-xaxistooltip .apexcharts-xaxistooltip-text {
  font-size: 14px !important;
}

.blot-formatter__overlay {
  border: 2px dashed #379fff !important;
  overflow: hidden !important;
}

.blot-formatter__overlay .ql-stroke {
  stroke: var(--black);
  stroke-width: 2px;
}

.blot-formatter__overlay .blot-formatter__toolbar {
  border-radius: 5px;
  margin: auto;
  overflow: hidden;
  box-shadow: 0 0 0 1px #00000008, 0 0 20px #0003;
  width: 72px !important;
  height: 24px !important;
  min-width: unset !important;
  top: 0 !important;
}

.blot-formatter__overlay .blot-formatter__toolbar .blot-formatter__toolbar-button {
  border: unset !important;
  filter: unset !important;
}

.blot-formatter__overlay .blot-formatter__toolbar .blot-formatter__toolbar-button.is-selected svg {
  background: var(--grey-sur) !important;
}

.blot-formatter__overlay .blot-formatter__toolbar .blot-formatter__toolbar-button svg {
  border: unset !important;
}

.blot-formatter__overlay .blot-formatter__resize-handle {
  opacity: 1 !important;
  border: unset !important;
  background: #379fff !important;
  border-radius: 2px !important;
  width: 8px !important;
  height: 8px !important;
}

.blot-formatter__overlay .blot-formatter__resize-handle[data-position="top-right"] {
  top: 1px !important;
  right: 1px !important;
}

.blot-formatter__overlay .blot-formatter__resize-handle[data-position="top-left"] {
  top: 1px !important;
  left: 1px !important;
}

.blot-formatter__overlay .blot-formatter__resize-handle[data-position="bottom-right"] {
  bottom: 1px !important;
  right: 1px !important;
}

.blot-formatter__overlay .blot-formatter__resize-handle[data-position="bottom-left"] {
  bottom: 1px !important;
  left: 1px !important;
}

@font-face {
  font-family: medium;
  src: local(Montserrat Medium), url("Montserrat-Medium.91612541.woff2") format("woff2"), url("Montserrat-Medium.bd19e0d4.woff") format("woff"), url("Montserrat-Medium.5425fc7a.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: bold;
  src: url("Montserrat-Bold.c78d159b.woff2") format("woff2"), url("Montserrat-Bold.f9ce8d83.woff") format("woff"), url("Montserrat-Bold.fc0d0bf0.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: black;
  src: local(Montserrat Black), url("Montserrat-Black.6d98d3fb.woff2") format("woff2"), url("Montserrat-Black.ddd143a0.woff") format("woff"), url("Montserrat-Black.2cab11ae.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: extra-bold;
  src: local(Montserrat ExtraBold), url("Montserrat-ExtraBold.9e6d4d9e.woff2") format("woff2"), url("Montserrat-ExtraBold.50c574e3.woff") format("woff"), url("Montserrat-ExtraBold.3ee63765.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: semi-bold;
  src: local(Montserrat SemiBold), url("Montserrat-SemiBold.9fe973e5.woff2") format("woff2"), url("Montserrat-SemiBold.bd204140.woff") format("woff"), url("Montserrat-SemiBold.0f16d814.ttf") format("truetype");
  font-display: swap;
}

:root {
  --grey-sur-light: #cfcfdb33;
  --grey-sur-opac: #efeff3;
  --grey-sur: #cfcfdb54;
  --grey-sur-hover: #cfcfdb73;
  --grey-sur-hover-opac: #e9e9ef;
  --grey-alpha: #0000001a;
  --grey-alpha-half: #0000000d;
  --grey-light: #cfcfdb33;
  --grey: #ebebeb;
  --grey-text: #84848a8a;
  --grey-text-dark: #84848a;
  --grey-text-dark-blog: #74747a;
  --grey-text-very-dark: #777;
  --grey-thumb: #888;
  --grey-dark: #dedede;
  --grey-extra-dark: #4a4a4a;
  --grey-shadow: #ffffffbd;
  --grey-mobile: #a0a7ac;
  --grey-gradient: #c8c8c8;
  --grey-transparent: #c4c4c400;
  --white: white;
  --white-light: white;
  --white-alpha: #ffffffd6;
  --white-transparent: #ffffff80;
  --white-sur: #ffffff1a;
  --white-sur-hover: #fff3;
  --black: black;
  --black-hr: #3f3939;
  --black-alpha: #00000080;
  --black-alpha-half: #0006;
  --black-alpha-two: #0003;
  --black-alpha-middle: #00000026;
  --black-alpha-max: #00000014;
  --black-alpha-dark: #000000b3;
  --black-light: #303235;
  --black-light-alpha: #32323280;
  --black-middle: #343434;
  --colors-1: #ffda2d;
  --colors-2: #febf00;
  --colors-3: #ffa501;
  --colors-4: #ff9100;
  --colors-5: #0b7598;
  --colors-6: #074f68;
  --red: #ef343e;
  --red-king: #611d1d;
  --red-dark: #dd2731;
  --red-hover: #f443361a;
  --red-alpha: #ef343e33;
  --red-pink: #fd3a57;
  --red-light: #fcd6d8;
  --red-very-light: #fff6f7;
  --red-real: #f0424a;
  --red-real-hover: #ff3e47;
  --red-flash: #ff3a3a;
  --red-notify: #e51010;
  --red-twitter: #f34f35;
  --purple-mep: #b453d8;
  --purple: #ba65fd;
  --purple-dark: #9551ca;
  --purple-extra: #6c42e4;
  --offer-free: linear-gradient(180deg, #41a2fc, #0085ff);
  --offer-free-hover: linear-gradient(180deg, #2594fc, #017ef0);
  --offer-paid: linear-gradient(180deg, #ffd12e, #ff981f);
  --offer-cancel: linear-gradient(180deg, #ff4c4c, #ff2020);
  --offer-advantage: #a0d1ff;
  --grad-sedestral: linear-gradient(to right, #434343 0%, black 100%);
  --grad-sedestral-inverse: linear-gradient(to top, #676767 0%, black 100%);
  --grad-inbox: linear-gradient(to top, #00c867, #14ff8d);
  --grad-help-center: linear-gradient(to top, #ff4e5b, #ffa2a2);
  --grad-blog: linear-gradient(to top, #9937dc, #d06bf9);
  --grad-statistics: linear-gradient(to top, #00f, #0084ff);
  --grad-sell: linear-gradient(to top, #d10c0b, #ff5851);
  --grad-contacts: linear-gradient(to top, #ff823d, #ffaf27);
  --grad-publisher: linear-gradient(to top, #006cff, #00c7ff);
  --grad-story: linear-gradient(143deg, #d399fa 0%, #8364e8 100%);
  --grad-green: linear-gradient(to bottom, #73ffa3, #00cf46);
  --grad-black: linear-gradient(to bottom, #000, #606060);
  --grad-purple: linear-gradient(to top, #c954ff, #af00ff);
  --grad-blue: linear-gradient(to bottom, #4886ff, #1967ff);
  --grad-blue-button: linear-gradient(to bottom, #41a2fc, #0085ff);
  --grad-red: linear-gradient(to bottom, #ff4c4c, #ff2020);
  --grad-orange-button: linear-gradient(to bottom, #ffd12e, #ff981f);
  --grad-orange-dark-button: linear-gradient(to bottom, #ffbf19, #ff981f);
  --grad-multi: linear-gradient(90deg, #ffa745, #ff4c4c, #c443ff, #4886ff, #4ffa40);
  --blue: #0197ff;
  --blue-mep: #1877f2;
  --blue-ai: #0dcaf8;
  --blue-mep-hover: #1163d0;
  --blue-twt: #1d9bf0;
  --blue-twt-black: #0e88da;
  --blue-hover: #3eb0ff;
  --blue-punk: #2541ff;
  --blue-pulp: #0093ee;
  --blue-dark: #4d63fb;
  --blue-alpha-dark-light: #00a6ed33;
  --blue-alpha-dark: #00a6ed1a;
  --blue-selection: #0089ff47;
  --blue-dark-hover: #ced6f6b3;
  --blue-light: #e3e7fa;
  --blue-ultra-light: #0197ff1a;
  --blue-cian: #e8f0fe;
  --blue-new: #f3fbff;
  --blue-new-text: #00b3ff;
  --blue-new-checked: #e4f7ff;
  --blue-new-hover: #dff5ff;
  --blue-str: #f7fafc;
  --blue-str-hover: #e5e9ec;
  --blue-str-hover-hover: #dde2e7;
  --blue-str-shadow: #e3e8ee;
  --blue-selection-shadow: #0084ff80;
  --blue-mobile: #0f77ff;
  --blue-pastel: #8fc2ff;
  --blue-twitter: #00b4fe;
  --green: #40db6f;
  --green-flash: #28d55c;
  --green-flash-hover: #26c857;
  --green-flash-alpha: #5ed9614d;
  --green-hover: #3ccb67;
  --green-dark: #18c23d;
  --green-dark-hover: #0d932d;
  --green-light: #e7ffe3;
  --green-light-dark: #d6f6d5;
  --green-very-light: #edfff4;
  --green-twitter: #00d040;
  --shadow-livechat: #00000014 0px 0px 0px 1px, #00000014 0px 2px 8px;
  --orange: #ffaf27;
  --orange-cover: #eca336;
  --orange-dark: #f08c00;
  --orange-light: #ffebc7;
  --orange-semi-light: #fff0d7;
  --orange-very-light: #fff7e9;
  --outline-blue: #0084ff80;
  --private: #fff2d7;
  --private-text: #ffa83e;
  --web-grad-blue: linear-gradient(180deg, #3974f1 0%, #3d54fc 100%);
  --web-border-blue: #ffffff78;
  --web-blue: #3a6ff3;
  --web-blue-hover: #1b50d3;
  --web-white-alpha: #ffffff1a;
  --web-white-alpha-hover: #ffffff38;
  --web-purple: #845bf1;
  --web-yellow: #fd0;
  --web-purple-text: #9360ff;
  --web-border-white: #ffffff2e;
  --web-background-steps: #a2bfff17;
  --web-brackground-grid-green: linear-gradient(180deg, #00e53200 0%, #00ff370f 100%);
  --web-background-grid-purple: linear-gradient(180deg, #8c62fe00 0%, #8c62fe0f 100%);
  --web-background-grid-blue: linear-gradient(180deg, #0000 0%, #0033ff17 100%);
  --web-background-grid-yellow: linear-gradient(180deg, #fd00 0%, #ffdd000f 100%);
  --web-background-grid-white: linear-gradient(180deg, #fff0 0%, #ffffff0f 100%);
  --web-text-white-alpha: #ffffff8c;
  --web-image-shadow: 0px 0px 30px 0px #0000001a;
  --web-shadow-green: #79ff44;
  --icon-black: invert(0%) sepia(96%) saturate(14%) hue-rotate(273deg) brightness(99%) contrast(104%);
  --icon-blue: invert(40%) sepia(84%) saturate(4291%) hue-rotate(224deg) brightness(98%) contrast(100%);
  --icon-blue-light: invert(50%) sepia(47%) saturate(2177%) hue-rotate(166deg) brightness(102%) contrast(103%);
  --icon-blue-mobile: invert(29%) sepia(74%) saturate(2619%) hue-rotate(208deg) brightness(106%) contrast(103%);
  --icon-blue-pulp: invert(35%) sepia(87%) saturate(2176%) hue-rotate(181deg) brightness(102%) contrast(101%);
  --icon-grey: invert(67%) sepia(24%) saturate(35%) hue-rotate(213deg) brightness(120%) contrast(109%);
  --icon-grey-dark: invert(84%) sepia(2%) saturate(16%) hue-rotate(314deg) brightness(99%) contrast(78%);
  --icon-grey-very-dark: invert(48%) sepia(0%) saturate(794%) hue-rotate(252deg) brightness(97%) contrast(95%);
  --icon-grey-extreme: invert(50%) sepia(19%) saturate(17%) hue-rotate(53deg) brightness(91%) contrast(91%);
  --icon-grey-mobile: invert(70%) sepia(10%) saturate(166%) hue-rotate(162deg) brightness(91%) contrast(98%);
  --icon-white: invert(100%) sepia(0%) saturate(2953%) hue-rotate(1deg) brightness(123%) contrast(102%);
  --icon-white-light: invert(100%) sepia(0%) saturate(2953%) hue-rotate(1deg) brightness(123%) contrast(102%) opacity(40%);
  --icon-red-pink: invert(36%) sepia(71%) saturate(3979%) hue-rotate(331deg) brightness(102%) contrast(98%);
  --icon-red: invert(47%) sepia(32%) saturate(6368%) hue-rotate(335deg) brightness(92%) contrast(109%);
  --icon-red-kind: invert(14%) sepia(50%) saturate(3265%) hue-rotate(316deg) brightness(95%) contrast(101%);
  --icon-green: invert(76%) sepia(16%) saturate(1827%) hue-rotate(81deg) brightness(94%) contrast(88%);
  --icon-green-flash: invert(89%) sepia(11%) saturate(5686%) hue-rotate(71deg) brightness(90%) contrast(84%);
  --icon-green-twitter: invert(62%) sepia(87%) saturate(4617%) hue-rotate(130deg) brightness(130%) contrast(101%);
  --icon-private: invert(85%) sepia(9%) saturate(7143%) hue-rotate(327deg) brightness(100%) contrast(104%);
  --icon-orange: invert(76%) sepia(49%) saturate(1509%) hue-rotate(337deg) brightness(103%) contrast(101%);
  --icon-fire: brightness(0) saturate(100%) invert(51%) sepia(33%) saturate(5183%) hue-rotate(336deg) brightness(103%) contrast(101%);
  --icon-orange-dark: brightness(0) saturate(100%) invert(55%) sepia(18%) saturate(5722%) hue-rotate(9deg) brightness(103%) contrast(101%);
  --icon-blue-black-web: invert(57%) sepia(81%) saturate(5709%) hue-rotate(210deg) brightness(98%) contrast(94%);
  --icon-purple-black-web: invert(55%) sepia(51%) saturate(7393%) hue-rotate(237deg) brightness(103%) contrast(100%);
}

.black {
  --grey-sur-opac: #38383e;
  --grey-sur: #3b435c;
  --grey-sur-hover: rgb(48, 48, 36 / 55%);
  --grey-sur-hover-opac: #e9e9ef;
  --grey-alpha: #ffffff1a;
  --grey-alpha-half: #ffffff0d;
  --grey-light: rgb(48, 48, 36 / 80%);
  --grey: #141414;
  --grey-text: #a6afc8;
  --grey-text-very-dark: #888;
  --grey-text-dark: #d4d4d4;
  --grey-text-dark-blog: #d4d4d4;
  --grey-thumb: #777;
  --grey-extra-dark: #b5b5b5;
  --grey-shadow: #00000042;
  --grey-mobile: #5f5853;
  --grey-gradient: #373737;
  --grey-transparent: #3b3b3b00;
  --white: #1f2333;
  --white-light: #3b435c;
  --white-alpha: #00000029;
  --black: #fff;
  --black-hr: #c0c6c6;
  --black-alpha: #ffffff80;
  --black-light: #cfcdca;
  --black-middle: #cbcbcb;
  --colors-1: #ff8a19;
  --colors-2: #fe5b00;
  --colors-3: #ff4101;
  --colors-4: #ff2d00;
  --colors-5: #0b394c;
  --colors-6: #072734;
  --red-dark: #d8202a;
  --red-hover: #0b62c91a;
  --red-alpha: #10cbc133;
  --red-pink: #fd1a37;
  --red-light: #672927;
  --red-very-light: #000908;
  --red-real: #0fbdb5;
  --red-real-hover: #00c1b8;
  --red-flash: #ff0a0a;
  --red-notify: #e55050;
  --red-twitter: #f31515;
  --purple-mep: #4a2d28;
  --purple-dark: #74ae35;
  --purple-extra: #9342b5;
  --blue: #b29c04;
  --blue-mep: #e7880d;
  --blue-mep-hover: #ee9c2f;
  --blue-twt: #e2a0b0;
  --blue-twt-black: #0e77ca;
  --blue-hover: #b14ff0;
  --blue-punk: #dabe00;
  --blue-pulp: #ff6c11;
  --blue-dark: #b29c04;
  --blue-alpha-dark-light: #ff591233;
  --blue-alpha-dark: #ff59121a;
  --blue-selection: #ff76ff47;
  --blue-dark-hover: #3129094d;
  --blue-light: #1c1805;
  --blue-ultra-light: #fe68001a;
  --blue-cian: #170f01;
  --blue-new: #0c0400;
  --blue-new-text: #ff4c00;
  --blue-new-checked: #1b0800;
  --blue-new-hover: #200a00;
  --blue-str: #080303;
  --blue-str-hover: #1a1717;
  --blue-str-hover-hover: #212121;
  --blue-str-shadow: #1d1c1c;
  --blue-selection-shadow: #ff7b0080;
  --blue-mobile: #f08800;
  --blue-pastel: #705dff;
  --blue-twitter: #0054fe;
  --green: #bf2480;
  --green-flash: #d72aa3;
  --green-flash-hover: #d937a8;
  --green-flash-alpha: #a1269e4d;
  --green-hover: #c32482;
  --green-dark: #e73dc2;
  --green-light: #18003c;
  --green-light-dark: #290049;
  --green-very-light: #310060;
  --green-twitter: #003520;
  --shadow-livechat: #ffffff14 0px 0px 0px 1px, #ffffff14 0px 2px 8px;
  --orange-dark: #0f73d8;
  --orange-light: #001438;
  --orange-semi-light: #000fb8;
  --orange-very-light: #0012f1;
  --outline-blue: #ff7b0080;
  --private: #000d28;
  --private-text: #ff5721;
  --icon-black: invert(100%);
  --icon-blue: invert(60%) sepia(84%) saturate(4291%) hue-rotate(224deg) brightness(98%) contrast(100%);
  --icon-blue-light: invert(50%) sepia(53%) saturate(783%) hue-rotate(194deg) brightness(98%) contrast(97%);
  --icon-blue-mobile: invert(71%) sepia(26%) saturate(1619%) hue-rotate(208deg) brightness(94%) contrast(97%);
  --icon-blue-pulp: invert(65%) sepia(13%) saturate(1176%) hue-rotate(181deg) brightness(98%) contrast(99%);
  --icon-grey: invert(33%) sepia(76%) saturate(65%) hue-rotate(33deg) brightness(80%) contrast(91%);
  --icon-grey-very-dark: invert(52%) sepia(100%) saturate(206%) hue-rotate(108deg) brightness(103%) contrast(105%);
  --icon-grey-mobile: invert(30%) sepia(90%) saturate(34%) hue-rotate(342deg) brightness(109%) contrast(102%);
  --icon-white: invert(0%) sepia(0%) saturate(47%) hue-rotate(359deg) brightness(77%) contrast(98%);
  --icon-white-light: invert(0%) sepia(0%) saturate(47%) hue-rotate(359deg) brightness(77%) contrast(98%) opacity(60%);
  --icon-red-pink: invert(64%) sepia(29%) saturate(1979%) hue-rotate(331deg) brightness(98%) contrast(102%);
  --icon-red: invert(53%) sepia(68%) saturate(3368%) hue-rotate(5deg) brightness(108%) contrast(91%);
  --icon-green: invert(24%) sepia(84%) saturate(827%) hue-rotate(281deg) brightness(106%) contrast(112%);
  --icon-green-flash: invert(11%) sepia(89%) saturate(386%) hue-rotate(291deg) brightness(110%) contrast(116%);
  --icon-green-twitter: invert(38%) sepia(13%) saturate(1617%) hue-rotate(230deg) brightness(70%) contrast(99%);
  --icon-private: invert(15%) sepia(91%) saturate(143%) hue-rotate(47deg) brightness(100%) contrast(96%);
  --icon-orange: invert(24%) sepia(51%) saturate(509%) hue-rotate(17deg) brightness(97%) contrast(99%);
  --icon-orange-dark: brightness(0) saturate(100%) invert(45%) sepia(82%) saturate(2722%) hue-rotate(9deg) brightness(103%) contrast(101%);
}

@keyframes _7x5qp {
  0% {
    left: 0;
  }

  50% {
    left: 65%;
  }

  100% {
    left: 0;
  }
}

.globalLoaderBackground {
  z-index: 9999;
  background: var(--white);
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

.globalLoaderBackground .loader {
  margin-top: -40px;
  position: absolute;
}

.globalLoaderBackground .loader .logo {
  z-index: 5;
  width: 100%;
  height: 95px;
  position: relative;
}

.globalLoaderBackground .loader .logo .loading {
  background: var(--grey-sur);
  border-radius: 10px;
  width: 100px;
  height: 10px;
  position: relative;
}

.globalLoaderBackground .loader .logo .loading .caret {
  background: var(--grey-sur-hover);
  border-radius: 10px;
  width: 35%;
  height: 100%;
  animation: 1s infinite _7x5qp;
  position: absolute;
}

.globalLoaderBackground .loader .logo .logo {
  float: left;
  background: var(--grad-sedestral);
  border-radius: 40%;
  justify-content: center;
  align-items: center;
  width: 42px;
  height: 42px;
  display: flex;
  position: relative;
  top: 3px;
}

.globalLoaderBackground .loader .logo .logo .icon {
  width: 20px;
  height: 20px;
}

.globalLoaderBackground .loader .logo .text {
  color: var(--black);
  float: left;
  margin-left: 14px;
  font-family: bold, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 32px;
  position: relative;
  top: 4px;
}

.globalLoaderBackground .gradients .inboxGradient {
  background: var(--grad-inbox);
  width: 262px;
  height: 280px;
  position: fixed;
  top: 0;
  left: 0;
}

.globalLoaderBackground .gradients .blogGradient {
  background: var(--grad-blog);
  width: 138px;
  height: 240px;
  position: fixed;
  bottom: 0;
  left: 0;
}

.globalLoaderBackground .gradients .sellGradient {
  background: var(--grad-sell);
  width: 351px;
  height: 151px;
  position: fixed;
  bottom: 0;
  right: 120px;
}

.globalLoaderBackground .gradients .contactsGradient {
  background: var(--grad-contacts);
  width: 122px;
  height: 330px;
  position: fixed;
  top: 100px;
  right: -2px;
}

.globalLoaderBackground .gradients .publisherGradient {
  background: var(--grad-publisher);
  width: 370px;
  height: 120px;
  position: fixed;
  top: 0;
  right: calc(40% - 185px);
}

.globalLoaderBackground .gradients .gradient {
  width: calc(100% + 6px);
  height: calc(100% + 5px);
  position: absolute;
  top: -5px;
  left: -5px;
}

.globalLoaderBackground .gradients .background {
  opacity: 0;
  background-repeat: space;
  background-size: 18px;
}

.d9hJK {
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  padding: 20px;
  position: absolute;
}

.d9hJK .Aoupu {
  float: left;
  width: 50%;
  max-width: 420px;
  height: 100%;
}

.d9hJK .Aoupu .GkPC8 {
  float: left;
}

.d9hJK .Aoupu .GkPC8 ._6TdY7 {
  font-family: bold, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 200px;
}

.d9hJK .Aoupu .GkPC8 .OdVCJ {
  margin-bottom: 10px;
  font-family: bold, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 47px;
}

.d9hJK .Aoupu .GkPC8 .E7EQ0 {
  font-size: 18px;
}

.d9hJK .hpI31 {
  float: right;
  background: linear-gradient(to top, #ffe174, #f1e5ba);
  border-radius: 15px;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 100%;
  display: flex;
}

.d9hJK .hpI31 .Emzq9 {
  background: url("lineal-parachute.1b2d8e82.svg") 0 0 / cover;
  width: 340px;
  height: 340px;
}

.MdSsb {
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
}

.MdSsb .MpSeh {
  width: 100%;
  height: 50px;
}

.MdSsb .MpSeh .bL0rY {
  border-bottom: 3px solid var(--grey-sur);
  width: fit-content;
  margin-top: 11px;
  margin-left: 15px;
  font-family: extra-bold, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 22px;
}

.MdSsb .tQc9T {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100% - 62px);
  display: flex;
  position: absolute;
}

.MdSsb .tQc9T .joeNi {
  width: 380px;
  height: 400px;
  margin-top: -100px;
}

.MdSsb .tQc9T .joeNi .oIqL9 {
  background: url("3d-office.3e208232.svg") 0 0 / cover;
  width: 120px;
  height: 120px;
  margin-left: calc(50% - 60px);
}

.MdSsb .tQc9T .joeNi ._9DgBb {
  text-align: center;
  width: 100%;
  margin-top: 10px;
  font-family: bold, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 20px;
}

.MdSsb .tQc9T .joeNi ._1XD06 {
  color: var(--grey-text);
  text-align: center;
  margin-top: 10px;
  font-size: 14px;
}

.MdSsb .tQc9T .joeNi .D1aLr {
  background-color: var(--blue-str);
  width: calc(100% - 50px);
  box-shadow: 0 0 0 1px var(--blue-str-shadow);
  border-radius: 14px;
  margin-top: 20px;
  padding: 25px;
}

.MdSsb .tQc9T .joeNi .D1aLr .MSWwh {
  cursor: pointer;
  border-radius: 6px;
  width: 100%;
  height: 20px;
  max-height: 40px;
  margin-bottom: 5px;
  padding: 15px 0;
  font-family: semi-bold, Segoe UI Emoji, Montserrat, sans-serif;
  position: relative;
}

.MdSsb .tQc9T .joeNi .D1aLr .MSWwh:after {
  content: "";
  clear: both;
  display: block;
}

.MdSsb .tQc9T .joeNi .D1aLr .MSWwh:hover {
  background: var(--blue-str-hover);
}

.MdSsb .tQc9T .joeNi .D1aLr .MSWwh span {
  text-align: center;
  width: 100%;
  display: block;
}

.MdSsb .tQc9T .joeNi .D1aLr .MSWwh svg {
  width: 20px;
  height: 20px;
}

.MdSsb .tQc9T .joeNi .D1aLr .MSWwh ._7HNrv {
  float: left;
  width: 20px;
  height: 20px;
  margin-right: 10px;
  position: relative;
}

.MdSsb .tQc9T .joeNi .D1aLr .MSWwh ._5kw27 {
  float: left;
  font-family: semi-bold, Segoe UI Emoji, Montserrat, sans-serif;
}

.MdSsb .tQc9T .joeNi .D1aLr .MSWwh .P1TmC {
  float: right;
  background: url("black-bottom-bold.1b3c333e.svg") 0 0 / cover;
  width: 16px;
  height: 16px;
  margin-top: 2px;
}

.MdSsb .tQc9T .joeNi .D1aLr .CcwDt {
  background: var(--blue-str-hover);
  width: 100%;
  height: 2px;
  margin-bottom: 10px;
}

.MdSsb .tQc9T .joeNi .D1aLr .sp6xN, .MdSsb .tQc9T .joeNi .D1aLr ._4yS0g {
  background: var(--grey-sur);
  cursor: pointer;
  color: var(--white);
  background: var(--blue-str-hover);
  border: none;
  border-radius: 12px;
  justify-content: center;
  align-items: center;
  height: 16px;
  margin-top: 12px;
  padding: 12px;
  font-family: semi-bold, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 14px;
  transition: all .1s;
  display: flex;
  position: relative;
  color: var(--black) !important;
}

.MdSsb .tQc9T .joeNi .D1aLr .sp6xN:hover, .MdSsb .tQc9T .joeNi .D1aLr ._4yS0g:hover {
  background: var(--grey-sur-hover);
  background: var(--blue-str-hover-hover);
}

.MdSsb ._7st3p {
  background: url("colored-aquarius.7fb5272b.svg") 0 0 / cover;
  width: 160px;
  height: 160px;
  position: absolute;
  bottom: -15px;
  right: 50px;
}

.lN1gY {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.lN1gY .y48H6 {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
}

.lN1gY .y48H6 circle {
  stroke-width: 4px;
}

.lN1gY .y48H6 svg {
  z-index: 2;
  width: 30px;
  height: 30px;
  animation: 2s linear infinite ZeMux;
  position: absolute;
}

@keyframes ZeMux {
  100% {
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes _8TQdO {
  0% {
    stroke-dasharray: 1 150;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 90 150;
    stroke-dashoffset: -35px;
  }

  100% {
    stroke-dasharray: 90 150;
    stroke-dashoffset: -124px;
  }
}

.lN1gY .y48H6 svg .-Yh7x {
  stroke: var(--black);
  stroke-width: 3px;
  animation: 1.5s ease-in-out infinite _8TQdO;
  stroke-linecap: round !important;
}

.M-u8I {
  z-index: 9999999;
  background: #00000045;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

.M-u8I ._91Na6 {
  background: var(--white);
  background: var(--white);
  border-radius: 14px;
  width: 400px;
  height: calc(100% - 40px);
  padding: 20px;
  position: relative;
  overflow: hidden;
  box-shadow: 0 0 0 1px #00000008, 0 0 20px #0003;
  box-shadow: unset !important;
}

.M-u8I ._91Na6 .RR1Lv {
  position: relative;
}

.M-u8I ._91Na6 .RR1Lv:after {
  content: "";
  clear: both;
  display: block;
}

.M-u8I ._91Na6 .RR1Lv ._2gJrE {
  float: left;
  font-family: bold, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 18px;
}

.M-u8I ._91Na6 .RR1Lv .mKbbs {
  float: right;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  margin-top: -2px;
  transition: all .1s;
  display: flex;
}

.M-u8I ._91Na6 .RR1Lv .mKbbs:hover {
  cursor: pointer;
  background: var(--grey-sur);
}

.M-u8I ._91Na6 .RR1Lv .mKbbs .-coP- {
  width: 12px;
  height: 12px;
}

.M-u8I ._91Na6 .j-rId {
  display: flex;
}

.M-u8I ._91Na6 .j-rId ._2gJrE {
  font-family: bold, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 18px;
}

.M-u8I ._91Na6 .j-rId .mKbbs {
  cursor: pointer;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  margin-top: -2px;
  margin-left: auto;
  transition: all .1s;
  display: flex;
}

.M-u8I ._91Na6 .j-rId .mKbbs:hover {
  cursor: pointer;
  background: var(--grey-sur);
}

.M-u8I ._91Na6 .j-rId .mKbbs:active {
  box-shadow: var(--blue-selection-shadow) 0 0 0 4px;
  outline: 0;
  transition: all;
}

.M-u8I ._91Na6 .j-rId .mKbbs._4t882 {
  visibility: visible;
}

.M-u8I ._91Na6 .j-rId .mKbbs .-coP- {
  background: url("black-close.4640468b.svg") 0 0 / cover;
  width: 12px;
  height: 12px;
}

.M-u8I ._91Na6 .wUbdo {
  height: calc(100% - 26px);
  position: relative;
}

@media screen and (width <= 480px) {
  .M-u8I ._91Na6 {
    position: fixed;
    bottom: 0;
    border-radius: 14px 14px 0 0 !important;
    width: calc(100% - 40px) !important;
    padding: 20px !important;
  }
}

.seOEH {
  height: fit-content;
}

.seOEH .skqkt {
  font-family: bold, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 20px;
  position: relative;
}

.seOEH .SK6Wl {
  margin-top: 25px;
}

.P1Qa3 {
  width: 100%;
}

.P1Qa3 .vEpPs {
  border-bottom: 1px solid var(--grey-sur);
  width: 100%;
  height: 30px;
  margin-bottom: 10px;
}

.P1Qa3 .vEpPs .IGeX9 {
  float: left;
  cursor: pointer;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  width: 26px;
  height: 26px;
  margin-top: -2px;
  margin-right: 5px;
  transition: all .1s;
  display: flex;
}

.P1Qa3 .vEpPs .IGeX9:hover {
  cursor: pointer;
  background: var(--grey-sur);
}

.P1Qa3 .vEpPs .IGeX9:active {
  box-shadow: var(--blue-selection-shadow) 0 0 0 4px;
  outline: 0;
  transition: all;
}

.P1Qa3 .vEpPs .IGeX9 .vOA3l {
  background: url("black-left-arrow.c271e0b6.svg") 0 0 / cover;
  width: 16px;
  height: 16px;
}

.P1Qa3 .vEpPs ._89DM9 {
  font-family: semi-bold, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 18px;
}

._3ZoNJ input {
  background: var(--white-light);
  width: 100%;
  color: var(--black);
  border: 2px solid #eaeaea;
  border-radius: 10px;
  width: 100%;
  height: 40px;
  margin-top: 12px;
  padding-left: 10px;
  padding-right: 10px;
  font-family: medium, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 14px;
}

._3ZoNJ input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

._3ZoNJ input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

._3ZoNJ input[type="number"] {
  -moz-appearance: textfield;
}

._3ZoNJ input[type="date"]::-webkit-inner-spin-button {
  display: none;
}

._3ZoNJ input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
}

._3ZoNJ input::-webkit-input-placeholder {
  color: var(--grey-text);
}

._3ZoNJ input::placeholder {
  color: var(--grey-text);
}

._3ZoNJ input:focus {
  outline: 0;
}

._3ZoNJ input._0DLIZ, ._3ZoNJ input.Yg6XI {
  visibility: visible;
}

._3ZoNJ ._16y-3 {
  margin-top: 15px;
}

._3ZoNJ ._16y-3 div[btnc] {
  display: none;
}

.Lh6iX:after {
  content: "";
  clear: both;
  display: block;
}

.Lh6iX.OdoUB ._8NIB- {
  border-radius: 55px;
  background: var(--black) !important;
  color: var(--white) !important;
}

.Lh6iX.OdoUB ._8NIB-:hover {
  opacity: .7;
}

.Lh6iX.OdoUB .XlN05 {
  border-radius: 55px;
}

.Lh6iX ._8NIB- {
  background: var(--green);
  color: var(--white);
  cursor: pointer;
  float: right;
  border: none;
  border-radius: 10px;
  padding: 14px 25px;
  font-family: semi-bold, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 14px;
  transition: all;
  position: relative;
}

.Lh6iX ._8NIB-:hover {
  background: var(--green-flash-hover);
}

.Lh6iX ._8NIB-.uYb7g {
  filter: grayscale();
}

.Lh6iX ._8NIB- svg {
  width: 18px !important;
  height: 18px !important;
}

.Lh6iX ._8NIB- svg circle {
  stroke-width: 5px !important;
  stroke: var(--white) !important;
}

.Lh6iX .XlN05 {
  float: right;
  background: var(--grey-sur);
  cursor: pointer;
  border-radius: 10px;
  margin-right: 10px;
  padding: 14px 25px;
  font-family: semi-bold, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 14px;
  position: relative;
}

.Lh6iX .XlN05:hover {
  background: var(--grey-sur-hover);
  border-color: #0000 !important;
}

.Lh6iX .XlN05 svg {
  width: 18px !important;
  height: 18px !important;
}

.Lh6iX .XlN05 svg circle {
  stroke-width: 5px !important;
}

.fVBBq .ekyEZ {
  font-family: bold, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 14px;
  position: relative;
}

.fVBBq .llCoa {
  background: var(--white-light);
  width: 100%;
  color: var(--black);
  border: 2px solid #eaeaea;
  border-radius: 10px;
  width: 100%;
  height: 40px;
  margin-top: 12px;
  padding-left: 10px;
  padding-right: 10px;
  font-family: medium, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 14px;
}

.fVBBq .llCoa::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.fVBBq .llCoa::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.fVBBq .llCoa[type="number"] {
  -moz-appearance: textfield;
}

.fVBBq .llCoa[type="date"]::-webkit-inner-spin-button {
  display: none;
}

.fVBBq .llCoa[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
}

.fVBBq .llCoa::-webkit-input-placeholder {
  color: var(--grey-text);
}

.fVBBq .llCoa::placeholder {
  color: var(--grey-text);
}

.fVBBq .llCoa:focus {
  outline: 0;
}

.fVBBq .Jemon {
  margin-top: 15px;
}

.fVBBq .Jemon div[btnc] {
  display: none;
}

.gmTu0 {
  gap: 15px;
  display: flex;
}

.gmTu0 .rs-Ya {
  border: 2px solid var(--grey-sur);
  border-radius: 15px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 200px;
  transition: all .15s;
  display: flex;
  position: relative;
}

.gmTu0 .rs-Ya:hover {
  cursor: pointer;
  border: 2px solid var(--blue);
}

.gmTu0 .rs-Ya.zDvEK .n-YCY {
  background: url("3d-identity.90076bec.svg") 0 0 / cover;
}

.gmTu0 .rs-Ya.cVNN- .n-YCY {
  background: url("3d-writing.0554ed3a.svg") 0 0 / cover;
}

.gmTu0 .rs-Ya.wWdjm .n-YCY {
  background: url("3d-article-blank.7e221b79.svg") 0 0 / cover;
}

.gmTu0 .rs-Ya.iKfs0 .n-YCY {
  background: url("3d-ai-writing.8e185124.svg") 0 0 / cover;
}

.gmTu0 .rs-Ya.-UkJ- .n-YCY {
  width: 34px;
  height: 34px;
}

.gmTu0 .rs-Ya .n-YCY {
  width: 80px;
  height: 80px;
  margin-bottom: 10px;
}

.gmTu0 .rs-Ya ._8xlDx {
  text-align: center;
  font-family: semi-bold, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 17px;
}

.gmTu0 .rs-Ya .DHL-w {
  text-align: center;
  max-width: 90%;
  color: var(--grey-text-very-dark);
  height: 46px;
  margin-top: 10px;
  font-size: 13px;
}

.xqnPq {
  cursor: pointer;
  border-radius: 14px;
  align-items: center;
  width: calc(100% - 8px);
  margin-bottom: 2px;
  padding: 8px 4px;
  transition: all .1s;
  display: flex;
  position: relative;
}

.xqnPq:hover {
  cursor: pointer;
  background: var(--grey-sur);
}

.xqnPq .Inaak {
  float: left;
  width: 26px;
  height: 26px;
  position: relative;
}

.xqnPq .Inaak .iEuXb {
  border: var(--white) 2px solid;
  width: 14px;
  height: 14px;
  color: var(--white);
  background: var(--red);
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  font-family: semi-bold, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 10px;
  display: flex;
  position: absolute;
  top: -4px;
  right: -4px;
}

.xqnPq .mvy1a {
  float: left;
  align-items: center;
  margin-top: -4px;
  margin-left: 15px;
  display: flex;
}

.xqnPq .mvy1a .Nnb6i {
  font-family: bold, Segoe UI Emoji, Montserrat, sans-serif;
  position: relative;
}

.xqnPq .mvy1a .Nnb6i:after {
  content: "";
  clear: both;
  display: block;
}

.xqnPq .mvy1a .Nnb6i .SZXCH {
  float: left;
  overflow-wrap: normal;
  text-overflow: ellipsis;
  width: 154px;
  white-space: nowrap !important;
  overflow: hidden !important;
}

.xqnPq ._1iX3r {
  background: url("black-checked.c004d811.svg") 0 0 / cover;
  width: 14px;
  height: 14px;
  position: absolute;
  top: 19px;
  right: 10px;
}

.VRZUP {
  cursor: pointer;
  background: var(--grey-alpha);
  border-radius: 100%;
  width: 100%;
  height: 100%;
}

.VRZUP .bHs6q {
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  overflow: hidden;
}

.VRZUP .bHs6q .aU1Zc {
  width: 100%;
  position: absolute;
}

.VRZUP .bHs6q .qIxIz {
  width: 40%;
  height: 40%;
  filter: var(--icon-white);
}

.VRZUP .bHs6q .qIxIz.Zjhxj {
  background: url("black-user.044cfb3e.svg") 0 0 / cover;
}

.VRZUP .bHs6q .qIxIz.jMLMa {
  background: url("black-roles.cb9269fe.svg") 0 0 / cover;
}

.VRZUP .bHs6q .qIxIz.e6g5f {
  background: url("black-help-center.d3d2e2cf.svg") 0 0 / cover;
}

.VRZUP .bHs6q .qIxIz.xEPse {
  background: url("black-members.5e58f623.svg") 0 0 / cover;
}

.VRZUP .bHs6q .qIxIz.MTUPx {
  background: url("black-company.86c7f90c.svg") 0 0 / cover;
}

.VRZUP .bHs6q .qIxIz.b9Y0T {
  background: url("black-livechat.9dbc507d.svg") 0 0 / cover;
}

.VRZUP .bHs6q .qIxIz.oqhiS {
  background: url("black-bot.1b6be133.svg") 0 0 / cover;
  width: 50%;
  height: 50%;
}

.VRZUP .-n8LO {
  border: var(--white) 2px solid;
  background: var(--red-real);
  border-radius: 100%;
  width: 20%;
  height: 20%;
  position: absolute;
  top: -1px;
  right: -1px;
}

.VRZUP .-n8LO.fcekX {
  background: var(--green);
}

.VRZUP .-n8LO.BqQa- {
  background: var(--red);
}

.VRZUP .Blnge {
  background: var(--white);
  border: 1px solid #fff;
  border-radius: 100%;
  width: 18px;
  height: 18px;
  position: absolute;
  bottom: -2px;
  left: -2px;
}

.VRZUP .bLqoW {
  border: 2px solid var(--white);
  background: var(--white);
  border-radius: 100%;
  width: 12px;
  height: 12px;
  position: absolute;
  bottom: -2px;
  right: -2px;
  overflow: hidden;
}

.VRZUP .bLqoW .eOgwf {
  background: var(--grey-sur);
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-family: bold, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 8px;
  display: flex;
  position: absolute;
}

._8wXnX {
  width: 100%;
  height: 100%;
}

._8wXnX._3666q, ._8wXnX.w9-CC {
  background: url("black-inbox.842b1bac.svg") 0 0 / cover;
}

._8wXnX.C5aOO {
  background: url("black-logo.ed21fc38.svg") 0 0 / cover;
}

._8wXnX.IT55P {
  background: url("black-sell.b06a484c.svg") 0 0 / cover;
}

._8wXnX.PIrpP {
  background: url("black-blog.6aee743a.svg") 0 0 / cover;
}

._8wXnX.iUHNS {
  background: url("black-statistics.0dc2f485.svg") 0 0 / cover;
}

._8wXnX.VEt1c {
  background: url("black-channels.2948e5a7.svg") 0 0 / cover;
}

._8wXnX._6V-hK {
  background: url("black-help.660bed67.svg") 0 0 / cover;
}

._8wXnX.X2WtW, ._8wXnX.N8plC {
  background: url("black-publisher.bff1a570.svg") 0 0 / cover;
}

._8wXnX.cw9eh, ._8wXnX.JpkLN {
  background: url("black-user.044cfb3e.svg") 0 0 / cover;
}

._8wXnX.Yw9YY {
  background: url("black-bot.1b6be133.svg") 0 0 / cover;
}

._8wXnX.BVFrS {
  background: url("black-email.dc2e55a5.svg") 0 0 / cover;
}

._8wXnX.j-yl6 {
  background: url("black-livechat.9dbc507d.svg") 0 0 / cover;
}

._8wXnX.kMETM {
  background: url("black-mail.460d7cb4.svg") 0 0 / cover;
}

._8wXnX._2DevX {
  background: url("black-marketing.7136008c.svg") 0 0 / cover;
}

._8wXnX.G8G4P {
  background: url("black-mailling.3b5d62a8.svg") 0 0 / cover;
}

._8wXnX.-Hrqj {
  background: url("black-media.87f66ec4.svg") 0 0 / cover;
}

._8wXnX.LdtAG {
  background: url("black-calendar.c7dd70fc.svg") 0 0 / cover;
}

._8wXnX.-ri-N {
  background: url("black-locker.e6024807.svg") 0 0 / cover;
}

._8wXnX._748by {
  background: url("black-box.6a4a7ff0.svg") 0 0 / cover;
}

._8wXnX.cHAKr {
  background: url("black-estimate.50b9aed3.svg") 0 0 / cover;
}

._8wXnX.RxvUN, ._8wXnX.mkZ4G {
  background: url("black-logo.ed21fc38.svg") 0 0 / cover;
}

._8wXnX.lz-5- {
  background: url("black-help-center.d3d2e2cf.svg") 0 0 / cover;
}

._8wXnX.vUyaI {
  background: url("black-document.c1759d89.svg") 0 0 / cover;
}

.Tt0wR {
  background: var(--grey);
  border-radius: 40%;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
}

.Tt0wR .HoMZO {
  width: 40%;
  height: 40%;
  filter: var(--icon-white);
}

.Tt0wR .Cbv0h {
  background: var(--red);
  border-radius: 15px;
  justify-content: center;
  align-items: center;
  width: 60%;
  height: 30%;
  font-size: 2.5vw;
  display: flex;
  position: absolute;
  bottom: 0;
  right: -5px;
}

.Tt0wR .Cbv0h .Pw8r6 {
  background: none;
  align-items: center;
  width: 80%;
  height: 100%;
  display: flex;
  position: relative;
}

.Tt0wR.nKAd9 .HoMZO, .Tt0wR.tqGEO .HoMZO, .Tt0wR.R71fv .HoMZO, .Tt0wR._9REBJ .HoMZO, .Tt0wR._3S-uD .HoMZO, .Tt0wR.d0uDN .HoMZO, .Tt0wR.K3bc5 .HoMZO, .Tt0wR.S8omn .HoMZO, .Tt0wR.Fw9WT .HoMZO, .Tt0wR.wZ-cV .HoMZO, .Tt0wR.eZksr .HoMZO, .Tt0wR._1l-33 .HoMZO, .Tt0wR.Hin-K .HoMZO, .Tt0wR.Bbqm8 .HoMZO, .Tt0wR._144aY .HoMZO, .Tt0wR.wUQcP .HoMZO, .Tt0wR._-2It8 .HoMZO {
  filter: var(--icon-black);
}

.Tt0wR.XWipe {
  background: var(--grad-inbox);
}

.Tt0wR.ifwk- {
  background: var(--grad-statistics);
}

.Tt0wR.bNX2C {
  background: var(--grad-blog);
}

.Tt0wR._5KAfU {
  background: var(--grad-help-center);
}

.Tt0wR.vM-fJ {
  background: var(--grad-sell);
}

.Tt0wR.eaoid {
  background: var(--grad-publisher);
}

.Tt0wR._0hqmv {
  background: var(--grad-contacts);
}

.Tt0wR.s3pKw, .Tt0wR.-rKLq {
  background: var(--grad-sedestral);
}

._0D8ml {
  background: none;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  overflow: hidden;
}

.CyXTF {
  width: 100%;
  height: 100%;
  padding-top: 10px;
}

.CyXTF div[site] {
  width: calc(100% - 7px);
}

.CyXTF div[scrolltrack] {
  height: calc(100% - 20px);
  top: 10px;
  right: -12px !important;
}

._7zFcP {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
}

._7zFcP .TsvOz {
  margin-top: -100px;
}

._7zFcP .TsvOz .uECWc {
  background: url("3d-locked.959bd82b.svg") 0 0 / cover;
  width: 120px;
  height: 120px;
  margin-bottom: 20px;
  margin-left: calc(50% - 60px);
}

._7zFcP .TsvOz .Wd5Gn {
  text-align: center;
  width: 360px;
  font-family: bold, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 20px;
}

._7zFcP .pyth- {
  border-radius: 5px;
  width: 600px;
  height: 20px;
  position: absolute;
  bottom: 20px;
  left: calc(50% - 300px);
  overflow: hidden;
}

._7zFcP .pyth-._86Vex {
  bottom: unset;
  top: 20px;
}

._7zFcP .pyth- ._3dCRr {
  float: left;
  width: 16.666%;
  height: 100%;
}

._7zFcP .pyth- ._3dCRr.-YW3H {
  background: var(--colors-1);
}

._7zFcP .pyth- ._3dCRr.jsAhO {
  background: var(--colors-2);
}

._7zFcP .pyth- ._3dCRr.jmoai {
  background: var(--colors-3);
}

._7zFcP .pyth- ._3dCRr.iw0Py {
  background: var(--colors-4);
}

._7zFcP .pyth- ._3dCRr.xkme7 {
  background: var(--colors-5);
}

._7zFcP .pyth- ._3dCRr.Ks-xm {
  background: var(--colors-6);
}

.e-HHS .D8Wan {
  border-bottom: 1px solid var(--grey-sur);
  cursor: pointer;
  width: 100%;
  height: 46px;
  display: flex;
  position: relative;
}

.e-HHS .D8Wan ._7g7Ks {
  align-items: center;
  height: 100%;
  margin-left: 15px;
  display: flex;
}

.e-HHS .D8Wan ._7g7Ks ._5nqUv {
  width: 30px;
  height: 30px;
  position: relative;
}

.e-HHS .D8Wan ._7g7Ks .oFaJ7 {
  margin-left: 15px;
  font-family: semi-bold, Segoe UI Emoji, Montserrat, sans-serif;
}

.e-HHS .D8Wan ._7g7Ks .WYl4r {
  background: var(--offer-free);
  color: var(--white);
  border-radius: 8px;
  margin-left: 8px;
  padding: 4px 8px;
  font-size: 12px;
}

.e-HHS .D8Wan ._0L2Nh {
  align-items: center;
  height: 100%;
  margin-left: auto;
  margin-right: 15px;
  display: flex;
}

.e-HHS .D8Wan ._0L2Nh .IKCyP {
  color: var(--grey-text-dark);
  font-size: 13px;
}

.e-HHS .D8Wan ._0L2Nh .l7uch {
  background: var(--offer-free);
  color: var(--white);
  border-radius: 8px;
  margin-left: 10px;
  padding: 10px 14px;
  font-family: semi-bold, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 14px;
}

.e-HHS ._0WZXB {
  height: 100%;
}

.e-HHS ._0WZXB.m73Xi {
  height: calc(100% - 47px);
}

.e-HHS .Zl448 {
  z-index: 6;
  background: var(--white);
  height: 49px;
  position: relative;
  border-bottom: 1px solid var(--grey-sur) !important;
}

.e-HHS .Zl448:after {
  content: "";
  clear: both;
  display: block;
}

.e-HHS .Zl448 .PFN-E {
  float: left;
  width: 30px;
  height: 30px;
  margin-top: 10px;
  margin-left: 12px;
}

.e-HHS .Zl448 .Tn1fN {
  float: left;
  width: fit-content;
  margin-top: 13px;
  margin-left: 16px;
  font-family: bold, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 20px;
}

.e-HHS .Zl448 .t2VMc {
  float: left;
  width: fit-content;
  margin-top: 13px;
  margin-left: 8px;
  font-family: bold, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 20px;
}

.e-HHS .Zl448 ._8tyun {
  float: right;
  width: calc(100% - 170px);
  height: 100%;
}

.e-HHS .Zl448 ._8tyun .LET9l {
  border-left: 1px solid var(--grey-sur);
  width: 100%;
  height: 35px;
  margin-top: 7px;
  padding-left: 10px;
}

.e-HHS .Zl448 ._8tyun .LET9l .XvKY6 {
  float: left;
  cursor: pointer;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-right: 10px;
  padding: 0 10px;
  transition: all .2s;
  display: flex;
  position: relative;
  overflow: hidden;
}

.e-HHS .Zl448 ._8tyun .LET9l .XvKY6:hover {
  background: var(--grey-sur);
}

.e-HHS .Zl448 ._8tyun .LET9l .XvKY6.-al70 {
  visibility: visible;
}

.e-HHS .Zl448 ._8tyun .LET9l .XvKY6[checked]:after {
  width: 100%;
  left: 0;
  top: unset;
  content: "";
  background: var(--grey-sur);
  height: 100%;
  position: absolute;
  bottom: 0;
}

.e-HHS .Zl448 ._8tyun .LET9l .XvKY6 .JpQtF {
  float: left;
  z-index: 2;
  border-radius: 6px;
  width: 14px;
  height: 14px;
  margin-right: 5px;
}

.e-HHS .Zl448 ._8tyun .LET9l .XvKY6 .JpQtF .Xq6l6 {
  background: url("black-plus.2ea284a8.svg") 0 0 / cover;
  width: 12px;
  height: 12px;
  margin-top: 2px;
}

.e-HHS .Zl448 ._8tyun .LET9l .XvKY6 .IKCyP {
  float: left;
  z-index: 2;
  font-family: bold, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 16px;
}

@media (width <= 480px) {
  .e-HHS .D8Wan ._7g7Ks .WYl4r, .e-HHS .D8Wan ._0L2Nh .IKCyP {
    display: none;
  }
}

._4kuzS {
  width: 100%;
  height: 100%;
}

._4kuzS.U-yIa {
  background: url("3d-offer-basic.502b469d.svg") 0 0 / cover;
}

._4kuzS.-LTPy {
  background: url("3d-offer-medium.9e623a4e.svg") 0 0 / cover;
}

._4kuzS.ikg9- {
  background: url("3d-offer-pro.058f91ff.svg") 0 0 / cover;
}

.XlFv1 {
  width: calc(100% - 1px);
  height: 100%;
  color: var(--black);
  padding-right: 2px;
  overflow: auto;
}

.XlFv1.cCXA8 .U1m8K {
  display: none;
}

.XlFv1.Oy7Bn ._0MZGS {
  margin-bottom: 100px;
}

.XlFv1.Oy7Bn .uR2dm {
  visibility: visible;
  background: var(--white);
  width: 100%;
  margin-bottom: 0;
  padding-bottom: 15px;
  position: absolute;
  bottom: 0;
}

.XlFv1 div[scrolltrack] {
  height: calc(100% - 20px);
  right: -14px;
}

.XlFv1 .Q9D5i {
  height: calc(100% - 40px);
  padding: 20px;
}

.XlFv1 .sk6Ee {
  flex-direction: column;
  align-items: center;
  gap: 8px;
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
}

.XlFv1 .sk6Ee .U1m8K {
  text-align: center;
  -webkit-text-fill-color: transparent;
  background: -webkit-linear-gradient(#000, #606060);
  -webkit-background-clip: text;
  width: 480px;
  font-family: bold, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 20px;
}

.XlFv1 .sk6Ee .V8CB3 {
  border: 3px solid var(--grey-sur);
  border-radius: 14px;
  width: 240px;
  height: 26px;
  padding: 4px;
}

.XlFv1 .sk6Ee .V8CB3 div[chentry] {
  font-family: semi-bold, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 14px;
}

.XlFv1 .sk6Ee .JJztT {
  background: var(--black);
  color: var(--white);
  border-radius: 25px;
  padding: 6px 10px;
  font-size: 12px;
}

.XlFv1 .pCHxs {
  gap: 30px;
  padding: 3px;
  display: flex;
}

.XlFv1 .pCHxs .sUbOC {
  background: var(--grey-sur);
  cursor: pointer;
  border-radius: 25px;
  width: 33.33%;
  padding: 15px 0;
  position: relative;
}

.XlFv1 .pCHxs .sUbOC:hover, .XlFv1 .pCHxs .sUbOC.gP8z9 {
  cursor: pointer;
  outline: unset;
  box-shadow: var(--outline-blue) 0 0 0 3px !important;
}

.XlFv1 .UA1jT {
  margin-top: 20px;
  margin-bottom: 20px;
}

.XlFv1 .UA1jT.qddhs {
  display: none !important;
}

.XlFv1 .UA1jT .b9Ca- {
  margin-bottom: 15px;
  font-family: semi-bold, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 14px;
}

.XlFv1 .UA1jT .SsI6m {
  flex-direction: column;
  gap: 10px;
  display: flex;
}

.XlFv1 .UA1jT .SsI6m .kj4UB {
  border: 1px solid var(--grey-sur);
  border-radius: 12px;
  width: 100%;
  height: 40px;
}

.XlFv1 ._0MZGS {
  border: 1px solid var(--grey-sur);
  border-radius: 12px;
  margin-top: 20px;
  margin-bottom: 25px;
  padding: 12px;
}

.XlFv1 .uR2dm {
  border-top: 1px solid var(--grey-sur);
  margin-bottom: 20px;
  padding-top: 15px;
}

@media screen and (width <= 480px) {
  .XlFv1 .pCHxs {
    flex-direction: column;
  }

  .XlFv1 .pCHxs .sUbOC {
    width: 100%;
  }
}

.qc9Zu .IL033 {
  font-family: semi-bold, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 16px;
}

.qc9Zu .CQODP {
  width: calc(100% - 20px);
  margin: 8px -5px;
  font-size: 15px;
}

.qc9Zu .CQODP:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  padding-left: 20px;
}

.qc9Zu .CQODP:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  padding-left: 20px;
}

.qc9Zu .CQODP:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  padding-right: 20px;
}

.qc9Zu .CQODP:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  padding-right: 20px;
}

.qc9Zu .CQODP li {
  border-bottom: 1px solid #00000012;
  margin-bottom: 5px;
  padding-bottom: 5px;
  list-style-type: disc;
}

.qc9Zu .CQODP li._5GN64 {
  margin-bottom: 10px;
  padding-bottom: 10px;
  font-family: semi-bold, Segoe UI Emoji, Montserrat, sans-serif;
}

.EUyoY {
  align-items: center;
  height: 100%;
  display: flex;
}

.EUyoY .mVkxk {
  align-items: center;
  height: 100%;
  margin-left: 15px;
  font-family: semi-bold, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 16px;
  display: flex;
}

.EUyoY .lvn7g {
  margin-left: auto;
  margin-right: 15px;
  display: flex;
}

.EUyoY .lvn7g ._1tERG {
  background: var(--grey-sur);
  cursor: pointer;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  display: flex;
}

.EUyoY .lvn7g ._1tERG:hover {
  background: var(--grey-sur-hover);
}

.EUyoY .lvn7g ._1tERG.P5YuJ .BXKYA {
  background: url("black-less.1374fc7e.svg") 0 0 / cover;
}

.EUyoY .lvn7g ._1tERG.GWDS3 .BXKYA {
  background: url("black-plus.2ea284a8.svg") 0 0 / cover;
}

.EUyoY .lvn7g ._1tERG .BXKYA {
  width: 12px;
  height: 12px;
}

.EUyoY .lvn7g ._4f7rU {
  border: unset;
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 50px;
  font-family: semi-bold, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 18px;
  display: flex;
}

.yzXs- {
  justify-content: flex-end;
  align-items: center;
  margin-left: auto;
  display: flex;
}

.yzXs- .uMQLz {
  margin-right: 30px;
}

.yzXs- .uMQLz .OWv0j {
  font-family: semi-bold, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 14px;
}

.yzXs- .uMQLz .kb75t {
  font-family: bold, Segoe UI Emoji, Montserrat, sans-serif;
}

.yzXs- .uMQLz .kb75t .h4AwQ {
  font-size: 28px;
}

.yzXs- .uMQLz .kb75t .D-iwl {
  font-size: 16px;
}

.yzXs- .tKSgY {
  background: var(--offer-free);
  height: fit-content;
  color: var(--white);
  cursor: pointer;
  border-radius: 12px;
  padding: 14px 20px;
  font-family: semi-bold, Segoe UI Emoji, Montserrat, sans-serif;
  position: relative;
}

.yzXs- .tKSgY:hover {
  background: var(--offer-free-hover);
}

.yzXs- .tKSgY._6MlXq {
  color: #0000;
}

.OEmeL {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.OEmeL .Pc1iI {
  width: 50%;
  margin-top: -10px;
  margin-bottom: 25px;
  margin-left: 25%;
}

.OEmeL .StgcT {
  height: calc(100% - 115px);
  position: relative;
  overflow: hidden;
}

.FMZE3 {
  background: var(--grey-sur);
  border-radius: 15px;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.FMZE3.lcPDH {
  overflow: hidden;
}

@keyframes e1j1C {
  0% {
    -ms-transform: translateX(100%);
    transform: translateX(100%);
  }

  100% {
    -ms-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}

.FMZE3.lcPDH:after {
  content: "";
  background: linear-gradient(90deg, transparent, var(--white), transparent);
  width: 100%;
  height: 100%;
  animation: 1s infinite e1j1C;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  -ms-transform: translateX(-100%);
  transform: translateX(-100%);
}

.FWezv {
  width: 100%;
  overflow: hidden;
}

.FWezv.FJOsh {
  overflow: hidden;
}

@keyframes MkV2y {
  0% {
    -ms-transform: translateX(100%);
    transform: translateX(100%);
  }

  100% {
    -ms-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}

.FWezv.FJOsh:after {
  content: "";
  background: linear-gradient(90deg, transparent, var(--white), transparent);
  width: 100%;
  height: 100%;
  animation: 1s infinite MkV2y;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  -ms-transform: translateX(-100%);
  transform: translateX(-100%);
}

.FWezv .Hs9sf {
  background: var(--grey-sur);
  border-radius: 14px;
  width: 100%;
  margin-top: 10px;
  position: relative;
}

.Ple9y {
  width: calc(100% + 8px);
  height: 100%;
  position: relative;
}

.Ple9y .-BMvC {
  cursor: pointer;
  float: left;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  width: calc(50% - 4px);
  height: 100%;
  display: flex;
  position: relative;
  top: 0;
}

.Ple9y .-BMvC.vMVi1 {
  background: var(--grey-sur);
  left: 0;
  border-color: #0000 !important;
  position: absolute !important;
}

.Ple9y .-BMvC .NaNLE {
  width: 16px;
  height: 16px;
}

._5F2IX {
  height: 100%;
}

._5F2IX .q3klv {
  cursor: pointer;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 14px;
  display: flex;
}

._5F2IX .q3klv:hover {
  text-decoration: underline;
}

._5F2IX .q3klv .pmorz {
  background: url("black-left-arrow.c271e0b6.svg") 0 0 / cover;
  width: 10px;
  height: 10px;
  margin-top: 3px;
  margin-right: 5px;
}

._5F2IX .PjWcP {
  border: 1px solid var(--grey-sur);
  border-radius: 12px;
  margin-bottom: 10px;
  padding: 10px;
}

._5F2IX .PjWcP .amZSr {
  border-bottom: 1px solid var(--grey-sur);
  align-items: center;
  margin-bottom: 15px;
  padding-bottom: 10px;
  display: flex;
}

._5F2IX .PjWcP .amZSr .M5Ge7 {
  width: 22px;
  height: 22px;
  margin-right: 10px;
  position: relative;
}

._5F2IX .PjWcP .amZSr .zKT09 {
  font-family: semi-bold, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 18px;
}

._5F2IX ._7XTTr {
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;
  gap: 25px;
  margin-bottom: 100px;
  font-size: 14px;
  display: flex;
}

._5F2IX ._7XTTr .Dmndu {
  width: 170px;
}

._5F2IX ._7XTTr .Dmndu input {
  height: 36px;
  border: 1px solid var(--grey-sur) !important;
}

._5F2IX ._6-jQd ._03LXX {
  margin-top: 25px;
  margin-bottom: 10px;
  font-size: 14px;
  display: flex;
}

._5F2IX ._6-jQd ._03LXX span {
  margin-left: 10px;
}

._5F2IX .bLieP {
  border: 1px solid var(--grey-sur);
  border-radius: 12px;
  margin-bottom: 15px;
  padding: 12px;
  position: relative;
}

._5F2IX .bLieP .VKtj9 {
  margin-bottom: 10px;
  font-family: semi-bold, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 16px;
}

._5F2IX .bLieP .VKtj9 span {
  font-family: medium, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 13px;
}

._5F2IX .bLieP .VKtj9 .QVU1G {
  color: var(--grey-text-dark);
  font-family: medium, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 14px;
}

._5F2IX .bLieP .uawxm {
  font-size: 14px;
}

._5F2IX .bLieP .uawxm._2ODnS {
  color: var(--grey-text-very-dark);
}

._5F2IX .bLieP .p23dZ {
  font-family: semi-bold, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 20px;
}

._5F2IX .bLieP ._2lpps {
  background: var(--blue);
  color: var(--white);
  border-radius: 10px;
  align-items: center;
  padding: 6px 10px;
  font-size: 14px;
  display: flex;
  position: absolute;
  top: 12px;
  right: 12px;
}

._5F2IX .bLieP ._2lpps .pmorz {
  width: 12px;
  height: 12px;
  filter: var(--icon-white);
  background: url("black-download.fc480158.svg") 0 0 / cover;
  margin-left: 10px;
}

._5F2IX .cRi-p {
  border: 1px solid var(--grey-sur);
  border-radius: 12px;
  margin-bottom: 100px;
  padding: 14px;
}

._5F2IX .cRi-p .UyISm {
  border: 2px solid var(--red);
  color: var(--red-real);
  border-radius: 5px;
  margin-bottom: 25px;
  padding: 14px;
  font-size: 14px;
}

._5F2IX .UcQJZ {
  border-top: 1px solid var(--grey-sur);
  text-align: right;
  margin-bottom: 24px;
  padding-top: 15px;
  font-size: 14px;
}

._5F2IX .H73mJ {
  border: 1px solid var(--grey-sur);
  border-radius: 12px;
  height: 96px;
  margin-top: 14px;
  margin-bottom: 14px;
  padding: 16px;
  position: relative;
}

._5F2IX .H73mJ .pmorz {
  background: url("3d-time.37073be0.svg") 0 0 / cover;
  width: 50px;
  height: 50px;
}

._5F2IX ._9iP2Y div[btns] {
  background: var(--offer-cancel);
}

._5F2IX .X3f6l {
  background: var(--white);
  border-top: 1px solid var(--grey);
  z-index: 2;
  width: 100%;
  padding: 15px 0;
  position: absolute;
  bottom: 0;
}

.aqsVz {
  border-radius: 15px;
  align-items: center;
  width: 100%;
  height: 100%;
  font-family: medium, Segoe UI Emoji, Montserrat, sans-serif;
  display: flex;
  position: relative;
}

.aqsVz._2-8xc .HZ1gj {
  background: var(--white);
  z-index: 2;
  height: 22px;
  font-family: semi-bold, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 14px;
  position: absolute;
  top: -11px;
  left: 10px;
  padding: 0 5px !important;
}

.aqsVz.vogRx ._88EKk {
  box-shadow: 0 0 0 3px var(--red-light) !important;
  border-color: var(--red) !important;
}

.aqsVz.vogRx .HZ1gj {
  color: var(--red) !important;
}

.aqsVz._9dWOo ._88EKk {
  font-family: bold, Segoe UI Emoji, Montserrat, sans-serif;
}

.aqsVz._3ype4 ._88EKk {
  font-family: semi-bold, Segoe UI Emoji, Montserrat, sans-serif;
}

.aqsVz.b2gKv ._88EKk:focus {
  box-shadow: var(--outline-blue) 0 0 0 2px;
}

.aqsVz.-CEZY ._88EKk {
  border-width: 1px;
  border-color: #0000;
}

.aqsVz.-CEZY ._88EKk:focus {
  border-color: #0000;
}

.aqsVz .N5bLS {
  align-items: center;
  width: 80px;
  height: 100%;
  display: flex;
}

.aqsVz .N5bLS div[data-selectbox] {
  height: calc(100% - 8px);
  margin-right: 10px;
}

.aqsVz .HZ1gj {
  pointer-events: none;
  z-index: 0;
  height: 100%;
  color: var(--black);
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  transition: all .2s;
  display: flex;
  position: absolute;
  top: 0;
}

.aqsVz .HZ1gj.ek2RZ {
  display: none !important;
}

.aqsVz ._88EKk {
  z-index: 1;
  border-style: solid;
  border-color: var(--grey-sur);
  background: none;
  border-width: 2px;
  height: 100%;
  padding: 0;
}

.aqsVz ._88EKk:focus {
  border-color: var(--grey-sur-hover);
}

.WgQ0f {
  cursor: pointer;
  border: var(--grey-sur) 2px solid;
  width: calc(100% - 24px);
  height: calc(100% - 4px);
  color: var(--black);
  border-radius: 10px;
  align-items: center;
  padding: 0 10px;
  font-family: semi-bold, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 14px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.WgQ0f:hover {
  cursor: pointer;
  background: var(--grey-sur);
  border-color: #0000 !important;
}

.WgQ0f.svHEm {
  pointer-events: none;
  background: var(--grey-sur);
}

.WgQ0f .JBpGF {
  pointer-events: none;
  width: 12px;
  height: 12px;
  filter: var(--icon-black);
  background: url("black-bottom-bold.1b3c333e.svg") 0 0 / cover;
  margin-left: auto;
}

.WgQ0f .vhYdc {
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
}

@media screen and (width <= 480px) {
  .WgQ0f {
    font-size: 16px !important;
  }
}

.ELPJA {
  z-index: 99999999;
  background: var(--white);
  border-radius: 14px;
  height: fit-content;
  padding: 15px;
  position: fixed;
  box-shadow: 0 0 0 1px #00000008, 0 0 20px #0003;
}

._50N-- {
  z-index: 99999;
  cursor: pointer;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

._9PF-j {
  position: relative;
  overflow: hidden;
}

._9PF-j .T-B3N {
  align-items: center;
  margin-top: 5px;
  margin-bottom: 10px;
  display: flex;
}

._9PF-j .T-B3N:after {
  content: "";
  clear: both;
  display: block;
}

._9PF-j .T-B3N .f-EUc {
  filter: var(--icon-grey-dark);
  float: left;
  background: url("black-search.1ec91ec2.svg") 0 0 / cover;
  width: 14px;
  height: 14px;
  margin-top: 2px;
  margin-left: 4px;
  margin-right: 10px;
}

._9PF-j .T-B3N .cFmpY {
  float: left;
  width: calc(100% - 20px);
  padding: 5px;
  font-family: semi-bold, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 13px;
  border: unset !important;
}

._9PF-j .hHIr0 {
  max-height: 100%;
}

._9PF-j .hHIr0[scrollvirtual] .BFocA {
  width: calc(100% - 18px) !important;
}

._9PF-j .hHIr0[scrollvirtual] div[scrolltrack] {
  height: calc(100% - 16px);
  top: 8px;
  right: 0 !important;
}

._9PF-j .hHIr0 ._-8Nbr .BFocA {
  cursor: pointer;
  width: calc(100% - 10px);
  color: var(--black);
  border-radius: 8px;
  align-items: center;
  margin-bottom: 2px;
  padding: 5px;
  font-family: semi-bold, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 14px;
  display: flex;
}

._9PF-j .hHIr0 ._-8Nbr .BFocA:hover, ._9PF-j .hHIr0 ._-8Nbr .BFocA._9LzAM {
  background: var(--grey-sur);
}

._9PF-j .hHIr0 ._-8Nbr .BFocA._9LzAM:hover {
  background: var(--grey-sur-hover);
}

._9PF-j .hHIr0 ._-8Nbr .BFocA.bLGZA {
  display: block;
}

._9PF-j .hHIr0 ._-8Nbr .BFocA .hFpcJ {
  color: var(--grey-text-dark);
  margin-top: 10px;
  font-family: medium, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 13px;
}

._9PF-j .hHIr0 ._-8Nbr .BFocA div[buttonIcon] {
  float: left;
  width: 16px;
  height: 16px;
  margin-top: 1px;
  margin-left: 0;
  margin-right: 6px;
  position: relative;
}

._9PF-j .hHIr0 ._-8Nbr .BFocA div[buttonAvatar] {
  float: left;
  background: var(--grey-sur);
  border-radius: 100%;
  width: 18px;
  height: 18px;
  margin-right: 6px;
  position: relative;
}

._9PF-j .hHIr0 ._-8Nbr .BFocA div[buttonColor] {
  float: left;
  border-radius: 3px;
  width: 14px;
  height: 14px;
  margin-top: 1px;
  margin-right: 6px;
}

@media screen and (width <= 480px) {
  ._9PF-j .BFocA {
    width: calc(100% - 18px) !important;
    padding: 9px !important;
    font-size: 16px !important;
  }
}

.WM8X6 {
  border: 2px solid var(--grey-sur);
  cursor: pointer;
  border-radius: 100%;
  width: fit-content;
  padding: 3px;
  position: relative;
}

.WM8X6.Ylxmj .eudQO {
  filter: var(--icon-white);
  background: url("black-checked.c004d811.svg") 0 0 / cover;
}

.WM8X6.Ylxmj[unchecked] .eudQO {
  filter: var(--icon-grey-dark);
}

.WM8X6.Ylxmj[unchecked]:hover .eudQO {
  background: url("black-checked.c004d811.svg") 0 0 / cover;
}

.WM8X6.Ylxmj[checked] {
  background: var(--black);
}

.WM8X6 .eudQO {
  background: var(--white);
  border-radius: 100%;
  width: 8px;
  height: 8px;
}

.WM8X6[unchecked]:hover .eudQO {
  background: var(--grey-sur);
}

.WM8X6[checked] {
  background: var(--green);
  border-color: #0000;
}

.zOxDG ._8ZWTx {
  margin-top: 12px;
  margin-bottom: 8px;
  font-size: 14px;
}

.zOxDG .kXdyw {
  gap: 15px;
  display: flex;
}

.zOxDG .kXdyw .dcUax {
  width: 50%;
}

.zOxDG .dcUax {
  height: 40px;
  margin-bottom: 10px;
  position: relative;
}

.zOxDG .dcUax input {
  border: 1px solid var(--grey-sur) !important;
}

.zOxDG div[data-selectbox] {
  border-width: 1px !important;
}

.osy8p {
  width: 100%;
}

.osy8p .ER-w7 {
  width: 20px;
  height: 20px;
  margin-right: 6px;
}

.jNb2G {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
}

.jNb2G .LRXto {
  background: url("black-language.6bbeae85.svg") 0 0 / cover;
  width: 80%;
  height: 80%;
}

.uGsry .E-t4F {
  margin-right: 5px;
  top: 0 !important;
}

.E-t4F {
  width: 16px;
  height: 16px;
  display: inline-block;
  position: relative;
  top: 2.5px;
}

.POGiS {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
}

.POGiS .qmkTN {
  width: 100%;
  position: relative;
}

.POGiS .qmkTN ._3pXQU {
  margin-top: 50px;
  position: relative;
}

.POGiS .qmkTN ._1xGyO {
  text-align: center;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
  font-size: 50px;
  display: flex;
}

.POGiS .qmkTN .Oia-2 {
  text-align: center;
  width: 70%;
  color: var(--black);
  margin-bottom: 20px;
  font-family: bold, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 16px;
  position: relative;
  left: 15%;
}

.POGiS .qmkTN .Nf2mf {
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
  position: relative;
}

.POGiS .qmkTN .Nf2mf .OaL6X {
  background: var(--grey-sur);
  cursor: pointer;
  color: var(--white);
  background: var(--grey-sur);
  border: none;
  border-radius: 30px;
  width: fit-content;
  margin-top: 25px;
  padding: 10px 30px;
  font-family: bold, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 16px;
  transition: all .1s;
  position: relative;
  color: var(--black) !important;
}

.POGiS .qmkTN .Nf2mf .OaL6X:hover {
  background: var(--grey-sur-hover);
}

.POGiS .qmkTN .Nf2mf .OaL6X.sQqtO {
  justify-content: center;
  align-items: center;
  display: flex;
  color: #0000 !important;
}

.POGiS .qmkTN .Nf2mf .OaL6X svg {
  height: 60%;
}

.POGiS .qmkTN .Nf2mf .OaL6X svg circle {
  stroke-width: 5px;
}

.POGiS .qmkTN .Nf2mf .OaL6X span {
  background: var(--red);
  color: var(--white);
  border-radius: 15px;
  padding: 1px 6px;
  font-size: 14px;
}

.-eIPc {
  margin-top: 15px;
}

.-eIPc .Z6d2k {
  text-align: center;
  margin-bottom: 15px;
  font-size: 14px;
}

.-eIPc .yLDdq {
  justify-content: flex-end;
  gap: 10px;
  display: flex;
}

.-eIPc .yLDdq ._2bOXJ {
  border-radius: 35px;
  justify-content: center;
  align-items: center;
  width: calc(50% - 25px);
  height: 20px;
  padding: 10px;
  font-family: semi-bold, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 14px;
  display: flex;
  position: relative;
}

.-eIPc .yLDdq ._2bOXJ svg {
  width: 22px;
  height: 22px;
}

.-eIPc .yLDdq ._2bOXJ svg circle {
  stroke: var(--white);
}

.-eIPc .yLDdq ._2bOXJ.fXoRb {
  background: var(--grey-sur);
  cursor: pointer;
  color: var(--white);
  border: none;
  transition: all .1s;
  color: var(--black) !important;
}

.-eIPc .yLDdq ._2bOXJ.fXoRb:hover {
  background: var(--grey-sur-hover);
}

.-eIPc .yLDdq ._2bOXJ.qd6Jo {
  background: var(--green);
  color: var(--white);
  cursor: pointer;
  border: none;
  transition: all .1s;
  background: var(--black) !important;
}

.-eIPc .yLDdq ._2bOXJ.qd6Jo:hover {
  background: var(--green-flash-hover);
  opacity: .7;
}

._9XhK6 {
  border: 1px solid var(--grey-sur);
  border-radius: 10px;
  width: 100%;
}

._9XhK6 .XPv7g {
  display: flex;
}

._9XhK6 .XPv7g:last-child .riHOJ {
  border-bottom: unset;
}

._9XhK6 .XPv7g .riHOJ {
  border-right: 1px solid var(--grey-sur);
  border-bottom: 1px solid var(--grey-sur);
  flex-direction: column;
  justify-content: center;
  gap: 5px;
  width: 100%;
  padding: 15px;
  display: flex;
}

._9XhK6 .XPv7g .riHOJ:last-child {
  border-right: unset;
}

._9XhK6 .XPv7g .riHOJ:first-child {
  min-width: 370px;
}

._9XhK6 .XPv7g .riHOJ .MUJJR {
  font-family: semi-bold, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 15px;
}

._9XhK6 .XPv7g .riHOJ .uGTfi {
  color: var(--grey-text-very-dark);
  font-size: 13px;
}

._9XhK6 .XPv7g .riHOJ .CQK23 {
  gap: 8px;
  display: flex;
}

._9XhK6 .XPv7g .riHOJ .CQK23 .jwaPT {
  width: 24px;
  height: 24px;
}

._9XhK6 .XPv7g .riHOJ .CQK23 .jwaPT.zb4TS {
  background: url("colored-shopify.98dad4f7.svg") 0 0 / cover;
}

._9XhK6 .XPv7g .riHOJ .CQK23 .jwaPT.KYMQg {
  background: url("colored-webflow.207ee93c.svg") 0 0 / cover;
}

._9XhK6 .XPv7g .riHOJ .CQK23 .jwaPT.-s90n {
  background: url("colored-wordpress.20eb4098.svg") 0 0 / cover;
}

._9XhK6 .XPv7g .riHOJ .CQK23 .jwaPT.btfWH {
  background: url("black-wix.5db99bb2.svg") 0 0 / cover;
}

._9XhK6 .XPv7g .riHOJ .CQK23 .jwaPT.RJsSQ {
  background: url("colored-prestashop.f0a14972.svg") 0 0 / cover;
}

._9XhK6 .XPv7g .riHOJ .ASwW5 {
  word-break: break-word;
  font-size: 14px;
}

._9XhK6 .XPv7g .riHOJ .ASwW5 span {
  font-family: semi-bold, Segoe UI Emoji, Montserrat, sans-serif;
}

._9XhK6 .XPv7g .riHOJ .XIDxA {
  background: var(--black);
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  display: flex;
}

._9XhK6 .XPv7g .riHOJ .XIDxA .-qoDq {
  width: 10px;
  height: 10px;
  filter: var(--icon-white);
  background: url("black-checked.c004d811.svg") 0 0 / cover;
}

._9XhK6 .XPv7g .riHOJ .V-MC8 {
  background: var(--grey-dark);
  border-radius: 2px;
  width: 20px;
  height: 4px;
}

@media screen and (width <= 1100px) {
  ._9XhK6 .XPv7g .riHOJ:first-child {
    min-width: 200px !important;
  }
}

@media screen and (width <= 480px) {
  ._9XhK6 .XPv7g:first-child {
    display: block;
  }

  ._9XhK6 .XPv7g:not(:first-child) {
    display: none;
  }

  ._9XhK6 .XPv7g .riHOJ {
    width: unset;
    border-right: unset;
    margin-bottom: 25px;
  }

  ._9XhK6 .XPv7g .riHOJ:first-child {
    display: none;
  }
}

.BPLDf {
  flex-direction: column;
  gap: 12px;
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
}

.BPLDf.zIpIj .F3cWF {
  display: block;
}

.BPLDf.zIpIj .e-cZr {
  cursor: pointer;
  outline: unset;
  box-shadow: var(--black) 0 0 0 2px !important;
}

.BPLDf .F3cWF {
  width: 10px;
  height: 10px;
  filter: var(--icon-black);
  background: url("black-checked.c004d811.svg") 0 0 / cover;
  display: none;
  position: absolute;
  right: 0;
}

.BPLDf .Im-wb {
  background: var(--black);
  width: 80px;
  color: var(--white);
  text-align: center;
  border-radius: 25px;
  padding: 5px;
  font-size: 13px;
  position: absolute;
  top: -32px;
  left: calc(50% - 40px);
}

.BPLDf .b9DRx {
  font-family: semi-bold, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 14px;
}

.BPLDf ._4Civj {
  width: 30px;
  height: 30px;
  position: relative;
}

.BPLDf .SGE-o {
  font-family: bold, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 20px;
}

.BPLDf .SGE-o span {
  font-family: semi-bold, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 14px;
}

.BPLDf ._8Y2ih {
  font-size: 14px;
}

.BPLDf .e-cZr {
  color: var(--white);
  background: var(--black);
  cursor: pointer;
  border: 2px solid var(--white);
  border-radius: 30px;
  margin-top: auto;
  padding: 10px 12px;
  font-family: semi-bold, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 14px;
}

.BPLDf .e-cZr:hover {
  opacity: .7;
}

.BPLDf .xnqsg {
  flex-direction: column;
  gap: 12px;
  display: none;
}

.BPLDf .xnqsg .MJAmK {
  border-bottom: 1px solid var(--grey-sur);
  padding-bottom: 7px;
}

.BPLDf .xnqsg .MJAmK .wL7iu {
  align-items: center;
  gap: 8px;
  font-family: semi-bold, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 15px;
  display: flex;
}

.BPLDf .xnqsg .MJAmK .wL7iu ._5X3ec {
  background: var(--black);
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  display: flex;
}

.BPLDf .xnqsg .MJAmK .wL7iu ._5X3ec ._4Civj {
  width: 10px;
  height: 10px;
  filter: var(--icon-white);
  background: url("black-checked.c004d811.svg") 0 0 / cover;
}

.BPLDf .xnqsg .MJAmK .wL7iu .uSbdC {
  background: var(--grey-dark);
  border-radius: 2px;
  width: 20px;
  height: 4px;
}

.BPLDf .xnqsg .MJAmK ._8Y2ih {
  color: var(--grey-text-very-dark);
  font-size: 13px;
}

.BPLDf .xnqsg .MJAmK .DfTVs {
  gap: 8px;
  margin-top: 5px;
  margin-left: 26px;
  display: flex;
}

.BPLDf .xnqsg .MJAmK .DfTVs .v7kgz {
  width: 20px;
  height: 20px;
}

.BPLDf .xnqsg .MJAmK .DfTVs .v7kgz.Pdld2 {
  background: url("colored-shopify.98dad4f7.svg") 0 0 / cover;
}

.BPLDf .xnqsg .MJAmK .DfTVs .v7kgz.lCju1 {
  background: url("colored-wordpress.20eb4098.svg") 0 0 / cover;
}

.BPLDf .xnqsg .MJAmK .DfTVs .v7kgz.X-IRC {
  background: url("black-wix.5db99bb2.svg") 0 0 / cover;
}

.BPLDf .xnqsg .MJAmK .DfTVs .v7kgz.pcQvc {
  background: url("colored-prestashop.f0a14972.svg") 0 0 / cover;
}

@media screen and (width <= 480px) {
  .BPLDf .xnqsg {
    display: flex;
  }

  .BPLDf .e-cZr {
    width: fit-content;
  }
}

.kVHSJ {
  border: 1px solid var(--grey-alpha);
  cursor: pointer;
  border-radius: 50%;
  margin-left: 5px;
  font-size: 75%;
  line-height: 0;
  display: inline-block;
  position: relative;
  top: -1px;
  padding: unset !important;
}

.kVHSJ:hover {
  background: var(--grey-alpha);
  border: 1px solid #0000;
}

.kVHSJ span {
  margin-left: 6px;
  margin-right: 6px;
  display: inline-block;
  padding: unset !important;
  padding-top: 50% !important;
  padding-bottom: 50% !important;
}

.kqQNp {
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
}

.kqQNp ._4sSGO {
  border-bottom: 1px solid var(--white-sur);
  z-index: 2;
  background: var(--black);
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 40px;
  display: flex;
  position: relative;
}

.kqQNp .FiFOA {
  width: calc(100% - 40px);
  max-width: 1150px;
  padding: 0;
}

.kqQNp .FiFOA .TmGHb {
  flex-direction: column;
  align-items: center;
  gap: 50px;
  display: flex;
}

.kqQNp .FiFOA .TmGHb .i-x9E {
  width: 100%;
}

.kqQNp .N27Hn {
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 70px 0 0;
  display: flex;
}

.TLoVY {
  align-items: center;
  width: calc(100% - 40px);
  max-width: 1150px;
  height: 70px;
  display: flex;
  position: relative;
}

.TLoVY.uqQxU {
  position: fixed;
  top: 0;
}

.TLoVY.wSkkj, .TLoVY._2xlwj {
  visibility: visible;
}

.TLoVY .Sy5qQ {
  z-index: 10;
  cursor: pointer;
  border-radius: 18px;
  flex-direction: row;
  align-items: center;
  gap: 10.45px;
  height: 38px;
  margin-left: -8px;
  padding: 0 8px;
  display: flex;
}

.TLoVY .Sy5qQ:hover {
  background: var(--white-sur);
}

.TLoVY .Sy5qQ .aOE6F {
  width: 28.5px;
  height: 28.5px;
}

.TLoVY .Sy5qQ .aOE6F .Uwz-A {
  width: 100%;
  height: 100%;
}

.TLoVY .Sy5qQ .rjiEX {
  margin-top: 1px;
  font-family: semi-bold, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 21px;
}

.TLoVY .Nqs87 {
  flex-direction: row;
  gap: 20px;
  width: 100%;
  margin-left: 10%;
  display: flex;
}

.TLoVY .Nqs87 .klTSr {
  cursor: pointer;
  color: var(--white);
  background: none;
  border-radius: 26px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  height: 38px;
  padding: 0 14px;
  font-family: semi-bold, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 15px;
  display: flex;
  position: relative;
}

.TLoVY .Nqs87 .klTSr.Wo25U, .TLoVY .Nqs87 .klTSr .inlHz, .TLoVY .Nqs87 .klTSr .J5FLr {
  visibility: visible;
}

.TLoVY .Nqs87 .klTSr:hover {
  background: var(--white-sur);
}

.TLoVY .Nqs87 .klTSr.pnoj8 {
  color: var(--white);
  white-space: nowrap;
  border-radius: 25px;
  height: fit-content;
  margin-left: auto;
  padding: 12px;
  position: relative;
}

.TLoVY .Nqs87 .klTSr.pnoj8.qMXh2 {
  border-top: 2px solid var(--web-border-blue);
  background: var(--web-grad-blue);
  color: var(--white);
  border-radius: 12px;
  height: fit-content;
  margin-left: 15px;
  padding: 10px 16px;
}

.TLoVY .Nqs87 .klTSr .K6Unu {
  width: 14px;
  height: 14px;
  filter: var(--icon-white);
  background: url("black-bottom.ee71d8f3.svg") 0 0 / cover;
  margin-top: 2px;
}

.TLoVY .Nqs87 .klTSr .DjTdk {
  z-index: 9;
  background: var(--white);
  border-radius: 15px;
  width: 620px;
  padding: 10px;
  display: none;
  position: absolute;
  top: 62px;
  left: 0;
  box-shadow: 0 15px 3.75rem #00000047;
}

.TLoVY .Nqs87 .klTSr .DjTdk .rKzrE {
  width: 100%;
  height: 50px;
  position: absolute;
  top: -25px;
  left: 0;
}

.TLoVY .qMxK1 {
  display: none;
}

.TLoVY .qMxK1 .s32-s {
  z-index: 4;
  cursor: pointer;
  background: var(--web-white-alpha);
  border-radius: 14px;
  justify-content: center;
  align-items: center;
  min-width: 42px;
  height: 42px;
  margin-left: auto;
  padding: 0 20px;
  font-family: semi-bold, Segoe UI Emoji, Montserrat, sans-serif;
  display: flex;
}

.TLoVY .qMxK1 .s32-s:hover {
  background: var(--grey-sur);
}

.TLoVY .qMxK1 .s32-s._64JCz .K6Unu {
  background: url("black-close-bold.27fa5f62.svg") 0 0 / cover;
  width: 16px;
  height: 16px;
}

.TLoVY .qMxK1 .s32-s.mY5-A {
  padding: 0;
}

.TLoVY .qMxK1 .s32-s .K6Unu {
  width: 20px;
  height: 20px;
  filter: var(--icon-white);
  background: url("black-mobile-menu.a8de092f.svg") 0 0 / cover;
}

.TLoVY .g-zA1 {
  z-index: 1;
  background: var(--white);
  border-top: 72px solid var(--black);
  border-radius: 0 0 14px 14px;
  width: calc(100% - 12px);
  height: calc(100% - 140px);
  max-height: 0%;
  padding: 6px;
  transition: all .5s;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  overflow: auto;
  box-shadow: 0 15px 3.75rem #000000a6;
}

@media (width <= 930px) {
  .TLoVY {
    justify-content: space-between;
  }

  .TLoVY .Sy5qQ {
    gap: 15px;
  }

  .TLoVY .Sy5qQ .aOE6F {
    width: 30px;
    height: 30px;
  }

  .TLoVY .Sy5qQ .rjiEX {
    margin-top: 3px;
    font-size: 20px;
  }

  .TLoVY .Nqs87, .TLoVY .itSsE {
    display: none;
  }

  .TLoVY .qMxK1 {
    display: flex;
  }
}

.-Vlt9 {
  flex-direction: row;
  gap: 30px;
  display: flex;
}

.-Vlt9 .dDQun {
  flex-direction: column;
  gap: 15px;
  margin-top: 15px;
  display: flex;
}

.-Vlt9 .dDQun ._0yX-y {
  background: var(--web-grad-blue);
  color: var(--white);
  cursor: pointer;
  border-radius: 14px;
  justify-content: center;
  align-items: center;
  height: fit-content;
  padding: 20px 32px;
  font-family: semi-bold, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 16px;
  display: flex;
}

.-Vlt9 .dDQun ._0yX-y.a-IcG {
  background: var(--grey-sur);
  color: #000;
}

.-Vlt9 .dDQun ._0yX-y.a-IcG:hover {
  background: var(--grey-sur-hover);
}

.-Vlt9 .dDQun ._0yX-y:hover {
  background: var(--web-blue-hover);
}

.-Vlt9 .dDQun ._0yX-y:hover .ayL-M {
  -ms-transform: translateX(8px);
  transform: translateX(8px);
}

.-Vlt9 .dDQun ._0yX-y .vMrem {
  align-items: center;
  gap: 15px;
  display: flex;
  position: relative;
}

.-Vlt9 .dDQun ._0yX-y .vMrem .ayL-M {
  width: 16px;
  height: 16px;
  filter: var(--icon-white);
  background: url("black-right-arrow.b93de0b4.svg") 0 0 / cover;
  transition: -ms-transform .1s ease-in-out, transform .1s ease-in-out;
}

.-Vlt9 .LGn2t {
  width: 50%;
}

.-Vlt9 .LGn2t._03B5h .v-2x3 {
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.-Vlt9 .LGn2t .qZxXS {
  text-align: left;
  color: var(--grey-text-dark);
  font-size: 14px;
  font-weight: unset;
  text-transform: uppercase;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 0 12px !important;
}

.-Vlt9 .LGn2t .qZxXS .v-2x3 {
  background: url("black-right-bold.59a22474.svg") 0 0 / cover;
  width: 16px;
  height: 16px;
  margin-left: auto;
  transition: all .1s;
  display: none;
}

.-Vlt9 .LGn2t .duq5w {
  flex-direction: column;
  gap: 8px;
  display: flex;
}

.-Vlt9 .LGn2t .duq5w .etszA {
  cursor: pointer;
  border-radius: 20px;
  flex-direction: row;
  gap: 20px;
  padding: 12px;
  display: flex;
}

.-Vlt9 .LGn2t .duq5w .etszA.Pt3yQ, .-Vlt9 .LGn2t .duq5w .etszA ._8G-OD, .-Vlt9 .LGn2t .duq5w .etszA .Zmwiz, .-Vlt9 .LGn2t .duq5w .etszA .VnbOJ, .-Vlt9 .LGn2t .duq5w .etszA .mdOXG, .-Vlt9 .LGn2t .duq5w .etszA .VjRw1, .-Vlt9 .LGn2t .duq5w .etszA .UhgYq {
  visibility: visible;
}

.-Vlt9 .LGn2t .duq5w .etszA:hover {
  background: var(--grey-sur);
}

.-Vlt9 .LGn2t .duq5w .etszA .A-gTE {
  justify-content: center;
  align-items: center;
  width: 42px;
  min-width: 42px;
  height: 42px;
  margin-top: 4px;
  display: flex;
}

.-Vlt9 .LGn2t .duq5w .etszA .A-gTE.q9ze4 {
  background: var(--grey-sur);
  border-radius: 100%;
}

.-Vlt9 .LGn2t .duq5w .etszA .A-gTE .ayL-M {
  width: 24px;
  height: 24px;
}

.-Vlt9 .LGn2t .duq5w .etszA .A-gTE .ayL-M.VawEX {
  background: url("black-lineal-ai.a4da612c.svg") 0 0 / cover;
}

.-Vlt9 .LGn2t .duq5w .etszA .A-gTE .ayL-M.ZPA1J {
  background: url("black-lineal-target.456c15f1.svg") 0 0 / cover;
}

.-Vlt9 .LGn2t .duq5w .etszA .A-gTE .ayL-M.VnbOJ {
  background: url("black-chart.f26b7454.svg") 0 0 / cover;
}

.-Vlt9 .LGn2t .duq5w .etszA .A-gTE .ayL-M.OzNbr {
  background: url("black-ai-stars.5ac6cf8b.svg") 0 0 / cover;
}

.-Vlt9 .LGn2t .duq5w .etszA .A-gTE .ayL-M._9PHzi {
  background: url("black-wix.5db99bb2.svg") 0 0 / cover;
}

.-Vlt9 .LGn2t .duq5w .etszA .A-gTE .ayL-M.J1akp {
  background: url("colored-prestashop.f0a14972.svg") 0 0 / cover;
}

.-Vlt9 .LGn2t .duq5w .etszA .A-gTE .ayL-M.Vpy-G {
  background: url("colored-wordpress.20eb4098.svg") 0 0 / cover;
}

.-Vlt9 .LGn2t .duq5w .etszA .A-gTE .ayL-M.DNoVU {
  background: url("colored-shopify.98dad4f7.svg") 0 0 / cover;
}

.-Vlt9 .LGn2t .duq5w .etszA .N5Eu3 {
  text-align: left;
  visibility: visible;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
  display: flex;
}

.-Vlt9 .LGn2t .duq5w .etszA .N5Eu3 .y4A12 {
  color: var(--black);
  font-family: semi-bold, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 17px;
}

.-Vlt9 .LGn2t .duq5w .etszA .N5Eu3 ._7ztef {
  color: var(--black);
  font-family: medium, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 13px;
}

.-Vlt9 .LGn2t .duq5w .etszA .N5Eu3 ._7ztef strong {
  font-weight: unset;
}

@media screen and (width <= 930px) {
  .-Vlt9 {
    padding: 0;
    display: block;
  }

  .-Vlt9 .LGn2t {
    width: 100%;
  }

  .-Vlt9 .LGn2t .qZxXS {
    color: var(--black);
    border-bottom: 2px dashed var(--grey-sur);
    height: 40px;
    margin-bottom: unset;
    margin-top: unset;
    cursor: pointer;
    align-items: center;
    font-size: 18px;
    display: flex;
  }

  .-Vlt9 .LGn2t .qZxXS:hover {
    background: var(--grey-sur);
  }

  .-Vlt9 .LGn2t .qZxXS .v-2x3 {
    display: block;
  }

  .-Vlt9 .LGn2t .duq5w {
    margin-top: 5px;
    display: none;
  }
}

.sWQBN {
  width: calc(100% - 40px);
  max-width: 1150px;
  padding: 0 20px;
}

.sWQBN .n2TOE ._6indg {
  z-index: 10;
  cursor: pointer;
  border-radius: 18px;
  flex-direction: row;
  align-items: center;
  gap: 10.45px;
  width: fit-content;
  height: 38px;
  margin-left: -8px;
  padding: 0 8px;
  display: flex;
}

.sWQBN .n2TOE ._6indg:hover {
  background: var(--white-sur);
}

.sWQBN .n2TOE ._6indg .QzMt4 {
  width: 28.5px;
  height: 28.5px;
}

.sWQBN .n2TOE ._6indg .QzMt4 ._3vKT0 {
  width: 100%;
  height: 100%;
}

.sWQBN .n2TOE ._6indg .wCw69 {
  margin-top: 1px;
  font-family: semi-bold, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 21px;
}

.sWQBN .KAdqr {
  flex-flow: wrap;
  justify-content: space-between;
  gap: 25px;
  margin-top: 40px;
  display: flex;
}

.sWQBN .KAdqr .eIrgo {
  width: fit-content;
}

.sWQBN .KAdqr .eIrgo nav:nth-of-type(2) {
  margin-top: 25px;
}

.sWQBN .KAdqr .eIrgo .wCw69 {
  font-family: semi-bold, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 26px;
}

.sWQBN .KAdqr .eIrgo .wCw69 .kyTyE {
  flex-direction: column;
  gap: 5px;
  margin-top: 15px;
  padding: 0;
  display: flex;
}

.sWQBN .KAdqr .eIrgo .wCw69 .kyTyE .XePa5 {
  cursor: pointer;
  font-size: 16px;
}

.sWQBN .KAdqr .eIrgo .wCw69 .kyTyE .XePa5:hover {
  text-decoration: underline;
}

.sWQBN .KAdqr .eIrgo .wCw69 .VPgA6 {
  width: 130px;
  height: 40px;
  margin-top: 10px;
  position: relative;
}

.sWQBN .KAdqr .eIrgo .wCw69 .VPgA6 div[data-selectbox] {
  background: var(--white-sur);
  color: #fff;
  height: 36px;
  border-color: #0000 !important;
}

.sWQBN .KAdqr .eIrgo .wCw69 .VPgA6 div[data-selectbox]:hover {
  background: var(--white-sur-hover);
}

.sWQBN .KAdqr .eIrgo .wCw69 .VPgA6 div[data-selectbox] div[data-selectIcon] {
  filter: var(--icon-white);
}

.sWQBN .Vy4h7 {
  margin-top: 50px;
  padding-bottom: 40px;
  font-family: medium, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 14px;
}

@media (width <= 480px) {
  .sWQBN .KAdqr {
    gap: 25px 105px;
  }

  .sWQBN .KAdqr .eIrgo .wCw69 {
    font-size: 25px;
  }

  .sWQBN .KAdqr .eIrgo .wCw69 .kyTyE {
    gap: 12px;
  }

  .sWQBN .Vy4h7 {
    text-align: center;
  }
}

.PrJU2 {
  width: 100%;
}

.PrJU2 .g8H2t {
  width: 20px;
  height: 20px;
  margin-right: 6px;
}

.ncrla {
  border: 1px solid var(--web-border-white);
  background: var(--web-background-grid-blue);
  border-radius: 22px;
  flex-direction: row;
  gap: 25px;
  width: calc(100% - 50px);
  margin-top: 33px;
  padding: 25px;
  display: flex;
  position: relative;
}

.ncrla .KxwNl {
  z-index: 1;
  flex-direction: column;
  gap: 25px;
  display: flex;
  position: relative;
}

.ncrla .KxwNl .Av5iz {
  align-items: center;
  gap: 10px;
  font-family: semi-bold, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 28px;
  display: flex;
}

.ncrla .KxwNl .SP8FW {
  max-width: 500px;
  font-size: 15px;
}

.ncrla .KxwNl .GQy6k {
  border-top: 2px solid var(--web-border-blue);
  background: var(--web-grad-blue);
  color: var(--white);
  cursor: pointer;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  width: fit-content;
  height: fit-content;
  margin-top: 30px;
  padding: 20px 32px;
  font-size: 16px;
  display: flex;
}

.ncrla .KxwNl .GQy6k:hover {
  background: var(--web-blue-hover);
}

.ncrla .KxwNl .GQy6k:hover .OXijq {
  -ms-transform: translateX(8px);
  transform: translateX(8px);
}

.ncrla .KxwNl .GQy6k .xDwRm {
  background: var(--web-blue);
  filter: blur(100px);
  pointer-events: none;
  width: 168px;
  height: 168px;
  position: absolute;
}

.ncrla .KxwNl .GQy6k .Qq00- {
  align-items: center;
  gap: 15px;
  display: flex;
  position: relative;
}

.ncrla .KxwNl .GQy6k .Qq00- .OXijq {
  width: 16px;
  height: 16px;
  filter: var(--icon-white);
  background: url("black-right-arrow.b93de0b4.svg") 0 0 / cover;
  transition: -ms-transform .1s ease-in-out, transform .1s ease-in-out;
}

.ncrla .qoRcZ {
  z-index: 1;
  flex: 0 0 280px;
  justify-content: center;
  align-items: center;
  width: 280px;
  height: 100%;
  margin-left: auto;
  display: flex;
}

.ncrla .qoRcZ .OXijq {
  background: url("illus-black-search-engine.d7247a2c.svg") 0 0 / cover;
  width: 265px;
  height: 265px;
}

.ncrla .dxpy- {
  background: var(--web-blue);
  filter: blur(85px);
  width: 84px;
  height: 84px;
  position: absolute;
  bottom: 0;
  right: 0;
}

@media (width <= 930px) {
  .ncrla .KxwNl {
    z-index: 1;
    position: relative;
  }

  .ncrla .qoRcZ {
    z-index: 0;
    position: absolute;
    right: -50%;
  }
}

._1xZ4z {
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
}

._1xZ4z ._0u-sQ {
  z-index: 1;
  background: #fff;
  border-radius: 17px;
  justify-content: center;
  width: 100%;
  margin-top: 70px;
  margin-bottom: 70px;
  padding: 40px 0;
  display: flex;
}

._1xZ4z .M6c0H {
  flex-direction: column;
  align-items: center;
  gap: 70px;
  width: calc(100% - 40px);
  max-width: 1150px;
  display: flex;
}

._1xZ4z .M6c0H .OKAGF {
  width: 100%;
}

@media (width <= 750px) {
  ._1xZ4z .M6c0H {
    gap: 60px;
  }
}

.Toz-F .-gDaK {
  background: url("colored-separation.6bda42cc.svg") 0 0 / cover;
  width: 158px;
  height: 40px;
}

.Toz-F .k0Z8m {
  flex-direction: column;
  align-items: center;
  gap: 85px;
  display: flex;
}

.Toz-F .k0Z8m ._5OUdl {
  width: 100%;
}

.A6P3b {
  grid-template-rows: repeat(5, 1fr);
  grid-template-columns: repeat(10, 1fr);
  width: 100%;
  height: fit-content;
  display: grid;
}

.A6P3b ._2lx1n {
  grid-area: 1 / 1 / 2 / 3;
}

.A6P3b .aHiz1 {
  grid-area: 1 / 3 / end / end;
  position: relative;
}

.A6P3b .wxWNP {
  min-width: 350px;
  box-shadow: 0 0 32.85px var(--black-alpha-middle);
  border: 1px solid var(--web-border-white);
  backdrop-filter: blur(30px);
  background: linear-gradient(0deg, #00001e, #000c);
  border-radius: 40px;
  grid-area: 2 / 1 / 6 / 6;
  padding: 38px 52px;
  position: relative;
}

.A6P3b .wxWNP .Lnmyj {
  font-family: bold, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 42px;
}

.A6P3b .wxWNP .Tz7ie {
  background: var(--grad-green);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.A6P3b .wxWNP .k-pem {
  background: var(--grad-purple);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.A6P3b .wxWNP .eHNQ9 {
  background: var(--grad-blue-button);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.A6P3b .wxWNP .Fm-kD, .A6P3b .wxWNP .LdonG {
  background: var(--grad-black);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.A6P3b .wxWNP .DM1Jo {
  margin-top: 20px;
  font-family: semi-bold, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 18px;
}

.A6P3b .wxWNP .nvn8h {
  background: var(--white);
  width: 120px;
  height: 120px;
  box-shadow: 0 0 32.85px var(--black-alpha-middle);
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: -60px;
  right: -60px;
}

.A6P3b .wxWNP .nvn8h.wH2ys .xJC3p {
  background: url("colored-prestashop.f0a14972.svg") 0 0 / cover;
}

.A6P3b .wxWNP .nvn8h.qODv7 .xJC3p {
  background: url("black-wix.5db99bb2.svg") 0 0 / cover;
}

.A6P3b .wxWNP .nvn8h.h3WXc .xJC3p {
  background: url("colored-wordpress.20eb4098.svg") 0 0 / cover;
}

.A6P3b .wxWNP .nvn8h.MK1oF .xJC3p {
  background: url("colored-shopify.98dad4f7.svg") 0 0 / cover;
}

.A6P3b .wxWNP .nvn8h .xJC3p {
  width: 70px;
  height: 70px;
}

.A6P3b .wxWNP .hLzZO {
  background: var(--grey-sur);
  border-radius: 12px;
  margin-top: 15px;
  padding: 12px;
  font-size: 14px;
}

.A6P3b .wxWNP ._529Da {
  border-top: 2px solid var(--web-border-blue);
  background: var(--web-grad-blue);
  width: fit-content;
  color: var(--white);
  cursor: pointer;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  height: fit-content;
  margin-top: 30px;
  padding: 20px 32px;
  font-size: 16px;
  display: flex;
}

.A6P3b .wxWNP ._529Da:hover {
  background: var(--web-blue-hover);
}

.A6P3b .wxWNP ._529Da:hover .xJC3p {
  -ms-transform: translateX(8px);
  transform: translateX(8px);
}

.A6P3b .wxWNP ._529Da ._856Tg {
  background: var(--web-blue);
  filter: blur(100px);
  pointer-events: none;
  width: 168px;
  height: 168px;
  position: absolute;
}

.A6P3b .wxWNP ._529Da .Xqh-B {
  align-items: center;
  gap: 15px;
  display: flex;
  position: relative;
}

.A6P3b .wxWNP ._529Da .Xqh-B .xJC3p {
  width: 16px;
  height: 16px;
  filter: var(--icon-white);
  background: url("black-right-arrow.b93de0b4.svg") 0 0 / cover;
  transition: -ms-transform .1s ease-in-out, transform .1s ease-in-out;
}

.A6P3b .wxWNP ._529Da .Xqh-B .xJC3p.MK1oF {
  background: url("colored-shopify.98dad4f7.svg") 0 0 / cover;
  filter: unset !important;
}

.A6P3b .wxWNP ._529Da .Xqh-B .xJC3p.wH2ys {
  background: url("colored-prestashop.f0a14972.svg") 0 0 / cover;
  filter: unset !important;
}

.A6P3b .wxWNP ._529Da .Xqh-B .xJC3p.qODv7 {
  background: url("black-wix.5db99bb2.svg") 0 0 / cover;
}

.A6P3b .wxWNP ._529Da .Xqh-B .xJC3p.h3WXc {
  background: url("black-lineal-wordpress.33140bac.svg") 0 0 / cover;
}

@media (width <= 1050px) {
  .A6P3b {
    grid-template-rows: repeat(4, 1fr);
  }

  .A6P3b .wxWNP .Lnmyj {
    font-size: 40px;
  }

  .A6P3b .wxWNP .DM1Jo {
    margin-top: 30px;
  }
}

@media (width <= 750px) {
  .A6P3b ._2lx1n {
    grid-column-end: 4;
  }

  .A6P3b .aHiz1 {
    grid-column-start: 4;
  }

  .A6P3b .wxWNP {
    min-width: 300px;
    padding: 25px 40px 50px;
  }

  .A6P3b .wxWNP .Lnmyj {
    font-size: 35px;
  }

  .A6P3b .wxWNP .DM1Jo {
    margin-top: 20px;
    font-size: 16px;
  }
}

@media (width <= 480px) {
  .A6P3b {
    flex-direction: column;
    display: flex;
  }

  .A6P3b ._2lx1n {
    order: 1;
  }

  .A6P3b .aHiz1 {
    order: 3;
    margin-top: 30px;
  }

  .A6P3b .wxWNP {
    border: unset;
    min-width: 0;
    box-shadow: none;
    background: none;
    order: 2;
    margin-top: 20px;
    padding: 0;
  }

  .A6P3b .wxWNP .Lnmyj {
    font-size: 33px;
  }

  .A6P3b .wxWNP .DM1Jo {
    margin-top: 20px;
    font-size: 18px;
  }

  .A6P3b .wxWNP .nvn8h {
    width: 56px;
    height: 56px;
    right: 0;
  }

  .A6P3b .wxWNP .nvn8h .xJC3p {
    width: 24px;
    height: 24px;
  }
}

.chBpD {
  flex-direction: row;
  gap: 15.6px;
  display: flex;
}

.chBpD .bgk4Z {
  border-radius: 50%;
  width: 36px;
  height: 36px;
}

.chBpD .iDRe2 {
  margin-top: 3px;
  font-family: bold, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 24px;
}

.ugMaD {
  object-fit: contain;
  border-radius: 15px;
  width: 100%;
  height: auto;
  top: 0;
}

.ugMaD.arVTJ {
  box-shadow: 0 0 24.53px var(--black-alpha-middle);
}

.ugMaD.VUubG {
  box-shadow: 0 4.24 52.97px var(--black-alpha-middle);
}

.ugMaD.HUKHM {
  box-shadow: 0 0 73.91px var(--black-alpha-middle);
}

._0Z1Fo {
  border-radius: 19.55px;
  flex-direction: row;
  gap: 100px;
  width: 100%;
  height: 100%;
  padding: 70px 0;
  display: flex;
}

._0Z1Fo .G-U-e {
  height: 100%;
}

._0Z1Fo .G-U-e.s64OX {
  width: 50%;
}

._0Z1Fo .G-U-e.Fgvjw {
  width: 60%;
}

._0Z1Fo .G-U-e ._8OJXc {
  width: 100%;
  height: 100%;
}

._0Z1Fo .G-U-e ._8OJXc .JdsXP {
  background: var(--grad-black);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  font-family: bold, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 44px;
}

._0Z1Fo .G-U-e ._8OJXc .WNZNH {
  margin-top: 12px;
  font-family: semi-bold, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 18px;
}

._0Z1Fo .G-U-e ._8OJXc .CcbG- {
  color: var(--black);
  background: var(--grey-sur);
  cursor: pointer;
  border-radius: 34px;
  flex-direction: row;
  width: fit-content;
  margin-top: 20px;
  padding: 19px 21px;
  font-family: semi-bold, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 18px;
  display: flex;
}

._0Z1Fo .G-U-e ._8OJXc .CcbG- .QvmBK {
  background: url("black-right-bold.59a22474.svg") 0 0 / cover;
  width: 13px;
  height: 13px;
  margin: 5px 0 0 10px;
}

._0Z1Fo .vZHsd.s64OX {
  width: 50%;
}

._0Z1Fo .vZHsd.Fgvjw {
  width: 40%;
}

._0Z1Fo .vZHsd ._9LfWb {
  width: 100%;
  height: 100%;
}

._0Z1Fo .vZHsd ._9LfWb .-rK3F {
  width: 100%;
  height: 100%;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: contain !important;
}

._0Z1Fo .vZHsd ._9LfWb .-rK3F.lIcF7 {
  background: url("illus-mails-advanced.ab5d88b7.svg") 0 0 / cover;
}

._0Z1Fo .vZHsd ._9LfWb .-rK3F.wWDjF {
  background: url("illus-seo.7c3d1e42.svg") 0 0 / cover;
}

._0Z1Fo .vZHsd ._9LfWb .-rK3F.D0OHZ {
  background: url("illus-perms.dc0349ba.png") 0 0 / cover;
}

._0Z1Fo .vZHsd ._9LfWb .-rK3F.dALtA {
  background: url("illus-funnel.bc2df210.svg") 0 0 / cover;
}

@media (width <= 1050px) {
  ._0Z1Fo .G-U-e ._8OJXc .JdsXP {
    font-size: 38px;
  }

  ._0Z1Fo .G-U-e ._8OJXc .WNZNH {
    font-size: 16px;
  }

  ._0Z1Fo .G-U-e ._8OJXc .CcbG- {
    padding: 17px 19px;
    font-size: 16px;
  }

  ._0Z1Fo .G-U-e ._8OJXc .CcbG- .QvmBK {
    margin: 3px 0 0 10px;
  }

  ._0Z1Fo .G-U-e ._8OJXc.dALtA {
    background: url("illus-funnel.bc2df210.svg") 0 0 / cover;
  }
}

@media (width <= 750px) {
  ._0Z1Fo {
    gap: 50px;
  }

  ._0Z1Fo .G-U-e ._8OJXc .JdsXP {
    font-size: 30px;
  }

  ._0Z1Fo .G-U-e ._8OJXc .WNZNH {
    font-size: 16px;
  }

  ._0Z1Fo .G-U-e ._8OJXc .CcbG- {
    padding: 17px 19px;
    font-size: 16px;
  }

  ._0Z1Fo .G-U-e ._8OJXc .CcbG- .QvmBK {
    margin: 3px 0 0 10px;
  }
}

@media (width <= 480px) {
  ._0Z1Fo {
    flex-direction: column;
    gap: 0;
    padding: 0;
  }

  ._0Z1Fo .G-U-e {
    width: 100% !important;
  }

  ._0Z1Fo .G-U-e ._8OJXc .JdsXP {
    font-size: 25px;
  }

  ._0Z1Fo .vZHsd {
    height: 190px;
    margin-top: 20px;
    width: 100% !important;
  }

  ._0Z1Fo .vZHsd ._9LfWb .-rK3F {
    height: 100%;
    margin: auto;
  }
}

.ygavk .r4yEW {
  text-align: center;
  width: 100%;
}

.ygavk .r4yEW .wUGOp {
  padding: 0 15%;
  font-family: bold, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 44px;
}

.ygavk .r4yEW .wUGOp.sGXHA {
  background: var(--grad-blue);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.ygavk .r4yEW .wUGOp.B3jpP {
  background: var(--grad-red);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.ygavk .r4yEW .wUGOp.G-DXY {
  background: var(--grad-black);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.ygavk .r4yEW .wUGOp._60-BV {
  background: var(--grad-multi);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.ygavk .r4yEW .wUGOp.gxllE {
  background: var(--grad-purple);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.ygavk .r4yEW .wUGOp.zsO-A {
  background: var(--grad-green);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.ygavk .r4yEW .wUGOp.YjMYk {
  color: var(--white);
}

.ygavk .r4yEW .UIyoX {
  margin-top: 30px;
  padding: 0 15%;
  font-family: semi-bold, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 18px;
}

.ygavk .t9VEZ {
  justify-content: center;
  margin-top: 50px;
  display: flex;
}

.ygavk .t9VEZ .VdJMY {
  visibility: visible;
}

.ygavk .t9VEZ .VdJMY.b-gKn {
  width: 100%;
  height: 100%;
}

.ygavk .t9VEZ .VdJMY._3uO1H {
  width: 60%;
  height: 100%;
}

.ygavk .t9VEZ .VdJMY._37I23 {
  width: 80%;
  height: 100%;
}

.ygavk .t9VEZ .VdJMY.a-Cfj {
  box-shadow: 0 0 35px var(--black-alpha-middle);
}

.ygavk .t9VEZ .VdJMY.yOgS2 {
  border-radius: 20px;
}

@media (width <= 750px) {
  .ygavk .r4yEW {
    text-align: center;
  }

  .ygavk .r4yEW .wUGOp {
    padding: 0;
    font-size: 30px;
  }

  .ygavk .r4yEW .UIyoX {
    margin-top: 24px;
    padding: 0;
    font-size: 18px;
  }
}

@media (width <= 480px) {
  .ygavk .r4yEW .wUGOp {
    font-size: 25px;
  }

  .ygavk .r4yEW .UIyoX {
    margin-top: 24px;
    padding: 0;
    font-size: 16px;
  }

  .ygavk .t9VEZ {
    margin-top: 25px;
  }

  .ygavk .t9VEZ .VdJMY {
    width: 100% !important;
    height: 100% !important;
  }
}

.zLXxD {
  flex-direction: column;
  align-items: center;
  width: 100%;
  display: flex;
  position: relative;
}

.zLXxD .pY2EZ {
  text-align: center;
  background: var(--grad-blue);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  width: 70%;
  font-family: bold, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 44px;
}

.zLXxD .iEBgs {
  flex-direction: row;
  gap: 43px;
  width: 100%;
  margin-top: 50px;
  display: flex;
  position: relative;
}

.zLXxD .iEBgs .YigGP {
  border: 1px solid var(--grey-sur);
  border-radius: 33px;
  flex-direction: column;
  align-items: center;
  width: 33.33%;
  padding: 24px 40px;
  display: flex;
  position: relative;
}

.zLXxD .iEBgs .YigGP .h9h5Y {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
}

.zLXxD .iEBgs .YigGP .h9h5Y img {
  width: 100%;
  box-shadow: 0 2.16px 10.82px var(--black-alpha-middle);
  border-radius: 12.5px;
}

.zLXxD .iEBgs .YigGP .h9h5Y img.oPrII {
  width: 45%;
  box-shadow: none;
  border-radius: 0;
}

.zLXxD .iEBgs .YigGP .ES7VN {
  text-align: center;
  background: var(--grad-black);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  margin-bottom: 40px;
  font-family: semi-bold, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 20px;
}

@media (width <= 750px) {
  .zLXxD {
    text-align: center;
  }

  .zLXxD .pY2EZ {
    text-align: center;
    width: 100%;
    margin-left: 0;
    font-family: bold, Segoe UI Emoji, Montserrat, sans-serif;
    font-size: 25px;
  }

  .zLXxD .iEBgs {
    flex-direction: column;
    gap: 20px;
    margin-top: 25px;
    display: flex;
  }

  .zLXxD .iEBgs .YigGP {
    background: var(--grey-sur);
    border-radius: 33px;
    width: calc(100% - 70px);
    padding: 35px;
  }

  .zLXxD .iEBgs .YigGP .h9h5Y img {
    width: 40%;
    box-shadow: 0 2.16px 10.82px var(--black-alpha-middle);
    border-radius: 12.5px;
  }

  .zLXxD .iEBgs .YigGP .h9h5Y img.oPrII {
    width: 35%;
    box-shadow: none;
    border-radius: 0;
  }

  .zLXxD .iEBgs .YigGP .ES7VN {
    margin-bottom: 20px;
    font-size: 16px;
  }
}

@media (width <= 480px) {
  .zLXxD .iEBgs .YigGP .h9h5Y img {
    width: 70%;
    box-shadow: 0 2.16px 10.82px var(--black-alpha-middle);
    border-radius: 12.5px;
  }
}

.k42bb {
  flex-direction: column;
  align-items: center;
  gap: 35px;
  display: flex;
}

.k42bb .uk1aS {
  text-align: center;
  background: var(--grad-blog);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  width: 70%;
  font-family: bold, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 44px;
}

.k42bb .eD55P {
  width: 60px;
  height: 60px;
  position: relative;
}

.k42bb .Zi-vH {
  flex-direction: row;
  gap: 43px;
  width: 100%;
  display: flex;
}

.k42bb .Zi-vH .f9D4V {
  width: 33.33%;
}

.k42bb .Zi-vH .f9D4V img {
  width: 100%;
  height: auto;
  box-shadow: 0 0 51.34px var(--black-alpha-middle);
  border-radius: 11px;
}

.k42bb .fGNP5 {
  width: 90%;
  margin-top: -100px;
  position: relative;
}

.k42bb .fGNP5 img {
  width: 100%;
  box-shadow: 0 0 51.34px var(--black-alpha-middle);
  border-radius: 13px;
}

@media (width <= 480px) {
  .k42bb {
    text-align: center;
  }

  .k42bb .uk1aS {
    width: 100%;
    margin-left: 0;
    font-size: 25px;
  }

  .k42bb .Zi-vH {
    grid-template-rows: repeat(5, 1fr);
    grid-template-columns: repeat(5, 1fr);
    gap: 0;
    margin-top: 20px;
    display: grid;
  }

  .k42bb .Zi-vH .f9D4V {
    background: var(--grey-sur);
    border-radius: 33px;
    width: calc(100% - 40px);
    height: fit-content;
    padding: 20px;
  }

  .k42bb .Zi-vH .f9D4V:first-child {
    grid-area: 2 / 1 / 5 / 4;
  }

  .k42bb .Zi-vH .f9D4V:nth-child(2) {
    grid-area: 1 / 3 / 4 / 6;
  }

  .k42bb .Zi-vH .f9D4V:nth-child(3) {
    grid-area: 3 / 2 / 6 / 5;
  }

  .k42bb .fGNP5 {
    width: 100%;
    margin: 0;
  }
}

.WXVPM {
  border: 1px solid var(--grey-sur);
  border-radius: 14px;
  flex-direction: column;
  align-items: center;
  gap: 37px;
  padding: 32px 22px;
  display: flex;
}

.WXVPM._75msI {
  border: unset;
  padding: 32px 0;
}

.WXVPM._75msI .b4WII {
  border: 1px solid var(--grey-sur);
  background: linear-gradient(126deg, #000, #ffffff14) !important;
  border-radius: 22px !important;
}

.WXVPM._75msI .b4WII ._-4sl- {
  filter: var(--icon-white);
}

.WXVPM ._9pqef {
  font-family: bold, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 32px;
}

.WXVPM ._9XQAG {
  flex-direction: column;
  gap: 26px;
  width: 100%;
  display: flex;
}

.WXVPM ._9XQAG .b4WII {
  background: var(--white);
  width: calc(100% - 55px);
  box-shadow: 0 0 20px var(--black-alpha-middle);
  border-radius: 8px;
  flex-direction: column;
  gap: 34px;
  padding: 25px 30px 25px 25px;
  display: flex;
}

.WXVPM ._9XQAG .b4WII .uxuJv {
  cursor: pointer;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  display: flex;
}

.WXVPM ._9XQAG .b4WII .uxuJv:hover .aouCN {
  background: var(--grey-sur);
}

.WXVPM ._9XQAG .b4WII .uxuJv .lhJ-- {
  font-family: semi-bold, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 20px;
}

.WXVPM ._9XQAG .b4WII .uxuJv .aouCN {
  border-radius: 50%;
  height: 16px;
  padding: 5px;
}

.WXVPM ._9XQAG .b4WII .uxuJv .aouCN ._-4sl- {
  background: url("black-plus.2ea284a8.svg") 0 0 / cover;
  width: 16px;
  height: 16px;
}

.WXVPM ._9XQAG .b4WII .uxuJv .aouCN ._-4sl-.bipT9 {
  animation: .5s forwards NqxeD;
}

@keyframes NqxeD {
  0% {
    -ms-transform: rotate(0);
    transform: rotate(0);
  }

  100% {
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}

.WXVPM ._9XQAG .b4WII .uxuJv .aouCN ._-4sl-.UzkRX {
  animation: .5s forwards Myd3e;
}

@keyframes Myd3e {
  0% {
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  100% {
    -ms-transform: rotate(0);
    transform: rotate(0);
  }
}

.WXVPM ._9XQAG .b4WII .CDFtg {
  width: 100%;
}

.WXVPM ._9XQAG .b4WII .CDFtg ul {
  margin-top: 10px;
  font-size: 14px;
}

.WXVPM ._9XQAG .b4WII .CDFtg li {
  margin: 0;
  list-style-type: circle;
}

.WXVPM ._9XQAG .b4WII .CDFtg strong {
  font-weight: normal;
}

.WXVPM ._9XQAG .b4WII .CDFtg .-wtOZ {
  font-family: medium, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 14px;
}

.WXVPM ._9XQAG .b4WII .CDFtg .-wtOZ a {
  color: var(--blue);
  text-decoration: none;
}

.WXVPM ._9XQAG .b4WII .CDFtg.n-yOA {
  display: none;
}

@media (width <= 480px) {
  .WXVPM {
    gap: 25px;
    padding: 25px 16px;
  }

  .WXVPM ._9pqef {
    text-align: center;
    font-size: 25px;
  }

  .WXVPM ._9XQAG, .WXVPM ._9XQAG .b4WII {
    gap: 16px;
  }

  .WXVPM ._9XQAG .b4WII .uxuJv .lhJ-- {
    font-size: 16px;
  }

  .WXVPM ._9XQAG .b4WII .CDFtg .-wtOZ {
    font-size: 13px;
  }
}

.FvVUi .PlotT {
  text-align: center;
  color: var(--white);
  font-family: bold, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 44px;
}

.FvVUi .t2Z1k {
  width: 100%;
  position: relative;
}

@media (width <= 480px) {
  .FvVUi .PlotT {
    width: 90%;
    font-size: 20px;
  }
}

.EJBeL {
  flex-direction: column;
  gap: 37px;
  display: flex;
}

.EJBeL .MI3-z {
  text-align: center;
  background: var(--grad-blue-button);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  margin: 0 20%;
  font-family: bold, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 44px;
}

.EJBeL .ayTYF {
  flex-direction: row;
  display: flex;
  position: relative;
}

.EJBeL .ayTYF .J2-sU {
  flex-direction: column;
  align-items: center;
  width: 33.33%;
  display: flex;
  position: relative;
}

.EJBeL .ayTYF .J2-sU .Hw6UG {
  text-align: center;
  background: var(--grey-sur);
  border-radius: 18px;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: calc(100% - 134px);
  height: calc(100% - 73px);
  margin: 0 27px;
  padding: 33px 40px 40px;
  display: flex;
}

.EJBeL .ayTYF .J2-sU .Hw6UG .wxJra {
  width: 90px;
  height: 90px;
}

.EJBeL .ayTYF .J2-sU .Hw6UG .kLb-5 {
  background: url("illus-computer.ec9ad9e6.svg") 0 0 / cover;
}

.EJBeL .ayTYF .J2-sU .Hw6UG ._63sdn {
  background: url("illus-pensil.c7b819ce.svg") 0 0 / cover;
}

.EJBeL .ayTYF .J2-sU .Hw6UG ._7TnQ3 {
  background: url("3d-collaboration.d4e3da94.svg") 0 0 / cover;
}

.EJBeL .ayTYF .J2-sU .Hw6UG .-WFi7 {
  background: url("illus-letter.cd9bdd54.svg") 0 0 / cover;
}

.EJBeL .ayTYF .J2-sU .Hw6UG ._3y1At {
  font-family: bold, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 28px;
}

.EJBeL .ayTYF .J2-sU .Hw6UG ._9LxgU {
  font-family: medium, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 14px;
}

.EJBeL .ayTYF .J2-sU .Hw6UG ._9LxgU strong {
  font-weight: unset;
}

.EJBeL .ayTYF .J2-sU .HmgLg {
  z-index: 1;
  width: calc(100% + 1px);
  margin-top: 5px;
  position: relative;
}

.EJBeL .ayTYF .J2-sU .nv92A {
  background: var(--white);
  z-index: 2;
  text-align: center;
  width: 100px;
  height: 100px;
  box-shadow: 0 0 32.85px var(--black-alpha-middle);
  border-radius: 50%;
  margin-top: -80px;
  padding: 12px 0;
  position: relative;
}

.EJBeL .ayTYF .J2-sU .nv92A ._4EZm0 {
  margin-top: 15px;
  font-family: bold, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 40px;
}

@media (width <= 750px) {
  .EJBeL {
    gap: 20px;
  }

  .EJBeL .MI3-z {
    margin: 0;
    font-size: 25px;
  }

  .EJBeL .ayTYF {
    flex-direction: column;
    gap: 20px;
  }

  .EJBeL .ayTYF .J2-sU {
    width: 100%;
  }

  .EJBeL .ayTYF .J2-sU .Hw6UG {
    width: calc(100% - 80px);
    margin: 0;
  }

  .EJBeL .ayTYF .J2-sU .HmgLg {
    display: none;
  }

  .EJBeL .ayTYF .J2-sU .nv92A {
    width: 50px;
    height: 50px;
    margin-top: -30px;
    padding: 0;
  }

  .EJBeL .ayTYF .J2-sU .nv92A ._4EZm0 {
    margin-top: 11px;
    font-size: 25px;
  }
}

.l5Zya .FMAOs {
  text-align: center;
  width: 100%;
}

.l5Zya .FMAOs .HsUnm {
  color: var(--white);
  padding: 0 156px;
  font-family: bold, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 44px;
}

.l5Zya .FMAOs .HsUnm.Vy0CW {
  background: var(--grad-black);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.l5Zya .FMAOs .HsUnm.lsnCj {
  background: var(--grad-red);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.l5Zya .FMAOs .HsUnm.PZ0UG {
  background: var(--grad-multi);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.l5Zya .ett64 {
  flex-direction: row;
  gap: 53px;
  margin-top: 75px;
  display: flex;
}

.l5Zya .ett64 .fRL0f {
  width: 50%;
  box-shadow: 0 0 32.85px var(--black-alpha-middle);
  text-align: center;
  border: 1px solid var(--web-border-white);
  backdrop-filter: blur(30px);
  background: linear-gradient(311deg, #8c62fe1a, #000);
  border-radius: 20.5px;
  padding-bottom: 76px;
}

.l5Zya .ett64 .fRL0f .octwo {
  width: 118px;
  height: 118px;
  margin-top: 63px;
  margin-left: calc(50% - 59px);
}

.l5Zya .ett64 .fRL0f .octwo.FXwLh {
  background: url("3d-seo.ff107651.svg") 0 0 / cover;
}

.l5Zya .ett64 .fRL0f .octwo.d1sAN {
  background: url("3d-handshake.84d0cea0.svg") 0 0 / cover;
}

.l5Zya .ett64 .fRL0f .octwo.OQ5TK {
  background: url("illus-customer-service.736ae70e.svg") 0 0 / cover;
}

.l5Zya .ett64 .fRL0f .octwo.zVly8 {
  background: url("illus-transaction.2ee3ba30.svg") 0 0 / cover;
}

.l5Zya .ett64 .fRL0f .octwo._9LXQs {
  background: url("3d-error.6027a386.svg") 0 0 / cover;
}

.l5Zya .ett64 .fRL0f .octwo.Yz97v {
  background: url("3d-spam.7dc3ae1c.svg") 0 0 / cover;
}

.l5Zya .ett64 .fRL0f .octwo._2J2d9 {
  background: url("illus-letter.cd9bdd54.svg") 0 0 / cover;
}

.l5Zya .ett64 .fRL0f .octwo.ZoKpH {
  background: url("3d-article-ia.da1f63c5.svg") 0 0 / cover;
}

.l5Zya .ett64 .fRL0f .lt4HJ {
  width: 90%;
  margin-top: 30px;
  margin-left: 5%;
  font-family: bold, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 32px;
}

.l5Zya .ett64 .fRL0f .zArqQ {
  margin-top: 15px;
  padding: 0 47px;
  font-family: semi-bold, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 16px;
}

@media (width <= 750px) {
  .l5Zya .FMAOs .HsUnm {
    padding: 0;
    font-size: 30px;
  }

  .l5Zya .ett64 {
    gap: 20px;
  }
}

@media (width <= 480px) {
  .l5Zya .FMAOs .HsUnm {
    font-size: 25px;
  }

  .l5Zya .ett64 {
    flex-direction: column;
    margin-top: 30px;
  }

  .l5Zya .ett64 .fRL0f {
    width: 100%;
    padding-bottom: 30px;
  }

  .l5Zya .ett64 .fRL0f .octwo {
    width: 70px;
    height: 70px;
    margin-top: 30px;
    margin-left: calc(50% - 35px);
  }

  .l5Zya .ett64 .fRL0f .lt4HJ {
    margin-top: 20px;
    font-size: 30px;
  }

  .l5Zya .ett64 .fRL0f .zArqQ {
    margin-top: 20px;
    padding: 0 30px;
    font-size: 16px;
  }
}

.YUUlr {
  flex-direction: column;
  gap: 40px;
  display: flex;
}

.YUUlr .lSnh6 {
  font-family: bold, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 44px;
}

.YUUlr .fXBXT {
  flex-direction: row;
  gap: 54px;
  width: 100%;
  display: flex;
}

.YUUlr .fXBXT .grb3R {
  flex-direction: column;
  gap: 11px;
  width: 40%;
  display: flex;
}

.YUUlr .fXBXT .grb3R .K5cGb {
  cursor: pointer;
  border: 1px solid var(--grey-sur);
  background: linear-gradient(126deg, #000, #ffffff14);
  border-radius: 21px;
  padding: 16px 17px 20px 20px;
}

.YUUlr .fXBXT .grb3R .K5cGb.Y7GTC, .YUUlr .fXBXT .grb3R .K5cGb:hover {
  background: var(--web-white-alpha);
}

.YUUlr .fXBXT .grb3R .K5cGb .Qv4F5 {
  flex-direction: row;
  gap: 12px;
  display: flex;
}

.YUUlr .fXBXT .grb3R .K5cGb .Qv4F5 .Ukb7J {
  width: 20px;
  height: 20px;
  filter: var(--icon-white);
}

.YUUlr .fXBXT .grb3R .K5cGb .Qv4F5 .Ukb7J.qOyeS {
  background: url("black-draft.a0b1de49.svg") 0 0 / cover;
}

.YUUlr .fXBXT .grb3R .K5cGb .Qv4F5 .Ukb7J.TKD4b {
  background: url("black-section.43eff183.svg") 0 0 / cover;
}

.YUUlr .fXBXT .grb3R .K5cGb .Qv4F5 .Ukb7J.eMtn6 {
  background: url("black-paragraph.1bddaa5c.svg") 0 0 / cover;
}

.YUUlr .fXBXT .grb3R .K5cGb .Qv4F5 .Ukb7J.dEJ1d {
  background: url("black-trombone.f3d2171e.svg") 0 0 / cover;
}

.YUUlr .fXBXT .grb3R .K5cGb .Qv4F5 .Ukb7J.uxSRm {
  background: url("black-shortcuts.f3f5f40c.svg") 0 0 / cover;
}

.YUUlr .fXBXT .grb3R .K5cGb .Qv4F5 .Ukb7J.YVX28 {
  background: url("black-pensil.60646059.svg") 0 0 / cover;
}

.YUUlr .fXBXT .grb3R .K5cGb .Qv4F5 .Ukb7J.xMOQY {
  background: url("black-lineal-time.78f4aa7b.svg") 0 0 / cover;
}

.YUUlr .fXBXT .grb3R .K5cGb .Qv4F5 .Ukb7J.OaGwx {
  background: url("black-lineal-home.4f80e72b.svg") 0 0 / cover;
}

.YUUlr .fXBXT .grb3R .K5cGb .Qv4F5 .Ukb7J.exXmA {
  background: url("black-lineal-mail.7c9918fb.svg") 0 0 / cover;
}

.YUUlr .fXBXT .grb3R .K5cGb .Qv4F5 .Ukb7J.zCXRG {
  background: url("black-lineal-mail-alias.32eb0318.svg") 0 0 / cover;
}

.YUUlr .fXBXT .grb3R .K5cGb .Qv4F5 .Ukb7J.X52gr {
  background: url("black-lineal-domains.42997d5f.svg") 0 0 / cover;
}

.YUUlr .fXBXT .grb3R .K5cGb .Qv4F5 .lSnh6 {
  text-align: left;
  margin-top: -2px;
  font-family: bold, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 21px;
}

.YUUlr .fXBXT .grb3R .K5cGb .pofdZ {
  margin: 10px 0 0 32px;
  font-family: semi-bold, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 16px;
}

.YUUlr .fXBXT .-zrsP {
  background: var(--blue-pastel);
  border-radius: 25px;
  justify-content: center;
  align-items: center;
  width: 60%;
  padding: 30px;
  display: flex;
}

.YUUlr .fXBXT .-zrsP .wGI6i {
  object-fit: contain;
  border-radius: 14px;
  width: auto;
  max-width: calc(100% - 60px);
  height: auto;
  max-height: 400px;
  display: block;
}

.YUUlr .fXBXT .-zrsP .yvUa4 {
  display: none;
}

@media (width <= 480px) {
  .YUUlr {
    gap: 20px;
  }

  .YUUlr .lSnh6 {
    text-align: center;
    font-size: 25px;
  }

  .YUUlr .fXBXT {
    flex-direction: column;
    gap: 20px;
  }

  .YUUlr .fXBXT .grb3R {
    gap: 8px;
    width: 100%;
  }

  .YUUlr .fXBXT .grb3R .K5cGb .Qv4F5 {
    gap: 8px;
  }

  .YUUlr .fXBXT .grb3R .K5cGb .Qv4F5 .Ukb7J {
    width: 15px;
    height: 15px;
  }

  .YUUlr .fXBXT .grb3R .K5cGb .Qv4F5 .lSnh6 {
    margin-top: -2px;
    font-size: 15px;
  }

  .YUUlr .fXBXT .grb3R .K5cGb .pofdZ {
    margin: 6px 0 0 22px;
    font-size: 12px;
  }

  .YUUlr .fXBXT .-zrsP {
    width: calc(100% - 40px);
    padding: 20px;
  }
}

.s8jKV .G-nsn {
  text-align: center;
  width: 100%;
}

.s8jKV .G-nsn .MA3hF {
  padding: 0 15%;
  font-family: bold, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 44px;
}

.s8jKV .G-nsn .MA3hF._6nbKi {
  font-family: bold, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 34px;
}

.s8jKV .G-nsn .MA3hF.-B4ob {
  font-family: bold, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 24px;
}

.s8jKV .G-nsn.HeQjH > .MA3hF {
  background: var(--grad-blue);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.s8jKV .G-nsn._3Gko9 > .MA3hF {
  background: var(--grad-red);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.s8jKV .G-nsn.ne6Gn > .MA3hF {
  background: var(--grad-black);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.s8jKV .G-nsn.XKRi3 > .MA3hF {
  background: var(--grad-multi);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.s8jKV .G-nsn._88gPl > .MA3hF {
  background: var(--grad-purple);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.s8jKV .p6aWt {
  justify-content: center;
  margin-top: 50px;
  display: flex;
}

.s8jKV .p6aWt .benqp {
  visibility: visible;
}

.s8jKV .p6aWt .benqp.y52n- {
  width: 100%;
  height: 100%;
}

.s8jKV .p6aWt .benqp._6nbKi {
  width: 60%;
  height: 100%;
}

@media (width <= 480px) {
  .s8jKV .G-nsn .MA3hF {
    padding: 0;
    font-family: bold, Segoe UI Emoji, Montserrat, sans-serif;
    font-size: 23px;
  }

  .s8jKV .G-nsn .MA3hF._6nbKi, .s8jKV .G-nsn .MA3hF.-B4ob {
    font-family: bold, Segoe UI Emoji, Montserrat, sans-serif;
    font-size: 23px;
  }

  .s8jKV .p6aWt {
    margin-top: 25px;
  }
}

.YR8RE .BrAUn {
  text-align: center;
  flex-direction: column;
  align-items: center;
  margin: 50px 0;
  display: flex;
}

.YR8RE .BrAUn .SrIN7 {
  background: var(--grad-black);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  width: 80%;
  font-family: bold, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 34px;
}

.YR8RE .BrAUn .B863R {
  background: var(--grad-black);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  width: 80%;
  font-family: bold, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 44px;
}

@media (width <= 480px) {
  .YR8RE .BrAUn {
    gap: 10px;
    margin: 0;
  }

  .YR8RE .BrAUn .SrIN7 {
    width: 100%;
    font-size: 22px;
  }

  .YR8RE .BrAUn .B863R {
    width: 100%;
    font-size: 28px;
  }
}

._8obg6 {
  flex-direction: column;
  gap: 25px;
  display: flex;
}

._8obg6 ._5iUED {
  flex-direction: column;
  gap: 17px;
  display: flex;
}

._8obg6 ._5iUED ._23fJh {
  flex-direction: row;
  gap: 36px;
  display: flex;
}

._8obg6 ._5iUED ._23fJh .JyyuX {
  background: url("illus-circle.2ff99b2c.svg") 0 0 / cover;
  width: 40px;
  height: 40px;
  margin-top: 8px;
}

._8obg6 ._5iUED ._23fJh .ZwOXq {
  font-family: bold, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 44px;
}

._8obg6 ._5iUED .onvxY {
  flex-direction: row;
  gap: 100px;
  min-height: 300px;
  display: flex;
}

._8obg6 ._5iUED .onvxY .-V1ky {
  border-left: 8px solid var(--grey-sur);
  margin-left: 16px;
  padding-bottom: 50px;
}

._8obg6 ._5iUED .onvxY .-V1ky .TVnje {
  flex-direction: column;
  gap: 50px;
  margin-left: 54px;
  display: flex;
}

._8obg6 ._5iUED .onvxY .-V1ky .TVnje .-UMv5 {
  font-family: semi-bold, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 23px;
}

._8obg6 ._5iUED .onvxY .-V1ky .TVnje .-UMv5 span {
  background: var(--grad-orange-button);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

._8obg6 ._5iUED .onvxY .-V1ky .TVnje .GM9Wg {
  cursor: pointer;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  width: fit-content;
  padding: 10px;
  display: flex;
}

._8obg6 ._5iUED .onvxY .-V1ky .TVnje .GM9Wg .pIF8L {
  text-align: right;
  background: var(--grad-orange-button);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  width: calc(100% - 50px);
  padding: 0 0 0 10px;
  font-family: bold, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 23px;
}

._8obg6 ._5iUED .onvxY .-V1ky .TVnje .GM9Wg .ahMLk {
  width: 18px;
  height: 18px;
  filter: var(--icon-orange);
  background: url("black-right-arrow.b93de0b4.svg") 0 0 / cover;
  margin: 0 10px 0 5px;
}

._8obg6 ._5iUED .onvxY .-V1ky .TVnje .GM9Wg:hover {
  background: var(--grey-sur);
  border-radius: 20px;
}

._8obg6 ._5iUED .onvxY .rvzAK {
  object-fit: contain;
  justify-content: center;
  align-items: center;
  min-width: 38%;
  max-width: 38%;
  display: flex;
}

._8obg6 ._5iUED .onvxY .rvzAK img {
  max-width: 100%;
  max-height: 70%;
}

@media (width <= 480px) {
  ._8obg6 {
    gap: 25px;
  }

  ._8obg6 ._5iUED {
    gap: 17px;
  }

  ._8obg6 ._5iUED ._23fJh {
    flex-direction: row;
    gap: 15px;
  }

  ._8obg6 ._5iUED ._23fJh .JyyuX {
    width: 30px;
    height: 30px;
    margin-top: 2px;
  }

  ._8obg6 ._5iUED ._23fJh .ZwOXq {
    font-size: 25px;
  }

  ._8obg6 ._5iUED .onvxY {
    border-left: 6px solid var(--grey-sur);
    flex-direction: column;
    gap: 20px;
    margin-left: 12px;
    padding-bottom: 0;
  }

  ._8obg6 ._5iUED .onvxY .-V1ky {
    border-left: none;
    padding-bottom: 0;
  }

  ._8obg6 ._5iUED .onvxY .-V1ky .TVnje {
    gap: 20px;
    margin-left: 13px;
  }

  ._8obg6 ._5iUED .onvxY .-V1ky .TVnje .-UMv5 {
    font-size: 16px;
  }

  ._8obg6 ._5iUED .onvxY .-V1ky .TVnje .GM9Wg {
    gap: 15px;
    width: 100%;
    padding: 0;
  }

  ._8obg6 ._5iUED .onvxY .-V1ky .TVnje .GM9Wg .pIF8L {
    width: calc(100% - 30px);
    padding: 0;
    font-size: 16px;
  }

  ._8obg6 ._5iUED .onvxY .-V1ky .TVnje .GM9Wg .ahMLk {
    width: 16px;
    height: 16px;
    margin: 0;
  }

  ._8obg6 ._5iUED .onvxY .rvzAK {
    min-width: calc(100% - 40px);
    max-width: calc(100% - 40px);
    padding: 20px 0 20px 40px;
  }

  ._8obg6 ._5iUED .onvxY .rvzAK img {
    width: 100%;
    height: 70%;
  }
}

.NLmOl .tC470 {
  background: var(--white);
  color: var(--black);
  border-radius: 20px;
  flex-direction: column;
  gap: 100px;
  padding: 55px 60px;
  display: flex;
}

.NLmOl .tC470 ._5962h {
  flex-direction: row-reverse;
  display: flex;
}

.NLmOl .tC470 ._5962h .g4Yqu {
  flex-direction: column;
  gap: 30px;
  width: 50%;
  display: flex;
}

.NLmOl .tC470 ._5962h .g4Yqu .VBPaj {
  width: 95%;
  font-family: bold, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 44px;
}

.NLmOl .tC470 ._5962h .g4Yqu .V2-ox {
  width: 80%;
  font-family: semi-bold, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 20px;
}

.NLmOl .tC470 ._5962h .pIWc1 {
  background: var(--web-blue);
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  width: calc(50% - 60px);
  padding: 30px;
  display: flex;
}

.NLmOl .tC470 ._5962h .pIWc1 .ydErr {
  background: var(--white);
  border-radius: 20px;
  width: calc(100% - 75px);
  height: auto;
}

.NLmOl .tC470 .jyv06 {
  width: 100%;
}

@media (width <= 480px) {
  .NLmOl .tC470 {
    gap: 60px;
    padding: 20px 30px;
  }

  .NLmOl .tC470 ._5962h {
    flex-direction: column-reverse;
    gap: 30px;
  }

  .NLmOl .tC470 ._5962h .g4Yqu {
    text-align: center;
    flex-direction: column;
    gap: 20px;
    width: 100%;
  }

  .NLmOl .tC470 ._5962h .g4Yqu .VBPaj {
    width: 100%;
    font-size: 25px;
  }

  .NLmOl .tC470 ._5962h .g4Yqu .V2-ox {
    width: 100%;
    font-size: 16px;
  }

  .NLmOl .tC470 ._5962h .g4Yqu .gWBxP {
    display: none;
  }

  .NLmOl .tC470 ._5962h .pIWc1 {
    width: calc(100% - 40px);
    padding: 20px;
  }
}

.ea3Uf {
  flex-direction: column;
  gap: 64px;
  display: flex;
}

.ea3Uf .D8KYI {
  text-align: center;
  width: 100%;
}

.ea3Uf .D8KYI .pKtD4 {
  padding: 0 15%;
  font-family: bold, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 44px;
}

.ea3Uf .D8KYI .pKtD4._8-INO {
  background: var(--grad-blue);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.ea3Uf .bsKOx {
  flex-flow: wrap;
  gap: 56px;
  display: flex;
  position: relative;
}

.ea3Uf .bsKOx .fdgHA {
  border: 1px solid var(--grey-sur);
  text-align: center;
  background: linear-gradient(126deg, #000, #ffffff14);
  border-radius: 14px;
  flex-direction: column;
  align-items: center;
  gap: 25px;
  width: calc(33.33% - 112px);
  padding: 36px;
  display: flex;
}

.ea3Uf .bsKOx .fdgHA .HvutW {
  width: 70px;
  height: 70px;
  filter: var(--icon-white);
}

.ea3Uf .bsKOx .fdgHA .HvutW.fseJ9 {
  background: url("black-chart.f26b7454.svg") 0 0 / cover;
}

.ea3Uf .bsKOx .fdgHA .HvutW.bK8ii {
  background: url("black-lineal-time.78f4aa7b.svg") 0 0 / cover;
}

.ea3Uf .bsKOx .fdgHA .HvutW._7Elhc {
  background: url("black-lineal-good.45bf678b.svg") 0 0 / cover;
}

.ea3Uf .bsKOx .fdgHA .HvutW.e8gfv {
  background: url("black-export.47571aa4.svg") 0 0 / cover;
}

.ea3Uf .bsKOx .fdgHA .HvutW.mrdpH {
  background: url("black-lineal-voice.845c2997.svg") 0 0 / cover;
}

.ea3Uf .bsKOx .fdgHA .HvutW._-4NrD {
  background: url("black-lineal-words.438eaf0d.svg") 0 0 / cover;
}

.ea3Uf .bsKOx .fdgHA .HvutW.P-UZS {
  background: url("black-lineal-seo.745af63d.svg") 0 0 / cover;
}

.ea3Uf .bsKOx .fdgHA .HvutW.sVvPK {
  background: url("black-lineal-code.81367723.svg") 0 0 / cover;
}

.ea3Uf .bsKOx .fdgHA .HvutW.q3A4x {
  background: url("black-lineal-read.e2bc8308.svg") 0 0 / cover;
}

.ea3Uf .bsKOx .fdgHA .HvutW.z29iQ {
  background: url("black-lineal-tags.344ac971.svg") 0 0 / cover;
}

.ea3Uf .bsKOx .fdgHA .HvutW.aGIoO {
  background: url("black-lineal-domains.42997d5f.svg") 0 0 / cover;
}

.ea3Uf .bsKOx .fdgHA .HvutW.LWfxr {
  background: url("black-lineal-conversations.668ac514.svg") 0 0 / cover;
}

.ea3Uf .bsKOx .fdgHA .HvutW.M6L97 {
  background: url("black-lineal-planning.f3026af4.svg") 0 0 / cover;
}

.ea3Uf .bsKOx .fdgHA .HvutW.odq-- {
  background: url("black-lineal-delay.a91c0eae.svg") 0 0 / cover;
}

.ea3Uf .bsKOx .fdgHA .HvutW.u9Rae {
  background: url("black-lineal-rating.b19ac6e4.svg") 0 0 / cover;
}

.ea3Uf .bsKOx .fdgHA .HvutW._78rux {
  background: url("black-lineal-team.473c446a.svg") 0 0 / cover;
}

.ea3Uf .bsKOx .fdgHA .pKtD4 {
  font-family: bold, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 22px;
}

.ea3Uf .bsKOx .fdgHA ._1aumv {
  font-family: semi-bold, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 14px;
}

@media (width <= 480px) {
  .ea3Uf {
    gap: 20px;
  }

  .ea3Uf .D8KYI .pKtD4 {
    padding: 0;
    font-size: 25px;
  }

  .ea3Uf .bsKOx {
    flex-direction: column;
    gap: 20px;
  }

  .ea3Uf .bsKOx .fdgHA {
    gap: 20px;
    width: calc(100% - 40px);
    padding: 20px;
  }

  .ea3Uf .bsKOx .fdgHA .HvutW {
    width: 50px;
    height: 50px;
  }
}

.AQxmE {
  flex-direction: column;
  justify-content: center;
  display: flex;
}

.AQxmE .IERJg {
  text-align: center;
  margin-bottom: 0;
  font-size: 40px;
}

.AQxmE h2 {
  font-family: bold, Segoe UI Emoji, Montserrat, sans-serif;
}

.AQxmE .gwW2L {
  text-align: center;
}

.AQxmE .eyuEU, .AQxmE .c-Mca, .AQxmE .Cj8oB {
  padding: 20px 25px;
}

.AQxmE .VK2zh {
  visibility: visible;
}

.AQxmE .eyuEU {
  border: 1px solid var(--grey-sur);
  border-radius: 12px;
  margin-top: 30px;
}

@media (width <= 480px) {
  .AQxmE .IERJg {
    margin-bottom: 14px;
    font-size: 35px;
  }
}

.e1dKy, .lzoUU, .tGGse {
  display: none;
}

.LdoR- {
  flex-direction: column;
  gap: 25px;
  display: flex;
}

.LdoR- .OvGB- {
  flex-direction: column;
  gap: 17px;
  display: flex;
}

.LdoR- .OvGB- ._1J-Of {
  flex-direction: row-reverse;
  gap: 36px;
  display: flex;
}

.LdoR- .OvGB- ._1J-Of .crt1m {
  background: url("illus-circle.2ff99b2c.svg") 0 0 / cover;
  width: 40px;
  height: 40px;
  margin-top: 8px;
}

.LdoR- .OvGB- ._1J-Of .XkFbv {
  font-family: bold, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 44px;
}

.LdoR- .OvGB- ._5uF3l {
  flex-direction: row-reverse;
  gap: 100px;
  min-height: 300px;
  display: flex;
}

.LdoR- .OvGB- ._5uF3l .BFSYr {
  border-right: 8px solid var(--grey-sur);
  margin-right: 16px;
  padding-bottom: 50px;
}

.LdoR- .OvGB- ._5uF3l .BFSYr .aXGDu {
  text-align: right;
  flex-direction: column;
  gap: 50px;
  margin-right: 54px;
  display: flex;
}

.LdoR- .OvGB- ._5uF3l .BFSYr .aXGDu .FTAVd {
  font-family: semi-bold, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 23px;
}

.LdoR- .OvGB- ._5uF3l .BFSYr .aXGDu .FTAVd span {
  background: var(--grad-orange-button);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.LdoR- .OvGB- ._5uF3l .BFSYr .aXGDu .j68oj {
  cursor: pointer;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  width: fit-content;
  padding: 10px;
  display: flex;
}

.LdoR- .OvGB- ._5uF3l .BFSYr .aXGDu .j68oj .FbUQf {
  text-align: right;
  background: var(--grad-orange-button);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  width: calc(100% - 50px);
  padding: 0 10px 0 0;
  font-family: bold, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 23px;
}

.LdoR- .OvGB- ._5uF3l .BFSYr .aXGDu .j68oj .rr0bA {
  width: 18px;
  height: 18px;
  filter: var(--icon-orange);
  background: url("black-right-arrow.b93de0b4.svg") 0 0 / cover;
  margin: 0 10px 0 5px;
}

.LdoR- .OvGB- ._5uF3l .BFSYr .aXGDu .j68oj:hover {
  background: var(--grey-sur);
  border-radius: 20px;
}

.LdoR- .OvGB- ._5uF3l .My2zJ {
  object-fit: contain;
  justify-content: center;
  align-items: center;
  min-width: 38%;
  max-width: 38%;
  display: flex;
}

.LdoR- .OvGB- ._5uF3l .My2zJ img {
  max-width: 100%;
  max-height: 70%;
}

@media (width <= 480px) {
  .LdoR- {
    gap: 25px;
  }

  .LdoR- .OvGB- {
    gap: 17px;
  }

  .LdoR- .OvGB- ._1J-Of {
    flex-direction: row;
    gap: 15px;
  }

  .LdoR- .OvGB- ._1J-Of .crt1m {
    width: 30px;
    height: 30px;
    margin-top: 2px;
  }

  .LdoR- .OvGB- ._1J-Of .XkFbv {
    font-size: 25px;
  }

  .LdoR- .OvGB- ._5uF3l {
    border-left: 6px solid var(--grey-sur);
    flex-direction: column;
    gap: 20px;
    margin-left: 12px;
    padding-bottom: 0;
  }

  .LdoR- .OvGB- ._5uF3l .BFSYr {
    border-left: none;
    padding-bottom: 0;
  }

  .LdoR- .OvGB- ._5uF3l .BFSYr .aXGDu {
    gap: 20px;
    margin-left: 13px;
  }

  .LdoR- .OvGB- ._5uF3l .BFSYr .aXGDu .FTAVd {
    font-size: 16px;
  }

  .LdoR- .OvGB- ._5uF3l .BFSYr .aXGDu .j68oj {
    gap: 15px;
    width: 100%;
    padding: 0;
  }

  .LdoR- .OvGB- ._5uF3l .BFSYr .aXGDu .j68oj .FbUQf {
    width: calc(100% - 30px);
    padding: 0;
    font-size: 16px;
  }

  .LdoR- .OvGB- ._5uF3l .BFSYr .aXGDu .j68oj .rr0bA {
    width: 16px;
    height: 16px;
    margin: 0;
  }

  .LdoR- .OvGB- ._5uF3l .My2zJ {
    min-width: calc(100% - 40px);
    max-width: calc(100% - 40px);
    padding: 20px 0 20px 40px;
  }

  .LdoR- .OvGB- ._5uF3l .My2zJ img {
    width: 100%;
    height: 70%;
  }
}

.XFZ-n {
  background: var(--white);
  box-sizing: border-box;
  width: 50%;
  color: var(--black);
  border-radius: 14px;
  flex: calc(50% - 25px);
  padding: 25px;
}

.XFZ-n ._9Uo3c {
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
  width: 100%;
  margin-bottom: 20px;
  display: flex;
}

.XFZ-n ._9Uo3c .uidhD {
  background: url("black-star.9dfc2eb0.svg") 0 0 / cover;
  width: 18px;
  height: 18px;
  margin-right: 1px;
  display: inline-block;
}

.XFZ-n ._9Uo3c .hd61J {
  color: var(--black);
  align-items: center;
  margin-left: auto;
  display: flex;
}

.XFZ-n ._9Uo3c .hd61J .AAaF1 {
  margin-right: 4px;
}

.XFZ-n ._9Uo3c .hd61J._9X34Z .OBEL0 {
  background: url("colored-shopify.98dad4f7.svg") 0 0 / cover;
}

.XFZ-n ._9Uo3c .hd61J._41OXe .OBEL0 {
  background: url("colored-google.394cf03c.svg") 0 0 / cover;
}

.XFZ-n ._9Uo3c .hd61J .OBEL0 {
  background: red;
  width: 16px;
  height: 16px;
  margin-left: 6px;
}

.XFZ-n .SCGSn {
  color: var(--black);
  font-size: 17px;
}

.XFZ-n ._8casC {
  align-items: center;
  gap: 15px;
  margin-top: 25px;
  display: flex;
}

.XFZ-n ._8casC .lnQ2D {
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 44px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.XFZ-n ._8casC .lnQ2D img {
  width: 100%;
}

.XFZ-n ._8casC .GYBvd {
  font-family: semi-bold, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 19px;
}

.XFZ-n ._8casC .lt-8w {
  color: var(--black);
  font-size: 15px;
}

.P5o2F .rY15Z {
  text-align: center;
  width: 100%;
  margin-bottom: 30px;
  font-family: bold, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 44px;
}

.P5o2F ._1-Wsg {
  flex-wrap: wrap;
  gap: 25px;
  display: flex;
}

.TBvlc {
  z-index: 9999;
  pointer-events: none;
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
  position: fixed;
  bottom: 15px;
}

.TBvlc .-KmyV {
  pointer-events: all;
  background: var(--white);
  background: var(--white);
  border-radius: 14px;
  width: 100%;
  max-width: 700px;
  padding: 20px;
  -ms-transform: translateY(500px)scale(.8);
  transform: translateY(500px)scale(.8);
  box-shadow: 0 0 0 1px #00000008, 0 0 20px #0003;
}

.TBvlc .-KmyV .WGM9H {
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
  display: flex;
}

.TBvlc .-KmyV .WGM9H .X6Gwb {
  font-family: semi-bold, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 21px;
}

.TBvlc .-KmyV .WGM9H .gSjsg {
  cursor: pointer;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  margin-left: auto;
  display: flex;
}

.TBvlc .-KmyV .WGM9H .gSjsg:hover {
  background: var(--grey-sur);
}

.TBvlc .-KmyV .WGM9H .gSjsg .MN3oY {
  background: url("black-close.4640468b.svg") 0 0 / cover;
  width: 18px;
  height: 18px;
}

.TBvlc .-KmyV .-csry {
  align-items: center;
  gap: 15px;
  margin-bottom: 10px;
  display: flex;
}

.TBvlc .-KmyV .-csry .MN3oY {
  background: url("3d-confetti.168bf930.svg") 0 0 / cover;
  flex: 0 0 70px;
  width: 70px;
  height: 70px;
}

.TBvlc .-KmyV .-csry .P-cL4 {
  font-family: semi-bold, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 17px;
}

.TBvlc .-KmyV ._3CPqo {
  gap: 10px;
  display: flex;
}

.TBvlc .-KmyV ._3CPqo .BFE7C {
  border: 1px solid var(--grey-sur);
  border-radius: 14px;
  width: 160px;
  height: 107px;
  margin-bottom: 10px;
  padding: 5px;
}

.TBvlc .-KmyV ._3CPqo .BFE7C._1ICUF .MN3oY {
  background: url("3d-context.051eac5f.svg") 0 0 / cover;
}

.TBvlc .-KmyV ._3CPqo .BFE7C.nJEw- .MN3oY {
  background: url("3d-style.8459d65b.svg") 0 0 / cover;
}

.TBvlc .-KmyV ._3CPqo .BFE7C._7-e8R .MN3oY {
  background: url("3d-ai.156d9775.svg") 0 0 / cover;
}

.TBvlc .-KmyV ._3CPqo .BFE7C .WGM9H {
  border-radius: 100%;
  align-items: center;
  gap: 6px;
  margin-bottom: 6px;
  font-family: semi-bold, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 12px;
  display: flex;
}

.TBvlc .-KmyV ._3CPqo .BFE7C .WGM9H ._0Gh5d {
  background: var(--grey-sur);
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  width: 21px;
  height: 21px;
  display: flex;
}

.TBvlc .-KmyV ._3CPqo .BFE7C .WGM9H ._0Gh5d .MN3oY {
  width: 12px;
  height: 12px;
}

.TBvlc .-KmyV ._3CPqo .BFE7C .P-cL4 {
  color: var(--grey-text-very-dark);
  font-size: 12px;
}

.TBvlc .-KmyV .njImB {
  background: var(--black);
  width: fit-content;
  color: var(--white);
  border-radius: 24px;
  align-items: center;
  gap: 10px;
  margin-left: auto;
  padding: 12px 20px;
  transition: all .1s;
  display: flex;
}

.TBvlc .-KmyV .njImB:hover {
  opacity: .8;
}

.TBvlc .-KmyV .njImB:hover .MN3oY {
  margin-left: 10px;
}

.TBvlc .-KmyV .njImB .MN3oY {
  width: 14px;
  height: 14px;
  filter: var(--icon-white);
  background: url("black-right-arrow.b93de0b4.svg") 0 0 / cover;
  transition: all .1s;
}

@media (width <= 480px) {
  .TBvlc .-KmyV ._3CPqo {
    display: none;
  }
}

.I68Mt .YSJEJ {
  min-width: 320px;
  height: 700px;
}

._52VCW {
  flex-direction: column;
  gap: 30px;
  display: flex;
  position: relative;
}

._52VCW.fzD9t {
  align-items: center;
}

._52VCW.fzD9t .OYML3, ._52VCW.fzD9t .pX5NR {
  text-align: center;
}

._52VCW .OYML3 {
  width: 90%;
  font-family: bold, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 54px;
}

._52VCW .pX5NR {
  z-index: 1;
  width: 70%;
  font-size: 16px;
}

._52VCW .GujlJ {
  justify-content: center;
  height: 190px;
  display: flex;
  position: relative;
}

._52VCW .GujlJ .Wi9mZ {
  background: var(--white);
  filter: blur(60px);
  width: 62px;
  height: 62px;
  position: absolute;
}

._52VCW .GujlJ .GIt79 {
  background: var(--white);
  filter: blur(100px);
  width: 187px;
  height: 48px;
  position: absolute;
  bottom: 0;
}

._52VCW .GujlJ ._9Bt5v {
  background: url("illus-black-prestashop.ed7d0ea1.svg") 0 0 / cover;
  width: 290px;
  height: 290px;
}

._52VCW .GujlJ .rO5f3 {
  background: url("illus-black-shopify.747193e0.svg") 0 0 / cover;
  width: 290px;
  height: 290px;
}

._52VCW .GujlJ .cpRAF {
  background: url("illus-black-wordpress.7063d6a4.svg") 0 0 / cover;
  width: 290px;
  height: 290px;
}

._52VCW .GujlJ .tTGka {
  background: url("illus-black-wix.7e4bd9f7.svg") 0 0 / cover;
  width: 290px;
  height: 290px;
}

._52VCW .vuphj {
  gap: 15px;
  display: flex;
  position: relative;
}

._52VCW .vuphj ._2Mumn {
  border-top: 2px solid var(--web-border-blue);
  background: var(--web-grad-blue);
  color: var(--white);
  cursor: pointer;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  height: fit-content;
  padding: 20px 32px;
  font-size: 16px;
  display: flex;
  position: relative;
}

._52VCW .vuphj ._2Mumn:hover {
  background: var(--web-blue-hover);
}

._52VCW .vuphj ._2Mumn:hover .ux-Oq {
  -ms-transform: translateX(8px);
  transform: translateX(8px);
}

._52VCW .vuphj ._2Mumn.Sz80f {
  background: var(--white);
  border: unset;
  color: var(--black);
}

._52VCW .vuphj ._2Mumn.Sz80f .ux-Oq {
  filter: var(--icon-black) !important;
}

._52VCW .vuphj ._2Mumn.Sz80f a {
  color: unset;
}

._52VCW .vuphj ._2Mumn .rkJA8 {
  background: var(--web-blue);
  filter: blur(100px);
  pointer-events: none;
  width: 168px;
  height: 168px;
  position: absolute;
}

._52VCW .vuphj ._2Mumn .-uSQp {
  align-items: center;
  gap: 15px;
  display: flex;
  position: relative;
}

._52VCW .vuphj ._2Mumn .-uSQp .ux-Oq {
  width: 16px;
  height: 16px;
  filter: var(--icon-white);
  background: url("black-right-arrow.b93de0b4.svg") 0 0 / cover;
  transition: -ms-transform .1s ease-in-out, transform .1s ease-in-out;
}

._52VCW .vuphj ._2Mumn .-uSQp .ux-Oq.GLQDJ {
  background: url("colored-prestashop.f0a14972.svg") 0 0 / cover;
  width: 22px;
  height: 22px;
  filter: unset !important;
}

._52VCW .vuphj .ObTY6 {
  background: var(--web-background-steps);
  border: 1px solid var(--web-border-white);
  color: var(--white);
  border-radius: 15px;
  align-items: center;
  gap: 15px;
  width: 366px;
  padding: 13px 18px;
  font-size: 14px;
  display: flex;
}

._52VCW .vuphj .ObTY6 .ux-Oq {
  width: 18px;
  height: 18px;
  filter: var(--icon-blue-black-web);
  background: url("black-star-fod.7d63a0b5.svg") 0 0 / cover;
  flex: 0 0 18px;
}

._52VCW .fXOt- {
  filter: blur(100px);
  background: #fff3;
  width: 239px;
  height: 124px;
  position: absolute;
  top: -77px;
  left: calc(50% - 120px);
}

@media screen and (width <= 930px) {
  ._52VCW .OYML3 {
    text-align: center;
    width: 100%;
    font-size: 36px;
  }

  ._52VCW .pX5NR {
    text-align: center;
    width: 100%;
  }

  ._52VCW .vuphj {
    flex-direction: column;
    align-items: center;
  }

  ._52VCW .vuphj ._2Mumn {
    width: calc(100% - 64px);
  }

  ._52VCW .vuphj .ObTY6 {
    width: calc(100% - 36px);
    position: relative;
  }
}

.PoB7X {
  border: 1px solid var(--web-border-white);
  background: var(--web-background-steps);
  border-radius: 50px;
  padding: 50px;
  position: relative;
}

.PoB7X .Vhl-t {
  background: var(--web-purple);
  filter: blur(95px);
  width: 150px;
  height: 150px;
  position: absolute;
  bottom: 0;
  right: 0;
}

.PoB7X .VkiXO {
  align-items: center;
  margin-bottom: 35px;
  display: flex;
  position: relative;
}

.PoB7X .VkiXO .oZZa3 {
  background: var(--web-purple);
  filter: blur(95px);
  width: 100px;
  height: 100px;
  position: absolute;
  top: 0;
  left: 0;
}

.PoB7X .VkiXO .HaV8- {
  align-items: center;
  gap: 15px;
  display: flex;
  position: relative;
}

.PoB7X .VkiXO .HaV8- .DYaIt {
  width: 22px;
  height: 22px;
  filter: var(--icon-purple-black-web);
}

.PoB7X .VkiXO .HaV8- .DYaIt.c486o {
  background: url("black-lineal-target.456c15f1.svg") 0 0 / cover;
}

.PoB7X .VkiXO .HaV8- .-KzaJ {
  color: var(--white);
  font-size: 14px;
}

.PoB7X .VkiXO ._2MtyC {
  gap: 10px;
  margin-left: auto;
  display: flex;
}

.PoB7X .VkiXO ._2MtyC .qxc5x {
  background: var(--web-white-alpha);
  cursor: pointer;
  border-radius: 11px;
  padding: 10px 20px;
  transition: all .1s;
  position: relative;
  overflow: hidden;
}

.PoB7X .VkiXO ._2MtyC .qxc5x:hover {
  background: var(--web-white-alpha-hover);
}

.PoB7X .VkiXO ._2MtyC .qxc5x .DwOoN {
  background: var(--web-blue);
  width: 0%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.PoB7X .VkiXO ._2MtyC .qxc5x .IEX5n {
  font-size: 14px;
  position: relative;
}

.PoB7X .JQG93 {
  border-radius: 14px;
  position: relative;
  overflow: hidden;
}

.PoB7X .JQG93 .hr8SM {
  border-radius: 12px;
  width: 100%;
}

@media screen and (width <= 930px) {
  .PoB7X {
    border-radius: 18px;
    padding: 15px;
  }

  .PoB7X .VkiXO {
    gap: 15px;
    width: 100%;
    overflow-y: auto;
  }

  .PoB7X .VkiXO .oZZa3, .PoB7X .VkiXO .HaV8- .DYaIt {
    display: none;
  }

  .PoB7X .VkiXO .HaV8- .-KzaJ, .PoB7X .VkiXO ._2MtyC .qxc5x {
    white-space: nowrap;
  }
}

.-aEG- {
  text-align: center;
  justify-content: center;
  align-items: center;
  font-family: bold, Segoe UI Emoji, Montserrat, sans-serif;
  display: flex;
}

.-aEG-.iCnEs {
  font-size: 28px;
}

.-aEG-.iCnEs .bA27x {
  max-width: 840px;
}

.-aEG-.bHiY6 {
  font-size: 36px;
}

.-aEG-.bHiY6 .bA27x {
  max-width: 100%;
}

.-aEG-.p4ztM .YQURL {
  background: var(--web-blue);
}

.-aEG- .YQURL {
  filter: blur(100px);
  will-change: filter, transform;
  width: 409px;
  height: 126px;
  position: absolute;
}

.-aEG- .bA27x {
  padding: 15px 0;
  position: relative;
}

@media screen and (width <= 930px) {
  .-aEG- {
    font-size: 28px !important;
  }
}

.zT2fa {
  flex-direction: column;
  gap: 15px;
  display: flex;
}

.zT2fa ._4krrE {
  align-items: center;
  gap: 15px;
  display: flex;
}

.zT2fa ._4krrE .APUgj {
  justify-self: center;
  align-items: center;
  width: 32px;
  height: 32px;
  display: flex;
  position: relative;
}

.zT2fa ._4krrE .APUgj .LTdkm {
  background: var(--web-blue);
  filter: blur(13px);
  width: 100%;
  height: 100%;
  position: absolute;
}

.zT2fa ._4krrE .APUgj .wJONj {
  background: url("3d-ai.156d9775.svg") 0 0 / cover;
  width: 27px;
  height: 27px;
  position: absolute;
}

.zT2fa ._4krrE .QCAI8 {
  text-transform: uppercase;
  color: var(--web-blue);
  font-size: 16px;
}

.zT2fa .haNuL {
  color: var(--white);
  font-family: semi-bold, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 28px;
}

.zT2fa ._8iGRt {
  align-items: center;
  width: 110px;
  margin: 10px 0;
  display: flex;
  position: relative;
}

.zT2fa ._8iGRt ._6GR0q {
  filter: blur(10px);
  background: var(--white);
  width: 100%;
  height: 14px;
  position: absolute;
}

.zT2fa ._8iGRt .jLehA {
  background: var(--white);
  border-radius: 6px;
  width: 100%;
  height: 6px;
  position: absolute;
}

.zT2fa .NCbz5 {
  color: var(--white);
  font-family: semi-bold, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 21px;
}

@media screen and (width <= 930px) {
  .zT2fa .haNuL {
    font-size: 25px;
  }
}

.qKCUJ {
  flex-direction: column;
  gap: 33px;
  display: flex;
}

.qKCUJ .WPmMr {
  gap: 33px;
  display: flex;
}

.qKCUJ .WPmMr .C8CXK {
  border: 1px solid var(--web-border-white);
  background: var(--web-brackground-grid-green);
  border-radius: 22px;
  flex-direction: column;
  width: 30%;
  padding: 25px;
  display: flex;
  position: relative;
}

.qKCUJ .WPmMr .C8CXK .dWs1Q {
  z-index: 1;
  flex-direction: column;
  gap: 25px;
  display: flex;
  position: relative;
}

.qKCUJ .WPmMr .C8CXK .dWs1Q .b-s4o {
  align-items: center;
  gap: 10px;
  font-family: semi-bold, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 21px;
  display: flex;
}

.qKCUJ .WPmMr .C8CXK .dWs1Q .b-s4o.gTIC5 {
  display: block;
}

.qKCUJ .WPmMr .C8CXK .dWs1Q .b-s4o .u-01b {
  justify-content: center;
  align-items: center;
  width: 6px;
  height: 15px;
  margin-right: 10px;
  display: inline-flex;
}

.qKCUJ .WPmMr .C8CXK .dWs1Q .b-s4o .u-01b .tyMh- {
  height: 1px;
  box-shadow: 0 0 20px 8px var(--white);
  position: absolute;
}

.qKCUJ .WPmMr .C8CXK .dWs1Q .b-s4o .u-01b .T-c5i {
  font-family: semi-bold, Segoe UI Emoji, Montserrat, sans-serif;
  position: absolute;
}

.qKCUJ .WPmMr .C8CXK .dWs1Q .b-s4o .U-iGy {
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
  top: -2px;
}

.qKCUJ .WPmMr .C8CXK .dWs1Q .b-s4o .U-iGy .BF8rl {
  background: var(--web-blue);
  filter: blur(13px);
  width: 32px;
  height: 32px;
  position: absolute;
}

.qKCUJ .WPmMr .C8CXK .dWs1Q .b-s4o .U-iGy .wQjPy {
  background: url("3d-ai.156d9775.svg") 0 0 / cover;
  width: 27px;
  height: 27px;
  position: relative;
}

.qKCUJ .WPmMr .C8CXK .dWs1Q .L-nqz {
  font-size: 13.5px;
}

.qKCUJ .WPmMr .C8CXK .JJUy6 {
  z-index: 1;
  justify-content: center;
  align-items: center;
  height: 100%;
  display: flex;
}

.qKCUJ .WPmMr .C8CXK .JJUy6 .ff7Pk {
  width: 130px;
  height: 130px;
}

.qKCUJ .WPmMr .C8CXK .JJUy6 .ff7Pk._7bZ0S {
  background: url("illus-black-optimize.f3bd2a37.svg") 0 0 / cover;
}

.qKCUJ .WPmMr .C8CXK .JJUy6 .ff7Pk.lv8jF {
  background: url("illus-black-ready.c722f0a0.svg") 0 0 / cover;
}

.qKCUJ .WPmMr .C8CXK .JJUy6 .ff7Pk.S7SPX {
  background: url("illus-black-unique.1ed25f33.svg") 0 0 / cover;
}

.qKCUJ .WPmMr .C8CXK .JJUy6 .ff7Pk.YT-pf {
  background: url("illus-black-picture.856754ff.svg") 0 0 / cover;
}

.qKCUJ .WPmMr .C8CXK .JJUy6 .ff7Pk.Cliu1 {
  background: url("illus-black-seo.587070c8.svg") 0 0 / cover;
}

.qKCUJ .WPmMr .C8CXK .JJUy6 .ff7Pk.PYhl0 {
  background: url("illus-black-write.cde02bf8.svg") 0 0 / cover;
}

.qKCUJ .WPmMr .C8CXK .JJUy6 .ff7Pk.CFik- {
  background: url("illus-black-plan.75b9f904.svg") 0 0 / cover;
}

.qKCUJ .WPmMr .C8CXK .JJUy6 .ff7Pk.pLCxC {
  background: url("illus-black-chart.39ebff7b.svg") 0 0 / cover;
}

.qKCUJ .WPmMr .C8CXK .JJUy6 .ff7Pk.rSty3 {
  background: url("illus-black-google.7b06b43a.svg") 0 0 / cover;
}

.qKCUJ .WPmMr .C8CXK .JJUy6 .ff7Pk.Mo3sE {
  background: url("illus-black-search-engine.d7247a2c.svg") 0 0 / cover;
}

.qKCUJ .WPmMr .C8CXK .JJUy6 .ff7Pk.MGs8X {
  background: url("illus-black-funnel.0161cea5.svg") 0 0 / cover;
  width: 404px;
  height: 190px;
}

.qKCUJ .WPmMr .C8CXK .JJUy6 .ff7Pk.MsCft {
  background: url("illus-black-autopilot.13603435.svg") 0 0 / cover;
  width: 358px;
  height: 100px;
}

.qKCUJ .WPmMr .C8CXK .JJUy6 .ff7Pk.-oQhP {
  background: url("illus-black-chart-purple.aeb70e2e.svg") 0 0 / cover;
}

.qKCUJ .WPmMr .C8CXK .JJUy6 .ff7Pk.IpCFF {
  background: url("illus-black-languages.5fb8ee4f.svg") 0 0 / cover;
}

.qKCUJ .WPmMr .C8CXK .pJg8E {
  z-index: 1;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: auto;
  display: flex;
  position: relative;
  bottom: -25px;
}

.qKCUJ .WPmMr .C8CXK .pJg8E .IEky7 {
  border-radius: 12px 12px 0 0;
  width: 100%;
}

.qKCUJ .WPmMr .C8CXK .D-XNO {
  background: var(--web-shadow-green);
  filter: blur(85px);
  width: 84px;
  height: 84px;
  position: absolute;
  bottom: 0;
  right: 0;
}

.qKCUJ .WPmMr .C8CXK.KpKma {
  background: var(--web-background-grid-blue);
}

.qKCUJ .WPmMr .C8CXK.KpKma .D-XNO {
  background: var(--web-blue);
}

.qKCUJ .WPmMr .C8CXK._0oATR {
  background: var(--web-background-grid-yellow);
}

.qKCUJ .WPmMr .C8CXK._0oATR .D-XNO {
  background: var(--web-yellow);
  filter: blur(85px);
}

.qKCUJ .WPmMr .C8CXK._0oATR .b-s4o .u-01b .tyMh- {
  box-shadow: 0 0 20px 8px var(--web-yellow);
}

.qKCUJ .WPmMr .C8CXK._0oATR .b-s4o .u-01b .T-c5i {
  color: var(--web-yellow);
}

.qKCUJ .WPmMr .C8CXK._0EH4V {
  background: var(--web-background-grid-white);
}

.qKCUJ .WPmMr .C8CXK._0EH4V .D-XNO {
  background: var(--white);
  filter: blur(85px);
}

.qKCUJ .WPmMr .C8CXK.iq-lJ {
  background: var(--web-background-grid-purple);
}

.qKCUJ .WPmMr .C8CXK.iq-lJ .D-XNO {
  background: var(--web-purple);
  filter: blur(85px);
}

.qKCUJ .WPmMr .C8CXK.WTtBr .D-XNO {
  width: 120px;
  height: 58px;
  bottom: 0;
  left: calc(50% - 60px);
}

.qKCUJ .WPmMr .C8CXK.RLhYf .D-XNO {
  width: 75px;
  height: 120px;
  top: calc(50% - 60px);
  bottom: 0;
  left: 0;
}

.qKCUJ .WPmMr .C8CXK.NVEv7 .D-XNO {
  display: none;
}

.qKCUJ .WPmMr .C8CXK.S-jOx .D-XNO {
  width: 308px;
  height: 76px;
  top: calc(50% - 38px);
  left: calc(50% - 154px);
  bottom: unset;
  right: unset;
}

.qKCUJ .WPmMr .C8CXK.vdbJ- {
  flex-direction: row;
  gap: 25px;
  width: 50%;
}

.qKCUJ .WPmMr .C8CXK.Qn-3O {
  gap: 25px;
  width: 50%;
}

.qKCUJ .WPmMr .C8CXK.Qn-3O .b-s4o {
  justify-content: center;
}

.qKCUJ .WPmMr .C8CXK.Qn-3O .L-nqz {
  text-align: center;
}

.qKCUJ .WPmMr .C8CXK.Qn-3O .JJUy6 {
  width: 100%;
  margin-top: auto;
  margin-bottom: auto;
}

.qKCUJ .WPmMr .C8CXK.qUy8L, .qKCUJ .WPmMr .C8CXK.hMPR6 {
  width: 100%;
}

.qKCUJ .WPmMr .C8CXK.qUy8L.hMPR6, .qKCUJ .WPmMr .C8CXK.hMPR6.hMPR6 {
  text-align: center;
  gap: 20px;
}

.qKCUJ .WPmMr .C8CXK.qUy8L.hMPR6 .b-s4o, .qKCUJ .WPmMr .C8CXK.hMPR6.hMPR6 .b-s4o {
  justify-content: center;
}

.qKCUJ .WPmMr .C8CXK.qUy8L .IEky7, .qKCUJ .WPmMr .C8CXK.hMPR6 .IEky7 {
  height: 330px;
  width: unset;
}

@media screen and (width <= 930px) {
  .qKCUJ .WPmMr {
    flex-direction: column;
  }

  .qKCUJ .WPmMr .C8CXK {
    flex-direction: column !important;
    width: calc(100% - 50px) !important;
  }

  .qKCUJ .WPmMr .C8CXK .IEky7 {
    width: 100% !important;
    height: unset !important;
  }

  .qKCUJ .WPmMr .C8CXK .MsCft {
    width: 280px !important;
    height: 78px !important;
  }

  .qKCUJ .WPmMr .C8CXK .MGs8X {
    width: 280px !important;
    height: 131px !important;
  }
}

._7pnq0 {
  flex-direction: column;
  gap: 10px;
  display: flex;
}

._7pnq0 .USf1h {
  align-items: center;
  gap: 15px;
  display: flex;
}

._7pnq0 .USf1h ._0JAhl {
  justify-self: center;
  align-items: center;
  width: 32px;
  height: 32px;
  display: flex;
  position: relative;
}

._7pnq0 .USf1h ._0JAhl .CaPpf {
  background: var(--web-blue);
  filter: blur(13px);
  width: 100%;
  height: 100%;
  position: absolute;
}

._7pnq0 .USf1h ._0JAhl .OIF4j {
  background: url("3d-ai.156d9775.svg") 0 0 / cover;
  width: 27px;
  height: 27px;
  position: absolute;
}

._7pnq0 .USf1h .jK8xj {
  text-transform: uppercase;
  color: var(--web-blue);
  font-size: 16px;
}

._7pnq0 ._0e7rD {
  gap: 44px;
  margin-bottom: 63px;
  display: flex;
}

._7pnq0 ._0e7rD .jNxQi {
  position: relative;
}

._7pnq0 ._0e7rD .jNxQi.EKOK2 .CaPpf {
  background: var(--web-purple-text);
}

._7pnq0 ._0e7rD .jNxQi.EKOK2 .jK8xj {
  color: var(--web-purple-text);
}

._7pnq0 ._0e7rD .jNxQi.yzn1U .CaPpf {
  background: var(--web-blue);
}

._7pnq0 ._0e7rD .jNxQi.yzn1U .jK8xj {
  color: var(--web-blue);
}

._7pnq0 ._0e7rD .jNxQi .h-h3d {
  align-items: center;
  width: fit-content;
  display: flex;
  position: relative;
}

._7pnq0 ._0e7rD .jNxQi .h-h3d .CaPpf {
  filter: blur(22px);
  width: 100%;
  height: 27px;
  position: absolute;
}

._7pnq0 ._0e7rD .jNxQi .h-h3d .jK8xj {
  font-family: bold, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 44px;
  position: relative;
}

._7pnq0 ._0e7rD .jNxQi .tApxT {
  color: var(--white);
  font-size: 16px;
}

._7pnq0 ._0e7rD .jNxQi ._339gc {
  background: var(--web-blue);
  border-radius: 8px;
  width: 6px;
  height: 50px;
  margin-top: 20px;
  position: absolute;
  left: calc(50% - 3px);
}

._7pnq0 .Wjq5j .PR59F {
  background: var(--web-blue);
  width: 100%;
  height: 1px;
}

._7pnq0 .Wjq5j .CaPpf {
  background: var(--web-blue);
  filter: blur(85px);
  width: 100%;
  height: 36px;
}

._7pnq0 .GtLcl {
  margin-bottom: 30px;
  font-family: semi-bold, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 16px;
}

._7pnq0 .GtLcl span {
  color: var(--web-blue);
}

._7pnq0 .tfe0b {
  flex-direction: column;
  gap: 20px;
  display: flex;
}

._7pnq0 .tfe0b .Ss-uZ .M1iSK {
  color: var(--white);
  font-family: semi-bold, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 14px;
}

._7pnq0 .tfe0b .Ss-uZ .u-1-W {
  align-items: center;
  gap: 15px;
  display: flex;
}

._7pnq0 .tfe0b .Ss-uZ .u-1-W.yzn1U .UXbZk {
  background: var(--web-blue);
}

._7pnq0 .tfe0b .Ss-uZ .u-1-W.OFjmr .UXbZk {
  background: var(--white);
}

._7pnq0 .tfe0b .Ss-uZ .u-1-W.OFjmr .EJoIw {
  color: var(--white-transparent);
}

._7pnq0 .tfe0b .Ss-uZ .u-1-W .lHulI {
  background: var(--white-transparent);
  border-radius: 6px;
  width: 100%;
  height: 6px;
  overflow: hidden;
}

._7pnq0 .tfe0b .Ss-uZ .u-1-W .lHulI .UXbZk {
  width: 50%;
  height: 100%;
}

._7pnq0 .tfe0b .Ss-uZ .u-1-W .EJoIw {
  color: var(--white);
  font-family: bold, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 23px;
}

@media screen and (width <= 930px) {
  ._7pnq0 ._0e7rD .h-h3d .jK8xj {
    font-size: 24px !important;
  }
}

._75Y8q {
  color: var(--black);
  flex-direction: column;
  gap: 30px;
  display: flex;
}

._75Y8q ._8Pp7V {
  color: var(--web-purple-text);
  text-transform: uppercase;
  font-family: semi-bold, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 16px;
}

._75Y8q .ONbEQ {
  max-width: 900px;
  font-family: semi-bold, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 26px;
}

._75Y8q .Pj9Wo {
  gap: 70px;
  display: flex;
}

._75Y8q .Pj9Wo .RV-V3 {
  background: var(--grey-sur);
  z-index: 1;
  border-radius: 16px;
  justify-content: flex-end;
  width: 50%;
  display: flex;
  position: relative;
}

._75Y8q .Pj9Wo .RV-V3 .IiyNK {
  height: 650px;
  box-shadow: var(--web-image-shadow);
  border: 2px solid #eee;
  border-radius: 16px;
  position: relative;
}

._75Y8q .Pj9Wo .RV-V3 .OLoHn {
  filter: blur(95px);
  background: #8c62fe;
  width: 100px;
  height: 100px;
  position: absolute;
  bottom: 0;
  right: 0;
}

._75Y8q .Pj9Wo ._5Rxs4 {
  flex-direction: column;
  gap: 40px;
  display: flex;
}

._75Y8q .Pj9Wo ._5Rxs4.hbHMv {
  gap: 25px;
}

._75Y8q .Pj9Wo ._5Rxs4 .mj-Wj {
  cursor: pointer;
  flex-direction: column;
  gap: 15px;
  transition: all .1s;
  display: flex;
}

._75Y8q .Pj9Wo ._5Rxs4 .mj-Wj.VRqmf {
  background: #fff;
  border-radius: 18px;
  padding: 20px;
  -ms-transform: scale(1.05);
  transform: scale(1.05);
  box-shadow: 0 0 30px #0000001c;
}

._75Y8q .Pj9Wo ._5Rxs4 .mj-Wj.VRqmf:hover ._-2n00 {
  color: var(--web-purple-text);
}

._75Y8q .Pj9Wo ._5Rxs4 .mj-Wj.VRqmf .N9jiU {
  display: block;
}

._75Y8q .Pj9Wo ._5Rxs4 .mj-Wj.ovOfg {
  -ms-transform: scale(1);
  transform: scale(1);
}

._75Y8q .Pj9Wo ._5Rxs4 .mj-Wj.ovOfg .-K7MZ {
  display: none;
}

._75Y8q .Pj9Wo ._5Rxs4 .mj-Wj.ovOfg .N9jiU {
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

._75Y8q .Pj9Wo ._5Rxs4 .mj-Wj .qhSaF {
  align-items: center;
  display: flex;
}

._75Y8q .Pj9Wo ._5Rxs4 .mj-Wj .qhSaF ._-2n00 {
  font-family: semi-bold, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 20px;
  transition: all .1s;
}

._75Y8q .Pj9Wo ._5Rxs4 .mj-Wj .qhSaF ._-2n00 .-NYLb {
  width: 20px;
  height: 20px;
  display: inline-block;
  position: relative;
  top: 3px;
}

._75Y8q .Pj9Wo ._5Rxs4 .mj-Wj .qhSaF ._-2n00 .-NYLb.R8Qym {
  background: url("black-ai-write.5b2c2486.svg") 0 0 / cover;
}

._75Y8q .Pj9Wo ._5Rxs4 .mj-Wj .qhSaF ._-2n00 .-NYLb._0MS0V {
  background: url("black-ai-stars.5ac6cf8b.svg") 0 0 / cover;
}

._75Y8q .Pj9Wo ._5Rxs4 .mj-Wj .qhSaF ._-2n00 .-NYLb.MnNf1 {
  filter: var(--icon-purple-black-web);
}

._75Y8q .Pj9Wo ._5Rxs4 .mj-Wj .qhSaF .N9jiU {
  background: url("black-bottom-bold.1b3c333e.svg") 0 0 / cover;
  flex: 0 0 16px;
  width: 16px;
  height: 16px;
  display: none;
}

._75Y8q .Pj9Wo ._5Rxs4 .mj-Wj .-K7MZ {
  color: var(--grey-text);
  font-size: 15px;
}

@media screen and (width <= 1200px) {
  ._75Y8q .RV-V3 .IiyNK {
    height: 630px !important;
  }
}

@media screen and (width <= 1100px) {
  ._75Y8q .RV-V3 .IiyNK {
    height: 550px !important;
  }
}

@media screen and (width <= 1000px) {
  ._75Y8q .RV-V3 .IiyNK {
    height: 540px !important;
  }
}

@media screen and (width <= 930px) {
  ._75Y8q .Pj9Wo {
    flex-direction: column-reverse;
  }

  ._75Y8q .Pj9Wo .RV-V3 {
    width: 100%;
  }

  ._75Y8q .Pj9Wo .RV-V3 .IiyNK {
    width: 100%;
    height: unset !important;
  }
}

.WQMTb {
  flex-direction: column;
  gap: 15px;
  display: flex;
}

.WQMTb .YX2FI {
  color: var(--web-purple-text);
  text-transform: uppercase;
  font-size: 16px;
}

.WQMTb .ttH3u {
  max-width: 751px;
  font-family: semi-bold, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 28px;
  position: relative;
}

.WQMTb .ttH3u span {
  color: var(--web-text-white-alpha);
}

.WQMTb .ttH3u .CNm8g {
  background: var(--web-purple);
  filter: blur(95px);
  width: 182px;
  height: 101px;
  position: absolute;
  top: 0;
  left: -70px;
}

.WQMTb .ttH3u .VnLNW {
  position: relative;
}

.WQMTb .MET-I {
  margin-top: 45px;
}

.tANMT {
  flex-direction: column;
  gap: 15px;
  display: flex;
}

.tANMT .tXBMU {
  color: var(--web-blue);
  font-family: bold, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 23px;
}

.tANMT .L-boR {
  gap: 20px;
  display: flex;
}

.tANMT .L-boR .C6-fI {
  background: linear-gradient(#fff0 0%, #ffffff0f 100%);
  border: 1px solid #ffffff2e;
  border-radius: 22px;
  flex-direction: column;
  gap: 15px;
  width: 33.33%;
  padding: 25px;
  display: flex;
  position: relative;
}

.tANMT .L-boR .C6-fI .XOTpq {
  font-family: bold, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 20px;
}

.tANMT .L-boR .C6-fI .SLsTh {
  font-size: 14px;
}

.tANMT .L-boR .C6-fI ._7jvfw {
  justify-content: center;
  width: 100%;
  margin-top: auto;
  display: flex;
}

.tANMT .L-boR .C6-fI ._7jvfw ._6LQ3h {
  width: 130px;
  height: 130px;
}

.tANMT .L-boR .C6-fI ._7jvfw ._6LQ3h._7wE4r {
  background: url("illus-black-page.07a3278c.svg") 0 0 / cover;
}

.tANMT .L-boR .C6-fI ._7jvfw ._6LQ3h.-op1F {
  background: url("illus-black-target.5c314779.svg") 0 0 / cover;
  width: 120px;
  height: 120px;
}

.tANMT .L-boR .C6-fI ._7jvfw ._6LQ3h._9BFxK {
  background: url("illus-black-box.51e4d5e3.svg") 0 0 / cover;
  width: 140px;
  height: 140px;
}

.tANMT .L-boR .C6-fI .k8h4B {
  filter: blur(85px);
  background: #5082ff;
  width: 120px;
  height: 58px;
  position: absolute;
  bottom: 0;
  left: calc(50% - 60px);
}

@media screen and (width <= 930px) {
  .tANMT .L-boR {
    flex-wrap: wrap;
  }

  .tANMT .L-boR .C6-fI {
    width: 100%;
  }
}

.NZXhT {
  flex-direction: column;
  gap: 35px;
  display: flex;
}

.NZXhT .yRwUc {
  z-index: 1;
  max-width: 800px;
  font-family: semi-bold, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 28px;
  position: relative;
}

.NZXhT .yRwUc span {
  color: var(--web-text-white-alpha);
}

.NZXhT .yRwUc .BsxTO {
  position: relative;
}

.NZXhT .HvZho {
  border-top: 1px solid var(--web-blue);
}

.NZXhT .HvZho .hENoT {
  background: var(--web-blue);
  filter: blur(60px);
  width: 100%;
  height: 36px;
}

.NZXhT .HvZho .BsxTO {
  max-width: 700px;
  position: relative;
}

.NZXhT .HvZho .BsxTO span {
  color: var(--web-blue);
}

.klryu {
  flex-direction: column;
  gap: 35px;
  display: flex;
}

.klryu .PoaQ4 {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.klryu .PoaQ4 .BGowu {
  color: var(--web-blue);
  background: #5082ff1a;
  border: 1px solid #5082ff26;
  border-radius: 39px;
  align-items: center;
  gap: 10px;
  padding: 10px 18px;
  font-size: 16px;
  display: flex;
}

.klryu .PoaQ4 .BGowu ._943qj {
  justify-content: center;
  align-items: center;
  display: flex;
}

.klryu .PoaQ4 .BGowu ._943qj .Stx-W {
  background: var(--web-blue);
  filter: blur(12.5px);
  width: 32px;
  height: 32px;
  position: absolute;
}

.klryu .PoaQ4 .BGowu ._943qj .g9YOi {
  background: url("3d-ai.156d9775.svg") 0 0 / cover;
  width: 27px;
  height: 27px;
  position: relative;
}

.klryu .EEDpq {
  background: url("illus-black-union.9a31df27.svg") 0 0 / cover;
  width: 800px;
  height: 98px;
  position: relative;
  left: calc(50% - 400px);
}

.klryu .vYEcJ {
  gap: 25px;
  display: flex;
}

.klryu .vYEcJ .ckG9B {
  border-radius: 22px;
  width: 33.33%;
  padding: 1px;
  position: relative;
}

.klryu .vYEcJ .ckG9B.XuQ9s:before {
  background: linear-gradient(to bottom, var(--web-blue), transparent);
}

.klryu .vYEcJ .ckG9B.XuQ9s .bD7k7 {
  background: linear-gradient(#000, #00071a);
}

.klryu .vYEcJ .ckG9B.XuQ9s .bD7k7 ._7-4ya {
  background: var(--web-blue);
}

.klryu .vYEcJ .ckG9B.XuQ9s .bD7k7 ._1kcaj {
  color: var(--web-blue);
}

.klryu .vYEcJ .ckG9B.d84Qm:before {
  background: linear-gradient(to bottom, var(--web-purple), transparent);
}

.klryu .vYEcJ .ckG9B.d84Qm .bD7k7 {
  background: linear-gradient(#000, #0c0818);
}

.klryu .vYEcJ .ckG9B.d84Qm .bD7k7 ._7-4ya {
  background: var(--web-purple);
}

.klryu .vYEcJ .ckG9B.d84Qm .bD7k7 ._1kcaj {
  color: var(--web-purple);
}

.klryu .vYEcJ .ckG9B.h9MMA:before {
  background: linear-gradient(to bottom, var(--web-yellow), transparent);
}

.klryu .vYEcJ .ckG9B.h9MMA .bD7k7 {
  background: linear-gradient(#000, #121200);
}

.klryu .vYEcJ .ckG9B.h9MMA .bD7k7 ._7-4ya {
  background: var(--web-yellow);
}

.klryu .vYEcJ .ckG9B.h9MMA .bD7k7 ._1kcaj {
  color: var(--web-yellow);
}

.klryu .vYEcJ .ckG9B:before {
  content: "";
  z-index: 1;
  pointer-events: none;
  border-radius: 22px;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.klryu .vYEcJ .ckG9B .bD7k7 {
  z-index: 2;
  text-align: center;
  border-radius: 22px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 25px;
  width: calc(100% - 60px);
  height: calc(100% - 100px);
  padding: 50px 30px;
  display: flex;
  position: relative;
}

.klryu .vYEcJ .ckG9B .bD7k7 .CpC5t {
  text-align: center;
  width: 230px;
  position: relative;
}

.klryu .vYEcJ .ckG9B .bD7k7 .CpC5t ._7-4ya {
  filter: blur(75px);
  width: 100%;
  height: 100%;
  position: absolute;
}

.klryu .vYEcJ .ckG9B .bD7k7 .CpC5t ._1kcaj {
  text-align: center;
  width: 100%;
  font-family: bold, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 32px;
  position: relative;
}

.klryu .vYEcJ .ckG9B .bD7k7 .xurSr {
  text-align: center;
  font-size: 14px;
}

@media screen and (width <= 480px) {
  .klryu .EEDpq {
    width: 300px;
    height: 36px;
    left: calc(50% - 150px);
  }

  .klryu .vYEcJ {
    flex-direction: column;
  }

  .klryu .vYEcJ .ckG9B {
    width: 100%;
  }
}

.mmdtX {
  color: var(--black);
  flex-direction: column;
  gap: 30px;
  display: flex;
}

.mmdtX ._1XfjP {
  color: var(--web-purple-text);
  text-transform: uppercase;
  font-family: semi-bold, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 16px;
}

.mmdtX .Ximav {
  max-width: 900px;
  font-family: semi-bold, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 26px;
}

.mmdtX .q9R-P {
  gap: 20px;
  display: flex;
}

.mmdtX .q9R-P .hErou {
  background: var(--grey-sur-light);
  border-radius: 16px;
  flex-direction: column;
  gap: 25px;
  width: 33.33%;
  padding: 40px 30px;
  display: flex;
  position: relative;
}

.mmdtX .q9R-P .hErou.htg4g {
  width: 50%;
  padding: 35px 40px 0;
}

.mmdtX .q9R-P .hErou.htg4g .gfp3D {
  font-size: 20px;
}

.mmdtX .q9R-P .hErou .gfp3D {
  align-items: center;
  gap: 15px;
  font-family: semi-bold, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 17px;
  display: flex;
}

.mmdtX .q9R-P .hErou .gfp3D .Lh8-0 {
  flex: 0 0 28px;
  width: 28px;
  height: 28px;
}

.mmdtX .q9R-P .hErou .gfp3D .Lh8-0.nOlcb {
  background: url("black-line-chart.5ae01ada.svg") 0 0 / cover;
}

.mmdtX .q9R-P .hErou .gfp3D .Lh8-0.jExnb {
  background: url("black-speedometer.d3b96812.svg") 0 0 / cover;
}

.mmdtX .q9R-P .hErou .gfp3D .Lh8-0.Pa1qn {
  background: url("black-refresh.4c486038.svg") 0 0 / cover;
}

.mmdtX .q9R-P .hErou ._5spjo {
  font-size: 14px;
}

.mmdtX .q9R-P .hErou .gpTJb {
  gap: 25px;
  display: flex;
}

.mmdtX .q9R-P .hErou .gpTJb .Ou0oS {
  flex: 0 0 200px;
  width: 200px;
  height: 249px;
  bottom: 0;
}

.mmdtX .q9R-P .hErou .gpTJb .Ou0oS.su6yq {
  background: url("illus-black-optimize.f3bd2a37.svg") 0 0 / cover;
}

.mmdtX .q9R-P .hErou .gpTJb .Ou0oS.AexdA {
  background: url("illus-black-ai-plan.e6dd662f.svg") 0 0 / cover;
}

.mmdtX .U5Kmi {
  background: url("illus-black-zig.a8343b6c.svg") 0 0 / cover;
  width: 70px;
  height: 17px;
  margin-top: 60px;
}

@media screen and (width <= 930px) {
  .mmdtX .q9R-P {
    flex-wrap: wrap;
  }

  .mmdtX .q9R-P .hErou {
    width: calc(100% - 80px) !important;
  }

  .mmdtX .q9R-P .hErou .Ou0oS {
    flex: 0 0 80px !important;
    width: 80px !important;
    height: 100px !important;
  }
}

._1Gqz5 .wgKID {
  background: var(--grad-multi);
  -webkit-text-fill-color: transparent;
  text-align: left;
  -webkit-background-clip: text;
  font-family: bold, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 30px;
}

._1Gqz5 .w60GZ {
  color: var(--white);
  text-align: center;
  font-family: bold, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 44px;
}

@media (width <= 480px) {
  ._1Gqz5 .w60GZ {
    width: 90%;
    font-size: 20px;
  }
}

._6e4O7 {
  border-radius: 15px;
  flex-direction: column;
  width: 100%;
  display: flex;
}

._6e4O7:hover .Ck9DZ ._111Lr {
  text-decoration: underline;
}

._6e4O7 .k1jrV {
  border: 1px solid var(--grey-sur);
  cursor: pointer;
  background: var(--grey-sur);
  color: #0000;
  border-radius: 12px;
  width: 100%;
  height: 276px;
  margin-bottom: 5px;
  overflow: hidden;
}

._6e4O7 .k1jrV.UHgVd {
  justify-content: center;
  align-items: center;
  display: flex;
}

._6e4O7 .k1jrV.UHgVd img {
  opacity: .2;
  width: 120px;
}

._6e4O7 .k1jrV img {
  width: 100%;
}

._6e4O7 .Ck9DZ {
  margin-bottom: 10px;
}

._6e4O7 .Ck9DZ ._111Lr {
  color: var(--black);
  margin-top: 10px;
  margin-bottom: 10px;
  font-family: bold, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 22px;
}

._6e4O7 .Ck9DZ ._25oiB {
  color: var(--grey-text-dark);
  -webkit-line-clamp: 6;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  margin: 0;
  font-family: medium, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 15px;
  display: -webkit-box;
  overflow: hidden;
}

._6e4O7 ._4reGz {
  color: var(--grey-text);
  font-size: 12px;
}

._6e4O7 .fK-rB {
  margin-top: 20px;
}

@media screen and (width <= 1010px) {
  ._6e4O7 .k1jrV {
    height: 220px;
  }
}

@media screen and (width <= 850px) {
  ._6e4O7 .k1jrV {
    height: 235px;
  }
}

body {
  margin: 0;
  padding: 0;
  font-family: medium, Segoe UI Emoji, Montserrat, sans-serif;
  overflow: hidden auto;
}

main {
  background: var(--black);
  width: 100%;
  min-height: 100%;
  color: var(--white);
  position: absolute;
}

input {
  background: var(--white-light);
  width: 100%;
  color: var(--black);
  border: 1px solid #eaeaea;
  border: var(--grey-sur) 2px solid;
  border-radius: 10px;
  padding-left: 10px;
  padding-right: 10px;
  font-family: medium, Segoe UI Emoji, Montserrat, sans-serif;
}

input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

input[type="date"]::-webkit-inner-spin-button {
  display: none;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
}

input::-webkit-input-placeholder {
  color: var(--grey-text);
}

input::placeholder {
  color: var(--grey-text);
}

input:focus {
  outline: 0;
}

input[disabled] {
  border: var(--grey-sur-hover) 2px solid;
  background: var(--grey-sur);
}

input:focus {
  border-color: var(--grey-alpha);
}

a {
  color: var(--white);
  text-decoration: none;
}

button {
  background: none;
  border: none;
}

h1, h2, h3, h4, h5, h6, p, ul, li {
  margin: 0;
  list-style-type: none;
}

@media (width <= 480px) {
  main {
    max-width: 480px;
    overflow-x: hidden;
  }
}

.zRLIt {
  z-index: 2147483647;
  background: var(--white-alpha);
  backdrop-filter: saturate(180%) blur(20px);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

.zRLIt .TKUoI {
  justify-content: center;
  align-items: center;
  display: flex;
}

.zRLIt .TKUoI .C2Rxd {
  width: 200px;
  height: 200px;
}

.zRLIt .TKUoI .XP-33 {
  text-align: center;
  width: 520px;
  margin-top: 40px;
  font-family: semi-bold, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 19px;
}

.zRLIt .TKUoI .o8tgT {
  position: absolute;
  bottom: 30px;
}

.zRLIt .TKUoI .o8tgT ._7Y2KA {
  float: left;
  width: 26px;
  height: 26px;
  position: relative;
}

.zRLIt .TKUoI .o8tgT .hu4NY {
  float: left;
  margin-top: 4px;
  margin-left: 7px;
  font-family: extra-bold, Segoe UI Emoji, Montserrat, sans-serif;
}

.e-V-Z {
  background: var(--grey-sur);
  cursor: pointer;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  overflow: hidden;
}

.e-V-Z.SBf3T {
  background: var(--white);
}

.e-V-Z .rJYe6 {
  color: #00000080;
  font-family: semi-bold, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 12px;
  position: absolute;
}

.e-V-Z .UQ3HL {
  background: var(--blue);
  width: 0;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.e-V-Z .UQ3HL.wAHO5 {
  background: var(--orange-cover);
}

.e-V-Z .UQ3HL.OZINJ {
  background: var(--red);
}

.e-V-Z .UQ3HL.aOQjW {
  background: #3ece80;
}

.ynzIG .nPGy9 {
  margin-top: 20px;
  font-size: 14px;
}

.ynzIG .d0vhn {
  border-radius: 3px;
  width: 70%;
  height: 6px;
  margin-top: 15px;
  position: relative;
  overflow: hidden;
}

.ynzIG .DozN7 {
  background: var(--green-dark);
  color: var(--white);
  cursor: pointer;
  border-radius: 9px;
  justify-content: center;
  align-items: center;
  width: fit-content;
  height: 46px;
  margin-top: 20px;
  margin-left: auto;
  padding: 0 16px;
  font-size: 14px;
  display: flex;
}

.ynzIG .AEdIg {
  background: url("3d-offer-list.3adf9f8d.svg") 0 0 / cover;
  width: 100px;
  height: 100px;
}
/*# sourceMappingURL=website.4b28fdab.css.map */
